const GujaratiTranslation={
    'Email Security Check':'ઈમેલ સુરક્ષા તપાસ',
    'Verify Your Email for Account Access': 'એકાઉન્ટ એક્સેસ માટે તમારો ઈમેલ ચકાસો',
    "To ensure the security of your account, please enter your valid Email ID in the specified format- username@domain.com. If your email address doesn't exist in our system": "તમારા એકાઉન્ટની સુરક્ષા સુનિશ્ચિત કરવા માટે, કૃપા કરીને ઉલ્લેખિત ફોર્મેટમાં તમારું માન્ય ઈમેલ આઈડી દાખલ કરો- username@domain.com. જો તમારું ઇમેઇલ સરનામું અમારી સિસ્ટમમાં અસ્તિત્વમાં નથી, તો એ",
    'NEW USER': 'નવું વપરાશકર્તા',
    'account will be created for you': 'એકાઉન્ટ બનાવવામાં આવશે.',
    'Once submitted, you will receive a Verification OTP (One-Time Password) sent to your provided email address.': 'એકવાર સબમિટ કર્યા પછી, તમને તમારા પ્રદાન કરેલ ઇમેઇલ સરનામાં પર મોકલેલ એક ચકાસણી OTP (વન-ટાઇમ પાસવર્ડ) પ્રાપ્ત થશે.',
    'For any queries or assistance, feel free to call us at +91-9699900111.': 'કોઈપણ પ્રશ્નો અથવા સહાયતા માટે, અમને +91-9699900111 પર કૉલ કરો.',
    'Thank you for entrusting PrivateCourt. Your security is our priority.': 'પ્રાઇવેટકોર્ટને સોંપવા બદલ આભાર. તમારી સુરક્ષા અમારી પ્રાથમિકતા છે.',
    'Verify': 'ચકાસો',
    'Invalid Email Address': 'અમાન્ય ઇમેઇલ સરનામું',
    'We regret to inform you that the email address entered is invalid. Please ensure that you have entered a correct and properly formatted email address.': 'અમે તમને જણાવતા ખેદ અનુભવીએ છીએ કે દાખલ કરેલું ઇમેઇલ સરનામું અમાન્ય છે. કૃપા કરીને ખાતરી કરો કે તમે સાચો અને યોગ્ય રીતે ફોર્મેટ કરેલ ઇમેઇલ સરનામું દાખલ કર્યું છે.',
    'If the issue persists, double-check for any typos or errors in the email format (e.g., username@example.com).': 'જો સમસ્યા યથાવત્ રહે છે, તો ઈમેલ ફોર્મેટ (દા.ત. username@example.com)માં કોઈપણ લખાણની ભૂલો અથવા ભૂલો માટે બે વાર તપાસો.',
    'For further queries or assistance, feel free to call us at +91-9699900111.': 'વધુ પ્રશ્નો અથવા સહાયતા માટે, અમને +91-9699900111 પર કૉલ કરો.',
    'Thank you for entrusting PrivateCourt. Your security is our priority.': 'પ્રાઇવેટકોર્ટને સોંપવા બદલ આભાર. તમારી સુરક્ષા અમારી પ્રાથમિકતા છે.',
    'Secure UDYAM Number Validation':'સુરક્ષિત ઉદ્યોગ નંબર માન્યતા',
    'Enter Your Udyam Number':'તમારો ઉદ્યમ નંબર દાખલ કરો',
    'Please enter your correct UDYAM Number.':'કૃપા કરીને તમારો સાચો ઉદ્યોગ નંબર દાખલ કરો.',
    'Your UDYAM Number allows us to collect crucial details associated with your business. This includes your business address, current status, service details, and organization type.':'તમારો ઉદ્યોગ નંબર અમને તમારા વ્યવસાય સાથે સંકળાયેલ નિર્ણાયક વિગતો એકત્રિત કરવાની મંજૂરી આપે છે. આમાં તમારું વ્યવસાય સરનામું, વર્તમાન સ્થિતિ, સેવાની વિગતો અને સંસ્થાનો પ્રકાર શામેલ છે.',
    'Ensuring the accuracy of your UDYAM Number is vital for a seamless verification process.':'તમારા ઉદ્યોગ નંબરની ચોકસાઈની ખાતરી કરવી એ સીમલેસ વેરિફિકેશન પ્રક્રિયા માટે મહત્વપૂર્ણ છે.',
    'UDYAM':'ઉદ્યોગ',
    'Error: Invalid UDYAM Number':'ભૂલ: અમાન્ય ચકાસો નંબર',
    'We regret to inform you that the UDYAM number entered is invalid or incorrect. Please ensure you have entered the correct UDYAM details and double-check for any typos.':'અમે તમને જણાવતા દિલગીર છીએ કે દાખલ કરેલ UDYAM નંબર અમાન્ય અથવા ખોટો છે. કૃપા કરીને ખાતરી કરો કે તમે સાચી UDYAM વિગતો દાખલ કરી છે અને કોઈપણ ટાઇપો માટે બે વાર તપાસો.',
    'If you continue to face issues, kindly verify your UDYAM certificate and re-enter the details.':'જો તમે સમસ્યાઓનો સામનો કરવાનું ચાલુ રાખો છો, તો કૃપા કરીને તમારું UDYAM પ્રમાણપત્ર ચકાસો અને વિગતો ફરીથી દાખલ કરો.',
    'Secure PAN Validation Process':'સુરક્ષિત PAN માન્યતા પ્રક્રિયા',
    'This verification process is designed to uphold the integrity of our services, ensuring a reliable and secure experience.':'આ ચકાસણી પ્રક્રિયા વિશ્વસનીય અને સુરક્ષિત અનુભવને સુનિશ્ચિત કરીને અમારી સેવાઓની અખંડિતતાને જાળવી રાખવા માટે બનાવવામાં આવી છે.',
    'Enter Your PAN Number':'તમારો PAN નંબર દાખલ કરો',
    'Please enter your correct PAN number in the format XXXXX-0000-X.':'કૃપા કરીને તમારો સાચો PAN નંબર XXXXX-0000-X ફોર્મેટમાં દાખલ કરો.',
    'PrivateCourt will meticulously collect essential information associated with your PAN such as your full name, date of birth, residential address, and contact details. For Business Owners, we extend our data collection to encompass all pertinent business details tied to your PAN such as GST details, MCA specifics, and records of GST return filings.':'પ્રાઇવેટકોર્ટ કાળજીપૂર્વક તમારા PAN સાથે સંકળાયેલ આવશ્યક માહિતી જેમ કે તમારું પૂરું નામ, જન્મ તારીખ, રહેણાંક સરનામું અને સંપર્ક વિગતો એકત્રિત કરશે. વ્યવસાયના માલિકો માટે, અમે તમારા PAN સાથે જોડાયેલ તમામ સંબંધિત વ્યવસાય વિગતો જેમ કે GST વિગતો, MCA સ્પષ્ટીકરણો અને GST રિટર્ન ફાઇલિંગના રેકોર્ડ્સનો સમાવેશ કરવા માટે અમારા ડેટા સંગ્રહને વિસ્તારીએ છીએ.',
    'Our system assesses your CIBIL score for financial insight and cross-verifies for any ongoing court cases associated with your PAN.':'અમારી સિસ્ટમ નાણાકીય સૂઝ માટે તમારા CIBIL સ્કોરનું મૂલ્યાંકન કરે છે અને તમારા PAN સાથે સંકળાયેલા કોઈપણ ચાલી રહેલા કોર્ટ કેસ માટે ક્રોસ-વેરિફાય કરે છે.',
    'Error: Invalid PAN Format':'ભૂલ: અમાન્ય PAN ફોર્મેટ',
    'We regret to inform you that the PAN number entered does not match the required format (XXXXX-0000-X). Please ensure you have entered the correct PAN details and follow the specified format for accurate verification.':'અમે તમને જણાવતા દિલગીર છીએ કે દાખલ કરેલ PAN નંબર જરૂરી ફોર્મેટ (XXXXX-0000-X) સાથે મેળ ખાતો નથી. કૃપા કરીને ખાતરી કરો કે તમે સાચી PAN વિગતો દાખલ કરી છે અને ચોક્કસ ચકાસણી માટે ઉલ્લેખિત ફોર્મેટને અનુસરો.',
    'If you continue to face issues, kindly double-check your PAN card and re-enter the details. For further queries or assistance, feel free to call us at +91-9699900111.':'જો તમે સમસ્યાઓનો સામનો કરવાનું ચાલુ રાખો છો, તો કૃપા કરીને તમારું PAN કાર્ડ બે વાર તપાસો અને વિગતો ફરીથી દાખલ કરો. વધુ પ્રશ્નો અથવા સહાયતા માટે, અમને +91-9699900111 પર કૉલ કરો.',
    'Welcome XXXX':'સ્વાગત છે XXXX',
    'Account security starts with verification':'એકાઉન્ટ સુરક્ષા ચકાસણી સાથે શરૂ થાય છે',
    'OTP Validation for Account Access':'એકાઉન્ટ એક્સેસ માટે OTP માન્યતા',
    'Kindly check your inbox, and enter the OTP in the designated field to complete the verification process.':'કૃપા કરીને તમારું ઇનબોક્સ તપાસો, અને ચકાસણી પ્રક્રિયા પૂર્ણ કરવા માટે નિયુક્ત ફીલ્ડમાં OTP દાખલ કરો.',
    "Didn't receive OTP?":'OTP પ્રાપ્ત થયો નથી?',
    'Request a Resend':'ફરીથી મોકલવાની વિનંતી કરો',
    'Verify OTP':'ઓટીપી ચકાસો',
    'Error: Incorrect OTP':'ભૂલ: ખોટો OTP',
    'We apologize for the inconvenience. The One-Time Password (OTP) entered is incorrect. Please ensure that you have accurately entered the OTP received in your email.':'અસુવિધા માટે અમે દિલગીર છીએ. દાખલ કરેલ વન-ટાઇમ પાસવર્ડ (OTP) ખોટો છે. કૃપા કરીને ખાતરી કરો કે તમે તમારા ઈમેલમાં પ્રાપ્ત થયેલ OTP સચોટ રીતે દાખલ કર્યો છે.',
    'If the issue persists, you may request a new OTP by clicking on the resend link or contacting our support team at +91-9699900111.':'જો સમસ્યા ચાલુ રહે, તો તમે ફરીથી મોકલો લિંક પર ક્લિક કરીને અથવા +91-9699900111 પર અમારી સપોર્ટ ટીમનો સંપર્ક કરીને નવા OTPની વિનંતી કરી શકો છો.',
    'Double-check the email and try entering the OTP again.':'ઈમેલને બે વાર તપાસો અને ફરીથી OTP દાખલ કરવાનો પ્રયાસ કરો.',
    'Choose Profile':'પ્રોફાઇલ પસંદ કરો',
    'Setting Your Default Profile':'તમારી ડિફૉલ્ટ પ્રોફાઇલ સેટ કરી રહ્યું છે',
    'Please choose the default profile for a seamless experience.':'સીમલેસ અનુભવ માટે કૃપા કરીને ડિફોલ્ટ પ્રોફાઇલ પસંદ કરો',
    "If you manage multiple companies, select the profile you'd like to set as the default. This ensures that every time you log in, you'll have quick access to the information relevant to your preferred company.":'જો તમે બહુવિધ કંપનીઓનું સંચાલન કરો છો, તો તમે ડિફોલ્ટ તરીકે સેટ કરવા માંગતા હો તે પ્રોફાઇલ પસંદ કરો. આ સુનિશ્ચિત કરે છે કે જ્યારે પણ તમે લોગ ઇન કરો છો, ત્યારે તમારી પાસે તમારી પસંદગીની કંપની સાથે સંબંધિત માહિતીની ઝડપી ઍક્સેસ હશે.',
    'Making the right selection now simplifies your future logins and provides a tailored experience based on your primary business entity.':'હવે યોગ્ય પસંદગી કરવાથી તમારા ભાવિ લૉગિનને સરળ બનાવે છે અને તમારી પ્રાથમિક વ્યાપાર સંસ્થાના આધારે અનુરૂપ અનુભવ પ્રદાન કરે છે.',
    'Go to Dashboard':'ડેશબોર્ડ પર જાઓ',
    'Data Compilation in Progress':'ડેટા સંકલન ચાલુ છે:',
    'Please Allow Processing Time':'કૃપા કરીને પ્રોસેસિંગ સમયની મંજૂરી આપો',
    "Thank you for providing your information. Our system is currently in the process of analyzing and compiling the data you've entered, including details such as PAN, Aadhaar, GST, Email, and more, to establish your comprehensive profile.":'તમારી માહિતી આપવા બદલ આભાર. તમારી વ્યાપક પ્રોફાઇલ સ્થાપિત કરવા માટે અમારી સિસ્ટમ હાલમાં તમે દાખલ કરેલ ડેટાનું વિશ્લેષણ અને સંકલન કરવાની પ્રક્રિયામાં છે, જેમાં PAN, આધાર, GST, ઇમેઇલ અને વધુ જેવી વિગતોનો સમાવેશ થાય છે.',
    "This operation may take a few moments. During this time, your screen may show minimal activity. Please be assured that this is a routine part of our data compilation process.":'આ ઓપરેશનમાં થોડો સમય લાગી શકે છે. આ સમય દરમિયાન, તમારી સ્ક્રીન ન્યૂનતમ પ્રવૃત્તિ બતાવી શકે છે. કૃપા કરીને ખાતરી કરો કે આ અમારી ડેટા સંકલન પ્રક્રિયાનો નિયમિત ભાગ છે.',
    'Your profile is being meticulously crafted to uphold accuracy and security. We sincerely appreciate your patience and understanding as we work to enhance your experience.':'સચોટતા અને સુરક્ષાને જાળવી રાખવા માટે તમારી પ્રોફાઇલને ઝીણવટપૂર્વક બનાવવામાં આવી રહી છે. અમે તમારી ધીરજ અને સમજણની નિષ્ઠાપૂર્વક પ્રશંસા કરીએ છીએ કારણ કે અમે તમારા અનુભવને વધારવા માટે કામ કરીએ છીએ.',
    'Start Your Bank Account Verification':'તમારું બેંક એકાઉન્ટ વેરિફિકેશન શરૂ કરો',
    'Bank Verification':'બેંક ચકાસણી',
    'Ensuring the accuracy of your bank account information is crucial for a seamless verification process. Kindly enter your valid Account Number, and input the correct IFSC code associated with your account.':'તમારા બેંક ખાતાની માહિતીની ચોકસાઈની ખાતરી કરવી એ સીમલેસ વેરિફિકેશન પ્રક્રિયા માટે મહત્વપૂર્ણ છે. કૃપા કરીને તમારો એકાઉન્ટ નંબર દાખલ કરો અને તમારા ખાતા સાથે સંકળાયેલ સાચો IFSC કોડ દાખલ કરો.',
    'This verification step is designed to enhance the security of our services and provide you with a reliable experience.':'આ ચકાસણી પગલું અમારી સેવાઓની સુરક્ષા વધારવા અને તમને વિશ્વસનીય અનુભવ પ્રદાન કરવા માટે રચાયેલ છે.',
    'Account Number':'',
    'IFSC code':'',
    'Error: Invalid Bank Account or IFSC Code':'ભૂલ: અમાન્ય બેંક એકાઉન્ટ અથવા IFSC કોડ',
    'We regret to inform you that the Bank Account or IFSC Code entered is invalid or incorrect. Please ensure you have accurately entered both details and double-check for any typos.':'અમે તમને જણાવતા દિલગીર છીએ કે દાખલ કરેલ બેંક એકાઉન્ટ અથવા IFSC કોડ અમાન્ય અથવા ખોટો છે. કૃપા કરીને ખાતરી કરો કે તમે બંને વિગતો સચોટ રીતે દાખલ કરી છે અને કોઈપણ ટાઇપો માટે બે વાર તપાસો.',
    'If you continue to face issues, kindly verify your bank statement or contact your bank to confirm the accuracy of the provided information.':'જો તમે સમસ્યાઓનો સામનો કરવાનું ચાલુ રાખો છો, તો કૃપા કરીને તમારું બેંક સ્ટેટમેન્ટ ચકાસો અથવા પ્રદાન કરેલી માહિતીની ચોકસાઈની પુષ્ટિ કરવા માટે તમારી બેંકનો સંપર્ક કરો.',
    'Aadhaar Details Verification':'આધાર વિગતોની ચકાસણી',
    'Enter Your Aadhaar Number':'તમારો આધાર નંબર દાખલ કરો',
    'Please input your Aadhaar number. This process ensures accurate cross-checking for your security. Thank you for your cooperation.':'કૃપા કરીને તમારો આધાર નંબર દાખલ કરો. આ પ્રક્રિયા તમારી સુરક્ષા માટે ચોક્કસ ક્રોસ-ચેકિંગની ખાતરી આપે છે. તમારા સહકાર માટે તમારો આભાર.',
    'Enter Your Aadhaar OTP Here':'અહીં તમારો આધાર OTP દાખલ કરો',
    'Error: Incorrect Aadhaar OTP':'ભૂલ: ખોટો આધાર OTP',
    'We regret to inform you that the One-Time Password (OTP) entered for Aadhaar verification is incorrect. Please ensure that you have accurately entered the OTP received on your registered mobile number.':'અમે તમને જણાવતા દિલગીર છીએ કે આધાર વેરિફિકેશન માટે દાખલ કરેલ વન-ટાઇમ પાસવર્ડ (OTP) ખોટો છે. કૃપા કરીને ખાતરી કરો કે તમે તમારા રજિસ્ટર્ડ મોબાઇલ નંબર પર પ્રાપ્ત થયેલ OTP ચોક્કસ રીતે દાખલ કર્યો છે.',
    'If the issue persists, you may request a new OTP by clicking on the resend link or contacting our support team at +91-9699900111.':'જો સમસ્યા ચાલુ રહે, તો તમે ફરીથી મોકલો લિંક પર ક્લિક કરીને અથવા +91-9699900111 પર અમારી સપોર્ટ ટીમનો સંપર્ક કરીને નવા OTPની વિનંતી કરી શકો છો.',
    'Double-check the email and try entering the OTP again.':'ઈમેલને બે વાર તપાસો અને ફરીથી OTP દાખલ કરવાનો પ્રયાસ કરો.',
    'Error: Invalid Aadhaar Number':'ભૂલ: અમાન્ય આધાર નંબર',
    'We apologize for the inconvenience. The Aadhaar number entered is invalid. Please double-check and ensure that you have entered the correct 12-digit Aadhaar number.':'અસુવિધા માટે અમે દિલગીર છીએ. દાખલ કરેલ આધાર નંબર અમાન્ય છે. કૃપા કરીને બે વાર તપાસો અને ખાતરી કરો કે તમે સાચો 12-અંકનો આધાર નંબર દાખલ કર્યો છે.',
    'If you continue to face issues, verify your Aadhaar card or contact our support team at +91-9699900111 for further assistance. We appreciate your cooperation.':'જો તમે સમસ્યાઓનો સામનો કરવાનું ચાલુ રાખો છો, તો તમારું આધાર કાર્ડ ચકાસો અથવા વધુ સહાયતા માટે +91-9699900111 પર અમારી સપોર્ટ ટીમનો સંપર્ક કરો. અમે તમારા સહકારની પ્રશંસા કરીએ છીએ.',
   
    // newly added
    "Please ensure you have entered the correct GST details and follow the specified format for accurate verification.": "કૃપા કરીને ખાતરી કરો કે તમે સાચી GST વિગતો દાખલ કરી છે અને ચોક્કસ ચકાસણી માટે ઉલ્લેખિત ફોર્મેટને અનુસરો.",
    "If you continue to face issues, kindly double-check your PAN card and re-enter the details.": "જો તમે સમસ્યાઓનો સામનો કરવાનું ચાલુ રાખો છો, તો કૃપા કરીને તમારું PAN કાર્ડ બે વાર તપાસો અને વિગતો ફરીથી દાખલ કરો.",
    "The GST provided already exists in our records. Please try using a different GST for verification.": "આપવામાં આવેલ GST અમારા રેકોર્ડમાં પહેલાથી જ અસ્તિત્વમાં છે. કૃપા કરીને ચકાસણી માટે અલગ GST નો ઉપયોગ કરવાનો પ્રયાસ કરો.",
    "It appears that the PAN details provided belong to a company. We require your personal PAN details for verification purposes.": "એવું લાગે છે કે આપવામાં આવેલી PAN વિગતો કંપનીની છે. ચકાસણી હેતુઓ માટે અમને તમારી વ્યક્તિગત PAN વિગતોની જરૂર છે.",
    "If you continue to face issues, kindly double-check your PAN card and re- enter the details.For further queries or assistance, feel free to call us at +91-9699900111.": "જો તમને સમસ્યાઓનો સામનો કરવાનું ચાલુ રહે છે, તો કૃપા કરીને તમારું PAN કાર્ડ બે વાર તપાસો અને વિગતો ફરીથી દાખલ કરો. વધુ પ્રશ્નો અથવા સહાયતા માટે, અમને +91-9699900111 પર કૉલ કરો.",
    "The PAN provided already exists in our records. Please try using a different PAN for verification.": "પ્રદાન કરેલ PAN અમારા રેકોર્ડ્સમાં પહેલાથી જ અસ્તિત્વમાં છે. કૃપા કરીને ચકાસણી માટે અલગ PAN નો ઉપયોગ કરવાનો પ્રયાસ કરો.",
    "New User Registration": "નવી વપરાશકર્તા નોંધણી",
    "To begin your journey with us, we kindly request all new users to register by following the simple steps outlined below:": "અમારી સાથે તમારી મુસાફરી શરૂ કરવા માટે, અમે બધા નવા વપરાશકર્તાઓને નીચે દર્શાવેલ સરળ પગલાંને અનુસરીને નોંધણી કરવા વિનંતી કરીએ છીએ:",
    "Registration": "નોંધણી",
    "To register and gain access to our comprehensive verification services, please proceed by making a one-time payment of": "નોંધણી કરવા અને અમારી વ્યાપક ચકાસણી સેવાઓની ઍક્સેસ મેળવવા માટે, કૃપા કરીને એક વખતની ચુકવણી કરીને આગળ વધો",
    "Rs. 500": "રૂ. 500",
    "GST": "GST",
    "done": "પૂર્ણ",
    "Please note that the registration fee is subject to 18% GST (Goods and Services Tax) as per government regulations.": "મહેરબાની કરીને નોંધ કરો કે નોંધણી ફી સરકારી નિયમો મુજબ 18% GST (ગુડ્સ એન્ડ સર્વિસ ટેક્સ) ને પાત્ર છે.",
    "Total Amount Due:": "કુલ બાકી રકમ:",
    "Rs. 590": "રૂ. 590",
    "Total Registration Fee + 18% GST": "કુલ નોંધણી ફી + 18% GST",
    "Secure Payment Gateway": "સુરક્ષિત પેમેન્ટ ગેટવે",
    "Rest assured, your payment and personal information are handled with the utmost security through our encrypted payment gateway.": "નિશ્ચિંત રહો, તમારી ચુકવણી અને વ્યક્તિગત માહિતી અમારા એન્ક્રિપ્ટેડ પેમેન્ટ ગેટવે દ્વારા અત્યંત સુરક્ષા સાથે નિયંત્રિત કરવામાં આવે છે.",
    "Registration Complete - Thank You!": "નોંધણી પૂર્ણ - આભાર!",
    "Congratulations! You've completed the registration process. Your dedication to providing the necessary information has brought us one step closer to serving you effectively.": "અભિનંદન! તમે નોંધણી પ્રક્રિયા પૂર્ણ કરી લીધી છે. જરૂરી માહિતી પ્રદાન કરવા માટેના તમારા સમર્પણ અમને અસરકારક રીતે તમારી સેવા કરવા માટે એક પગલું નજીક લાવ્યા છે.",
    "With your registration finalized, our team is fully equipped to handle your requirements efficiently. We assure you that your case will be handled with the utmost care and attention to detail.": "તમારી નોંધણીને આખરી ઓપ આપવા સાથે, અમારી ટીમ તમારી જરૂરિયાતોને અસરકારક રીતે હેન્ડલ કરવા માટે સંપૂર્ણપણે સજ્જ છે. અમે તમને ખાતરી આપીએ છીએ કે તમારા કેસને અત્યંત કાળજી અને વિગતવાર ધ્યાન સાથે સંભાળવામાં આવશે.",
    "Should you ever require assistance or have any queries, please feel free to contact us at any time. We are here to address your concerns and ensure a smooth journey throughout the process.": "જો તમને ક્યારેય સહાયની જરૂર હોય અથવા કોઈ પ્રશ્નો હોય, તો કૃપા કરીને કોઈપણ સમયે અમારો સંપર્ક કરવા માટે મફત લાગે. અમે તમારી ચિંતાઓને દૂર કરવા અને સમગ્ર પ્રક્રિયા દરમિયાન સરળ મુસાફરીની ખાતરી કરવા માટે અહીં છીએ.",
    "We eagerly anticipate the opportunity to assist you further.": "અમે તમને વધુ મદદ કરવાની તકની આતુરતાપૂર્વક અપેક્ષા રાખીએ છીએ.",
    "Warm regards,": "હાર્દિક સાદર,",
    "PrivateCourt Team": "ખાનગી કોર્ટ ટીમ",
    "Secure UDYAM Number Validation": "સુરક્ષિત UDYAM નંબર માન્યતા",
    "Enter UDYAM for": "માટે UDYAM દાખલ કરો",
    "having GST number": "GST નંબર ધરાવતી",
    "Enter Your Udyam Number": "તમારો ઉદ્યમ નંબર દાખલ કરો",
    "Error: Invalid UAM Number": "ભૂલ: અમાન્ય UAM નંબર",
    "We regret to inform you that the UAM Number entered isinvalid or incorrect. Please ensure you have accuratelyentered detail and double-check for any typos.": "અમે તમને જણાવતા દિલગીર છીએ કે દાખલ કરેલ UAM નંબર અમાન્ય અથવા ખોટો છે. કૃપા કરીને ખાતરી કરો કે તમે ચોક્કસ વિગતો દાખલ કરી છે અને કોઈપણ ટાઇપો માટે બે વાર તપાસો.",
    "Server Overload: Please Retry Later": "સર્વર ઓવરલોડ: કૃપા કરીને પછીથી ફરી પ્રયાસ કરો",
    "We apologize for the inconvenience and appreciate your patience. Currently, our server is experiencing a surge in requests, creating a temporary delay. Rest assured, we are diligently collecting your data to ensure accuracy and security.": "અસુવિધા બદલ અમે દિલગીર છીએ અને તમારી ધીરજની પ્રશંસા કરીએ છીએ. હાલમાં, અમારું સર્વર વિનંતીઓમાં વધારો અનુભવી રહ્યું છે, જેનાથી અસ્થાયી વિલંબ થઈ રહ્યો છે. નિશ્ચિંત રહો, ચોકસાઈ અને સુરક્ષા સુનિશ્ચિત કરવા માટે અમે ખંતપૂર્વક તમારો ડેટા એકત્રિત કરી રહ્યા છીએ.",
    "Please bear with us during this busy period, and we kindly request you to wait for a brief moment before attempting again. Your understanding is crucial in maintaining the quality of service we strive to provide.": "કૃપા કરીને આ વ્યસ્ત સમયગાળા દરમિયાન અમારી સાથે સહન કરો, અને અમે તમને વિનંતી કરીએ છીએ કે ફરી પ્રયાસ કરતા પહેલા થોડી ક્ષણ રાહ જુઓ. અમે પ્રદાન કરવાનો પ્રયાસ કરીએ છીએ તે સેવાની ગુણવત્તા જાળવવા માટે તમારી સમજ મહત્વપૂર્ણ છે.",
    "For urgent matters or assistance, feel free to call us at +91-9699900111.": "તાત્કાલિક બાબતો અથવા સહાયતા માટે, અમને +91-9699900111 પર કૉલ કરવા માટે નિઃસંકોચ.",
    "Thank you for your patience and entrusting PrivateCourt. Your security is our priority.": "તમારી ધીરજ અને પ્રાઈવેટકોર્ટને સોંપવા બદલ આભાર. તમારી સુરક્ષા અમારી પ્રાથમિકતા છે.",
    "If you continue to face issues, verify the": "જો તમે સમસ્યાઓનો સામનો કરવાનું ચાલુ રાખો છો, તો ચકાસો",
    "Error: Invalid OTP": "ભૂલ: અમાન્ય OTP",
    "We apologize for the inconvenience. The OTP entered is incorrect. Please double-check the OTP and try again.": "અસુવિધા માટે અમે દિલગીર છીએ. દાખલ કરેલ OTP ખોટો છે. કૃપા કરીને OTP બે વાર તપાસો અને ફરી પ્રયાસ કરો.",
    "Aadhaar card or contact our support team at +91-9699900111 for further assistance. We appreciate your cooperation.": "વધુ સહાયતા માટે આધાર કાર્ડ અથવા +91-9699900111 પર અમારી સપોર્ટ ટીમનો સંપર્ક કરો. અમે તમારા સહકારની પ્રશંસા કરીએ છીએ.",
    "Error: User already exists": "ભૂલ: વપરાશકર્તા પહેલેથી અસ્તિત્વમાં છે",
    "We apologize for the inconvenience. The Aadhaar number provided already exists in our records.Please provide a different Aadhaar number for verification.": "અસુવિધા માટે અમે દિલગીર છીએ. આપેલ આધાર નંબર અમારા રેકોર્ડમાં પહેલાથી જ અસ્તિત્વમાં છે. કૃપા કરીને ચકાસણી માટે અલગ આધાર નંબર આપો.",
    "We apologize for the inconvenience. The name provided does not match the PAN records. Please ensure that the name entered matches the information on your PAN card and try again.": "અસુવિધા માટે અમે દિલગીર છીએ. પ્રદાન કરેલ નામ PAN રેકોર્ડ સાથે મેળ ખાતું નથી. કૃપા કરીને ખાતરી કરો કે દાખલ કરેલ નામ તમારા PAN કાર્ડ પરની માહિતી સાથે મેળ ખાય છે અને ફરી પ્રયાસ કરો.",
    "Enter Bank Details for": "માટે બેંક વિગતો દાખલ કરો",
    "Error: Already Exists": "ભૂલ: પહેલેથી જ અસ્તિત્વમાં છે",
    "We regret to inform you that the ID you've provided already exists in our system. Please try another ID.": "અમે તમને જણાવતા ખેદ અનુભવીએ છીએ કે તમે પ્રદાન કરેલ ID અમારી સિસ્ટમમાં પહેલાથી જ અસ્તિત્વમાં છે. કૃપા કરીને અન્ય ID અજમાવી જુઓ.",
    "If you continue to face issues, kindly verify your bank statement or contact your bank to confirm the accuracy of the provided information.": "જો તમે સમસ્યાઓનો સામનો કરવાનું ચાલુ રાખો છો, તો કૃપા કરીને તમારું બેંક સ્ટેટમેન્ટ ચકાસો અથવા પ્રદાન કરેલી માહિતીની ચોકસાઈની પુષ્ટિ કરવા માટે તમારી બેંકનો સંપર્ક કરો.",
    "Error: Name Not Matched": "ભૂલ: નામ મેળ ખાતું નથી",
    "Good Afternoon": "શુભ અપરાહ્ન",
    "Account security starts with verification": "એકાઉન્ટ સુરક્ષા ચકાસણી સાથે શરૂ થાય છે",
    "OTP Validation for Account Access": "એકાઉન્ટ એક્સેસ માટે OTP માન્યતા",
    "Kindly check your inbox, and enter the OTP in the designated field to complete the verification process.": "કૃપા કરીને તમારું ઇનબોક્સ તપાસો, અને ચકાસણી પ્રક્રિયા પૂર્ણ કરવા માટે નિયુક્ત ફીલ્ડમાં OTP દાખલ કરો.",
    "Request a Resend": "ફરીથી મોકલવાની વિનંતી કરો",
    "Error: Incorrect OTP": "ભૂલ: ખોટો OTP",
    "We apologize for the inconvenience. The One-Time Password (OTP) entered is incorrect. Please ensure that you have accurately entered the OTP received in your email.": "અસુવિધા માટે અમે દિલગીર છીએ. દાખલ કરેલ વન-ટાઇમ પાસવર્ડ (OTP) ખોટો છે. કૃપા કરીને ખાતરી કરો કે તમે તમારા ઈમેલમાં પ્રાપ્ત થયેલ OTP સચોટ રીતે દાખલ કર્યો છે.",
    "If the issue persists, you may request a new OTP by clicking on the resend link or contacting our support team  at +91-9699900111.": "જો સમસ્યા ચાલુ રહે, તો તમે ફરીથી મોકલો લિંક પર ક્લિક કરીને અથવા +91-9699900111 પર અમારી સપોર્ટ ટીમનો સંપર્ક કરીને નવા OTPની વિનંતી કરી શકો છો.",
    "Double-check the email and try entering the OTP again.": "ઈમેલને બે વાર તપાસો અને ફરીથી OTP દાખલ કરવાનો પ્રયાસ કરો.",
    "At PrivateCourt, we prioritize the security and integrity of our services. Our GST verification process is meticulously designed to ensure a reliable and secure experience for all our users.": "પ્રાઇવેટકોર્ટમાં, અમે અમારી સેવાઓની સુરક્ષા અને અખંડિતતાને પ્રાથમિકતા આપીએ છીએ. અમારા તમામ વપરાશકર્તાઓ માટે વિશ્વસનીય અને સુરક્ષિત અનુભવ સુનિશ્ચિત કરવા માટે અમારી GST વેરિફિકેશન પ્રક્રિયા સાવચેતીપૂર્વક ડિઝાઇન કરવામાં આવી છે.",
    "Enter Your GSTIN": "તમારો GSTIN દાખલ કરો",
    "Please enter your correct GST Identification Number (GSTIN) in the format 00-XXXXX-0000-X-0-X-0.": "કૃપા કરીને તમારો સાચો GST ઓળખ નંબર (GSTIN) 00-XXXXX-0000-X-0-X-0 ફોર્મેટમાં દાખલ કરો.",
    "Upon entering your GSTIN, PrivateCourt will diligently collect essential information associated with it. This includes your full name, date of birth, residential address, and contact details. For business owners, we extend our data collection to encompass all pertinent business details tied to your GSTIN, such as MCA specifics and records of GST return filings.": "તમારો GSTIN દાખલ કરવા પર, PrivateCourt ખંતપૂર્વક તેની સાથે સંકળાયેલ આવશ્યક માહિતી એકત્રિત કરશે. આમાં તમારું પૂરું નામ, જન્મ તારીખ, રહેઠાણનું સરનામું અને સંપર્ક વિગતોનો સમાવેશ થાય છે. વ્યવસાયના માલિકો માટે, અમે તમારા GSTIN સાથે જોડાયેલ તમામ સંબંધિત વ્યવસાય વિગતો, જેમ કે MCA સ્પષ્ટીકરણો અને GST રિટર્ન ફાઇલિંગના રેકોર્ડ્સનો સમાવેશ કરવા માટે અમારો ડેટા સંગ્રહ વિસ્તારીએ છીએ.",
    "Our system assesses your CIBIL score for financial insight and cross-verifies for any ongoing court cases associated with your GSTIN.": "અમારી સિસ્ટમ નાણાકીય આંતરદૃષ્ટિ માટે તમારા CIBIL સ્કોરનું મૂલ્યાંકન કરે છે અને તમારા GSTIN સાથે સંકળાયેલા કોઈપણ ચાલી રહેલા કોર્ટ કેસ માટે ક્રોસ-વેરિફાય કરે છે.",
    "Error: Invalid GST Format": "ભૂલ: અમાન્ય GST ફોર્મેટ",
    "Please enter your personal PAN": "કૃપા કરીને તમારો વ્યક્તિગત PAN દાખલ કરો",
    "Already Exists": "પહેલાથી અસ્તિત્વમાં",
    "Invalid": "અમાન્ય",
    "Secure PAN Validation Process": "સુરક્ષિત PAN માન્યતા પ્રક્રિયા",
    "This verification process is designed to uphold the integrity of our services, ensuring a reliable and secure experience.": "આ ચકાસણી પ્રક્રિયા વિશ્વસનીય અને સુરક્ષિત અનુભવને સુનિશ્ચિત કરીને અમારી સેવાઓની અખંડિતતાને જાળવી રાખવા માટે બનાવવામાં આવી છે.",
    "Enter Your PAN Number": "તમારો PAN નંબર દાખલ કરો",
    "Please enter your correct PAN number in the format XXXXX-0000-X.": "કૃપા કરીને તમારો સાચો PAN નંબર XXXXX-0000-X ફોર્મેટમાં દાખલ કરો.",
    "PrivateCourt will meticulously collect essential information associated with your PAN such as your full name, date of birth, residential address, and contact details. For Business Owners, we extend our data collection to encompass all pertinent business details tied to your PAN such as GST details, MCA specifics, and records of GST return filings.": "પ્રાઇવેટકોર્ટ કાળજીપૂર્વક તમારા PAN સાથે સંકળાયેલ આવશ્યક માહિતી જેમ કે તમારું પૂરું નામ, જન્મ તારીખ, રહેણાંક સરનામું અને સંપર્ક વિગતો એકત્રિત કરશે. વ્યાપાર માલિકો માટે, અમે તમારા PAN સાથે જોડાયેલ તમામ સંબંધિત વ્યવસાય વિગતો જેમ કે GST વિગતો, MCA સ્પષ્ટીકરણો અને GST રિટર્ન ફાઇલિંગના રેકોર્ડ્સનો સમાવેશ કરવા માટે અમારા ડેટા સંગ્રહને વિસ્તારીએ છીએ.",
    "Our system assesses your CIBIL score for financial insight and cross-verifies for any ongoing court cases associated with your PAN.": "અમારી સિસ્ટમ નાણાકીય સૂઝ માટે તમારા CIBIL સ્કોરનું મૂલ્યાંકન કરે છે અને તમારા PAN સાથે સંકળાયેલા કોઈપણ ચાલી રહેલા કોર્ટ કેસ માટે ક્રોસ-વેરિફાય કરે છે.",
    "It appears that the PAN details provided belong to a company. We require your personal PAN details for verification purposes.": "એવું લાગે છે કે આપવામાં આવેલી PAN વિગતો કંપનીની છે. ચકાસણી હેતુઓ માટે અમને તમારી વ્યક્તિગત PAN વિગતોની જરૂર છે.",


    "Data Compilation in Progress: Please Allow Processing Time": "ડેટા સંકલન પ્રગતિમાં છે: કૃપા કરીને પ્રક્રિયા સમયની મંજૂરી આપો",
    "Thank you for providing your information. Our system is currently in the process of analyzing and compiling the data you've entered, including details such as PAN, Aadhaar, GST, Email, and more, to establish your comprehensive profile.": "તમારી માહિતી પ્રદાન કરવા બદલ તમારો આભાર. અમારી સિસ્ટમ હાલમાં તમે દાખલ કરેલ ડેટાનું વિશ્લેષણ અને સંકલન કરવાની પ્રક્રિયામાં છે, જેમાં PAN, Aadhaar, GST, Email, અને વધુ જેવી વિગતોનો સમાવેશ થાય છે, તમારી વ્યાપક પ્રોફાઇલ સ્થાપિત કરવા માટે.",
    "This operation may take a few moments. During this time, your screen may show minimal activity. Please be assured that this is a routine part of our data compilation process.": "આ ઓપરેશનમાં થોડો સમય લાગી શકે છે. આ સમય દરમિયાન, તમારી સ્ક્રીન ન્યૂનતમ પ્રવૃત્તિ બતાવી શકે છે. કૃપા કરીને ખાતરી કરો કે આ અમારી ડેટા સંકલન પ્રક્રિયાનો નિયમિત ભાગ છે.",
    "Your profile is being meticulously crafted to uphold accuracy and security. We sincerely appreciate your patience and understanding as we work to enhance your experience.": "સચોટતા અને સુરક્ષાને જાળવી રાખવા માટે તમારી પ્રોફાઇલને ઝીણવટપૂર્વક બનાવવામાં આવી રહી છે. અમે તમારી ધીરજ અને સમજણની નિષ્ઠાપૂર્વક પ્રશંસા કરીએ છીએ કારણ કે અમે તમારા અનુભવને વધારવા માટે કામ કરીએ છીએ.",
    "For urgent matters or assistance, feel free to call us at +91-9699900111.": "તાત્કાલિક બાબતો અથવા સહાયતા માટે, અમને +91-9699900111 પર કૉલ કરવા માટે નિઃસંકોચ.",
    "Thank you for your patience and entrusting PrivateCourt. Your security is our priority.": "તમારી ધીરજ અને પ્રાઈવેટકોર્ટને સોંપવા બદલ આભાર. તમારી સુરક્ષા અમારી પ્રાથમિકતા છે.",
    "Please input your Aadhaar number. This process ensures accurate cross-checking for your security. Thank you for your cooperation.": "કૃપા કરીને તમારો આધાર નંબર દાખલ કરો. આ પ્રક્રિયા તમારી સુરક્ષા માટે ચોક્કસ ક્રોસ-ચેકિંગની ખાતરી આપે છે. તમારા સહકાર બદલ આભાર.",
    "We apologize for the inconvenience. The Aadhaar number entered is invalid. Please double-check and ensure that you have entered the": "અસુવિધા માટે અમે દિલગીર છીએ. દાખલ કરેલ આધાર નંબર અમાન્ય છે. કૃપા કરીને બે વાર તપાસો અને ખાતરી કરો કે તમે દાખલ કર્યું છે",
    "Applicant’s correct 12-digit Aadhaar number.": "અરજદારનો સાચો 12-અંકનો આધાર નંબર.",
    "Error: Invalid OTP": "ભૂલ: અમાન્ય OTP",
    "We apologize for the inconvenience. The OTP entered is incorrect. Please double-check the OTP and try again.": "અસુવિધા માટે અમે દિલગીર છીએ. દાખલ કરેલ OTP ખોટો છે. કૃપા કરીને OTP બે વાર તપાસો અને ફરી પ્રયાસ કરો.",
    "Welcome": "સ્વાગત છે",
    "Ensuring the accuracy of your bank account information is crucial for a seamless verification process.Kindly enter your valid Account Number, and input the correct IFSC code associated with your account.": "સીમલેસ વેરિફિકેશન પ્રક્રિયા માટે તમારા બેંક ખાતાની માહિતીની ચોકસાઈની ખાતરી કરવી મહત્વપૂર્ણ છે. કૃપા કરીને તમારો માન્ય એકાઉન્ટ નંબર દાખલ કરો અને તમારા ખાતા સાથે સંકળાયેલ સાચો IFSC કોડ દાખલ કરો.",
    "This verification step is designed to enhance the security of our services and provide you with a reliable experience.": "આ ચકાસણી પગલું અમારી સેવાઓની સુરક્ષા વધારવા અને તમને વિશ્વસનીય અનુભવ પ્રદાન કરવા માટે રચાયેલ છે.",
    "We apologize for the inconvenience. The name provided does not match the GST records. Please ensure that the name entered matches the information on your GST card and try again.": "અસુવિધા માટે અમે દિલગીર છીએ. પ્રદાન કરેલ નામ GST રેકોર્ડ સાથે મેળ ખાતું નથી. કૃપા કરીને ખાતરી કરો કે દાખલ કરેલ નામ તમારા GST કાર્ડ પરની માહિતી સાથે મેળ ખાય છે અને ફરી પ્રયાસ કરો.",
    "Business Verification Process": "વ્યવસાય ચકાસણી પ્રક્રિયા",
    "Ensuring Secure Business Validation- This verification process is meticulously designed to uphold the integrity of our services, providing a reliable and secure experience.": "સુરક્ષિત વ્યાપાર માન્યતા સુનિશ્ચિત કરવી- આ ચકાસણી પ્રક્રિયા અમારી સેવાઓની અખંડિતતાને જાળવી રાખવા માટે કાળજીપૂર્વક ડિઝાઇન કરવામાં આવી છે, જે એક વિશ્વસનીય અને સુરક્ષિત અનુભવ પ્રદાન કરે છે.",
    "Please provide details for each of your businesses for accurate validation.": "સચોટ માન્યતા માટે કૃપા કરીને તમારા દરેક વ્યવસાયની વિગતો આપો.",
    "PrivateCourt will collect essential information associated with your PAN such as your full name, date of birth, residential address, and contact details. For Business Owners, we extend our data collection to encompass all pertinent business details tied to your PAN such as GST details, MCA specifics, and records of GST return filings.": "પ્રાઈવેટકોર્ટ તમારા PAN સાથે સંકળાયેલ આવશ્યક માહિતી જેમ કે તમારું પૂરું નામ, જન્મ તારીખ, રહેઠાણનું સરનામું અને સંપર્ક વિગતો એકત્રિત કરશે. વ્યાપાર માલિકો માટે, અમે તમારા PAN સાથે જોડાયેલ તમામ સંબંધિત વ્યવસાય વિગતો જેમ કે GST વિગતો, MCA સ્પષ્ટીકરણો અને GST રિટર્ન ફાઇલિંગના રેકોર્ડ્સનો સમાવેશ કરવા માટે અમારા ડેટા સંગ્રહને વિસ્તારીએ છીએ.",
    "Our system evaluates your CIBIL score for financial insight and cross-verifies for any ongoing court cases associated with your PAN.": "અમારી સિસ્ટમ નાણાકીય સૂઝ માટે તમારા CIBIL સ્કોરનું મૂલ્યાંકન કરે છે અને તમારા PAN સાથે સંકળાયેલા કોઈપણ ચાલી રહેલા કોર્ટ કેસ માટે ક્રોસ-વેરિફાય કરે છે.",
    "To ensure the security of your account, please enter your valid Email ID in the specified format- username@domain.com. If your email address doesn't exist in our system, a": "તમારા એકાઉન્ટની સુરક્ષા સુનિશ્ચિત કરવા માટે, કૃપા કરીને ઉલ્લેખિત ફોર્મેટમાં તમારું માન્ય ઈમેલ આઈડી દાખલ કરો- username@domain.com. જો તમારું ઇમેઇલ સરનામું અમારી સિસ્ટમમાં અસ્તિત્વમાં નથી, તો એ",
    "account will be created for you.": "તમારા માટે એકાઉન્ટ બનાવવામાં આવશે.",
    "GST Verification Process": "GST ચકાસણી પ્રક્રિયા",
    "We regret to inform you that the GST number entered does not match the required format (00-XXXXX-0000-X-0-X-0).Please ensure you have entered the correct GST details and follow the specified format for accurate verification.": "અમે તમને જણાવતા દિલગીર છીએ કે દાખલ કરેલ GST નંબર જરૂરી ફોર્મેટ (00-XXXXX-0000-X-0-X-0) સાથે મેળ ખાતો નથી. કૃપા કરીને ખાતરી કરો કે તમે સાચી GST વિગતો દાખલ કરી છે અને ચોક્કસ ચકાસણી માટે ઉલ્લેખિત ફોર્મેટને અનુસરો.",
    "Verify GST": "GST ચકાસો",
    "Congratulations! You've completed the registration process. Your dedication to providing the necessary information has brought us one step closer to serving you effectively.": "અભિનંદન! તમે નોંધણી પ્રક્રિયા પૂર્ણ કરી લીધી છે. જરૂરી માહિતી પ્રદાન કરવા માટેના તમારા સમર્પણ અમને અસરકારક રીતે તમારી સેવા કરવા માટે એક પગલું નજીક લાવ્યા છે.",
    "We apologize for the inconvenience. The name provided does not match the GST records. Please ensure that the name entered matches the information on your GST card and try again.": "અસુવિધા માટે અમે દિલગીર છીએ. પ્રદાન કરેલ નામ GST રેકોર્ડ સાથે મેળ ખાતું નથી. કૃપા કરીને ખાતરી કરો કે દાખલ કરેલ નામ તમારા GST કાર્ડ પરની માહિતી સાથે મેળ ખાય છે અને ફરી પ્રયાસ કરો.",
    "Skip": "છોડો",
    "Server Overload: Please Retry Later": "સર્વર ઓવરલોડ: કૃપા કરીને પછીથી ફરી પ્રયાસ કરો",
    "Back To Home": "ઘરે પાછા",


    //Dispute WebsPage
    "Verify Aadhaar":"આધારને ચકાસો",
    "less than Rs. 10000/- per month": "મહિને Rs. 10000/- કરતા ઓછા",
    "less than Rs. 20,000/- per month": "મહિને Rs. 20,000/- કરતા ઓછા",
    "less than Rs. 40,000/- per month": "મહિને Rs. 40,000/- કરતા ઓછા",
    "less than Rs. 60,000/- per month": "મહિને Rs. 60,000/- કરતા ઓછા",
    "less than Rs. 1,00,000/- per month": "મહિને Rs. 1,00,000/- કરતા ઓછા",
    "more than Rs. 1,00,000/- per month": "મહિને Rs. 1,00,000/- કરતા વધુ",
    "We appreciate your openness to resolving your outstanding dues. To better understand your financial situation and tailor our approach accordingly,": "તમારું બકી રહેલું વ્યાજ સમાધાન કરવામાં તમારું ખોલાપન સરખાવવાનું આભારી છે. તમારા આર્થિક સ્થિતિને સ્પષ્ટ કરવા અને અમારી પહોંચ અનુસાર અમલ કરવા માટે,",
    "could you kindly provide details on your current family income?": "કૃપા કરીને તમારી હાલના કુટુંબ આવકની વિગતો આપી શકો?",
    "In our efforts to address your outstanding dues, we'd like to know if a": "તમારા બાકી રહેલા પૈસાના મુદ્દાને સમાધાન કરવામાં, અમાં તમારે જાણવાની આવડ છે કે એક ",
    "one-time payment settlement is an option for you.": "એક વખત ચૂકવણી સેટલમેન્ટ તમારે માટે વિકલ્પ છે કે નહિં.",
    "Payment Date":"ચૂકવણીની તારીખ",
    "Aadhaar Verification":"આધાર ચકાસણી",
    "To proceed further, we have initiated the Aadhaar verification process. Please provide the OTP (One-Time Password) that you have received to complete this step. Ensuring the security and authenticity of our users is paramount to us, and this verification step helps us maintain the integrity of our services.":"આગળ વધવા માટે, અમે આધાર ચકાસણી પ્રક્રિયા શરૂ કરી છે. કૃપા કરીને આ પગલું પૂર્ણ કરવા માટે તમને મળેલ ઓટીપી (વન-ટાઇમ પાસવર્ડ) પ્રદાન કરો. અમારા વપરાશકર્તાઓની સુરક્ષા અને પ્રામાણિકતાની ખાતરી કરવી એ અમારા માટે સર્વોચ્ચ છે, અને ચકાસણીનું આ પગલું અમને અમારી સેવાઓની અખંડિતતા જાળવવામાં મદદ કરે છે.",
    "Thank You Aadhaar Verification Successfully":"આધાર ચકાસણી સફળતાપૂર્વક તમારો આભાર",
    "Thank you for providing the OTP for Aadhaar verification. With this step completed, we are now ready to proceed with your case.":"આધાર ચકાસણી માટે ઓટીપી આપવા બદલ આભાર. આ પગલું પૂર્ણ થયા પછી, અમે હવે તમારા કેસ સાથે આગળ વધવા માટે તૈયાર છીએ.",
    "Our team will continue to work diligently to bring about a resolution that meets your needs and expectations. Should you have any questions or concerns along the way, please don't hesitate to reach out to us.":"અમારી ટીમ તમારી જરૂરિયાતો અને અપેક્ષાઓને પૂર્ણ કરે તેવા ઠરાવ લાવવા માટે ખંતપૂર્વક કામ કરવાનું ચાલુ રાખશે. જો તમને રસ્તામાં કોઈ પ્રશ્નો અથવા ચિંતાઓ હોય, તો કૃપા કરીને અમારો સંપર્ક કરવામાં અચકાશો નહીં.",
    "Once again, thank you for entrusting PrivateCourt with your dispute resolution needs. We look forward to serving you.":"ફરી એક વાર, તમારી વિવાદ નિવારણ જરૂરિયાતોને ખાનગી અદાલતને સોંપવા બદલ તમારો આભાર. અમે તમારી સેવા કરવા માટે ઉત્સુક છીએ.",
    // "Warm regards,":"",
    // "PrivateCourt Team":"",
    'Oops!':"ઓહ!",
    "The page you are searching, can't be found.":"તમે શોધી રહ્યા છો તે પાનું, શોધી શકાતું નથી.",
    "Language Selection:": "ભાષા પસંદગી:",
    "Empowering Effective Communication": "અસરકારક સંચારને સશક્ત બનાવવું",
    "Good day! I'm Murthy, your dedicated assistant for today's proceedings. Lets start by selecting the language you're most comfortable with.":"શુભ દિવસ! હું મૂર્તિ છું, આજની કાર્યવાહી માટે તમારો સમર્પિત સહાયક છું. ચાલો તમને સૌથી વધુ અનુકૂળ હોય તેવી ભાષા પસંદ કરીને શરૂઆત કરીએ.",
    "Select Your Preferred Language": "તમારી પસંદગીની ભાષા પસંદ કરો",
    "Opting for your preferred language ensures clear and effective communication. Whether it's your native tongue or another language of your choice, selecting it will streamline our interaction and enhance your experience.":"તમારી પસંદગીની ભાષા પસંદ કરવાથી સ્પષ્ટ અને અસરકારક સંચાર સુનિશ્ચિત થાય છે. ભલે તે તમારી માતૃભાષા હોય કે તમારી પસંદગીની અન્ય ભાષા, તેને પસંદ કરવાથી અમારી ક્રિયાપ્રતિક્રિયા સુવ્યવસ્થિત થશે અને તમારા અનુભવમાં વધારો થશે.",
    "Next": "આગળ",
    "Welcome to PrivateCourt, where Helping you is our priority.": "પ્રાઈવેટકોર્ટમાં આપનું સ્વાગત છે, જ્યાં તમને મદદ કરવી એ અમારી પ્રાથમિકતા છે.",
    "Now, let me introduce you to PrivateCourt – your reliable partner in resolving lending disputes effectively and efficiently.": "હવે, ચાલો હું તમને પ્રાઈવેટકોર્ટ સાથે પરિચય કરાવું - ધિરાણ વિવાદોને અસરકારક અને અસરકારક રીતે ઉકેલવામાં તમારા વિશ્વસનીય ભાગીદાર.",
    "PrivateCourt":"પ્રાઇવેટકોર્ટ",
    "Your trusted partner in resolving lending disputes with care and expertise.":"કાળજી અને કુશળતા સાથે ધિરાણ વિવાદોને ઉકેલવામાં તમારો વિશ્વાસુ ભાગીદાર.",
    "Welcome to PrivateCourt, where your case details are in good hands. With the lending company initiating arbitration or conciliation, it's essential for us to understand your situation fully. Please share all the details you can.":"પ્રાઇવેટકોર્ટમાં આપનું સ્વાગત છે, જ્યાં તમારા કેસની વિગતો સારા હાથમાં છે. ધિરાણ આપતી કંપની આર્બિટ્રેશન અથવા સમાધાન શરૂ કરે છે, તે અમારા માટે તમારી પરિસ્થિતિને સંપૂર્ણ રીતે સમજવું આવશ્યક છે. કૃપા કરીને તમે કરી શકો તે બધી વિગતો શેર કરો.",
    "The more information we have, the better we can help. Your input is crucial in finding the right solution for you.":"અમારી પાસે જેટલી વધુ માહિતી છે, તેટલી સારી રીતે અમે મદદ કરી શકીએ છીએ. તમારા માટે યોગ્ય ઉકેલ શોધવામાં તમારું ઇનપુટ મહત્વપૂર્ણ છે.",
    "Trust us to carefully examine your case and work together to find the best way forward.":"તમારા કેસની કાળજીપૂર્વક તપાસ કરવા અને આગળનો શ્રેષ્ઠ માર્ગ શોધવા માટે સાથે મળીને કામ કરવા માટે અમારા પર વિશ્વાસ કરો.",
    "Reviewing Your Dispute Information": "તમારી વિવાદની માહિતીની સમીક્ષા કરવી",
    "Let's take a closer look at the details surrounding your dispute. The information we're discussing today has been shared with us by Company, regarding a personal loan.": "ચાલો તમારા વિવાદની આસપાસની વિગતો પર નજીકથી નજર કરીએ. આજે આપણે જે માહિતીની ચર્ચા કરી રહ્યા છીએ તે વ્યક્તિગત લોન સંબંધિત Company દ્વારા અમારી સાથે શેર કરવામાં આવી છે.",
    "By understanding these specifics, we can better assess the situation and formulate a resolution plan that aligns with your circumstances and goals.":"આ વિશિષ્ટતાઓને સમજીને, અમે પરિસ્થિતિનું વધુ સારી રીતે મૂલ્યાંકન કરી શકીએ છીએ અને તમારા સંજોગો અને ધ્યેયોને અનુરૂપ રિઝોલ્યુશન પ્લાન બનાવી શકીએ છીએ.",
    "Dispute Amount Information": "વિવાદની રકમની માહિતી",
    "According to their records, you initially borrowed an amount of": "તેમના રેકોર્ડ મુજબ, તમે શરૂઆતમાં ઉછીના લીધેલ રકમ",
    "Presently, your outstanding due stands at Rs.": "હાલમાં, તમારી બાકી લેણી રકમ રૂ.",
    "These details provide us with valuable insight into the history and current status of your loan.":"આ વિગતો અમને તમારી લોનના ઈતિહાસ અને વર્તમાન સ્થિતિ વિશે મૂલ્યવાન સમજ આપે છે.",
    "Spotting Financial Issues: Understanding Unpaid Obligations": "નાણાકીય સમસ્યાઓ શોધવી: અવેતન જવાબદારીઓને સમજવી",
    "Now, let's gently explore the challenges you've faced due to financial constraints. Discussing this may be sensitive, but your openness will greatly assist us in finding the best path forward. Please take a moment to select any of the following options that resonate with your experience:":"હવે, ચાલો આપણે નાણાકીય અવરોધોને લીધે તમે જે પડકારોનો સામનો કર્યો હોય તેને હળવાશથી અન્વેષણ કરીએ. આ અંગે ચર્ચા કરવી કદાચ સંવેદનશીલ હશે, પરંતુ તમારી નિખાલસતા અમને આગળનો શ્રેષ્ઠ માર્ગ શોધવામાં ઘણી મદદ કરશે. કૃપા કરીને નીચેનામાંથી કોઈપણ વિકલ્પ પસંદ કરવા માટે થોડો સમય ફાળવો જે તમારા અનુભવ સાથે પડઘો પાડે છે:",
    "Financial Loss": "નાણાકીય નુકસાન",
    "Business Loss": "ધંધામાં નુકસાન",
    "Job Loss": "નોકરીની ખોટ",
    "Health Issue": "આરોગ્ય સમસ્યા",
    "Death of the earning member": "કમાતા સભ્યનું મૃત્યુ",
    "Missing payment details": "ચુકવણી વિગતો ખૂટે છે",
    "Dispute due to bad behaviour": "ખરાબ વર્તનને કારણે વિવાદ",
    "No clear loan information": "કોઈ સ્પષ્ટ લોન માહિતી નથી",
    "Death Case": "મૃત્યુ કેસ",
    "We kindly request you to upload the death certificate at": "અમે તમને Legal@privatecourt.in પર મૃત્યુ પ્રમાણપત્ર અપલોડ કરવા વિનંતી કરીએ છીએ",
    "Other reason/s": "અન્ય કારણ/ઓ",
    "Your prompt response will greatly assist us in assessing the situation and finding the most suitable resolution. Rest assured, we are committed to working with you to resolve any outstanding issues and ensure a fair and satisfactory outcome.":"તમારો ત્વરિત પ્રતિસાદ પરિસ્થિતિનું મૂલ્યાંકન કરવામાં અને સૌથી યોગ્ય ઉકેલ શોધવામાં અમને ખૂબ મદદ કરશે. નિશ્ચિંત રહો, અમે કોઈપણ બાકી મુદ્દાઓને ઉકેલવા અને ન્યાયી અને સંતોષકારક પરિણામની ખાતરી કરવા તમારી સાથે કામ કરવા માટે પ્રતિબદ્ધ છીએ.",
    "Partner Dispute": "પાર્ટનર વિવાદ",
    "Funds held with other vendors or clients": "અન્ય વિક્રેતાઓ અથવા ગ્રાહકો સાથે રાખવામાં આવેલ ભંડોળ",
    "Monetary loss through other means": "અન્ય માધ્યમો દ્વારા નાણાકીય નુકસાન",
    "Less than 2 months": "બે મહિના કરતાં ઓછા",
    "Less than 6 months": "છ મહિના કરતાં ઓછા",
    "Less than 1 year": "એક વર્ષથી ઓછા",
    "More than 1 year": "એક વર્ષથી વધુ",
    "Exploring Settlement Possibilities": "પતાવટની શક્યતાઓનું અન્વેષણ",
    "As our discussion progresses, I appreciate your openness in explaining the reason for the default. It's crucial to address your outstanding dues promptly to prevent any legal complexities. We recommend exploring different avenues, such as seeking assistance from family or investigating alternative financing options, to fulfill your obligations without delay.":"જેમ જેમ અમારી ચર્ચા આગળ વધે છે, તેમ તેમ ડિફોલ્ટનું કારણ સમજાવવામાં તમારી નિખાલસતાની હું પ્રશંસા કરું છું. કોઈપણ કાનૂની ગૂંચવણોને રોકવા માટે તમારા બાકી લેણાંને તાત્કાલિક સંબોધવા તે મહત્વપૂર્ણ છે. અમે વિલંબ કર્યા વિના તમારી જવાબદારીઓને પૂર્ણ કરવા માટે કુટુંબ પાસેથી સહાય મેળવવા અથવા વૈકલ્પિક ધિરાણ વિકલ્પોની તપાસ કરવા જેવા વિવિધ માર્ગોની શોધ કરવાની ભલામણ કરીએ છીએ.",
    "Timely settlement not only mitigates legal risks but also strengthens trust and credibility in your financial interactions. Your cooperation in this matter is truly valued.": "સમયસર પતાવટ માત્ર કાનૂની જોખમોને જ નહીં પરંતુ તમારી નાણાકીય ક્રિયાપ્રતિક્રિયાઓમાં વિશ્વાસ અને વિશ્વસનીયતાને પણ મજબૂત બનાવે છે. આ બાબતે તમારો સહકાર ખરેખર મૂલ્યવાન છે.",
    "Settlement Options": "પતાવટ વિકલ્પો",
    "In our efforts to facilitate a swift resolution, we seek your cooperation.":"ઝડપી રિઝોલ્યુશનની સુવિધા આપવાના અમારા પ્રયાસોમાં, અમે તમારો સહકાર માંગીએ છીએ.",
    "Are you open to discussing the settlement of your outstanding dues?": "શું તમે તમારા બાકી લેણાંની પતાવટ અંગે ચર્ચા કરવા તૈયાર છો?",
    "Please Select": "કૃપા કરી પસંદ કરો",
    "Yes": "હા",
    "No": "ના",
    "I am unable to pay by any means": "હું કોઈપણ રીતે ચૂકવણી કરવામાં અસમર્થ છું",
    "We Appreciate Your Honesty.": "અમે તમારી પ્રામાણિકતાની પ્રશંસા કરીએ છીએ.",
    "Aadhaar Verify": "આધાર ચકાસો",
    "We appreciate your openness to resolving your outstanding dues. To better understand your financial situation and tailor our approach accordingly, could you kindly provide details on your current family income?":"અમે તમારા બાકી લેણાંના નિરાકરણ માટે તમારી નિખાલસતાની પ્રશંસા કરીએ છીએ. તમારી નાણાકીય પરિસ્થિતિને વધુ સારી રીતે સમજવા અને તે મુજબ અમારા અભિગમને અનુરૂપ બનાવવા માટે, શું તમે કૃપા કરીને તમારી વર્તમાન કૌટુંબિક આવકની વિગતો આપી શકો છો?",
    "No Income": "આવક નથી",
    "less than Rs. 10000/- per month": "રૂ કરતાં ઓછી 10000/- દર મહિને",
    "less than Rs. 20000/- per month": "રૂ કરતાં ઓછી 20000/- દર મહિને",
    "less than Rs. 40000/- per month": "રૂ કરતાં ઓછી 40000/- દર મહિને",
    "more than Rs. 100000/- per month": "રૂ. કરતાં વધુ 100000/- દર મહિને",
    "In our efforts to address your outstanding dues, we'd like to know if a one-time payment settlement is an option for you.":"તમારા બાકી લેણાંને સંબોધવાના અમારા પ્રયાસોમાં, અમે જાણવા માંગીએ છીએ કે તમારા માટે વન-ટાઇમ પેમેન્ટ સેટલમેન્ટ એક વિકલ્પ છે કે કેમ.",
    "We appreciate your feedback. If a one-time payment is not viable, could you consider arranging payment in three equal instalments?":"અમે તમારા પ્રતિસાદની પ્રશંસા કરીએ છીએ. જો એક વખતની ચૂકવણી યોગ્ય ન હોય, તો શું તમે ત્રણ સમાન હપ્તામાં ચુકવણીની વ્યવસ્થા કરવાનું વિચારી શકો છો?",
    "This approach could help ease any immediate financial strain while still addressing the outstanding dues.": "આ અભિગમ બાકી લેણાંને સંબોધિત કરતી વખતે કોઈપણ તાત્કાલિક નાણાકીય તાણને સરળ બનાવવામાં મદદ કરી શકે છે.",
    "Aadhaar Verification": "આધાર ચકાસણી",
    "To proceed further, we have initiated the Aadhaar verification process. Please provide the OTP (One-Time Password) that you have received to complete this step. Ensuring the security and authenticity of our users is paramount to us, and this verification step helps us maintain the integrity of our services.":"આગળ વધવા માટે, અમે આધાર વેરિફિકેશન પ્રક્રિયા શરૂ કરી છે. કૃપા કરીને આ પગલું પૂર્ણ કરવા માટે તમને પ્રાપ્ત થયેલ OTP (વન-ટાઇમ પાસવર્ડ) પ્રદાન કરો. અમારા વપરાશકર્તાઓની સુરક્ષા અને અધિકૃતતાની ખાતરી કરવી એ અમારા માટે સર્વોપરી છે અને આ ચકાસણી પગલું અમને અમારી સેવાઓની અખંડિતતા જાળવવામાં મદદ કરે છે.",
    "Enter the Applicant’s Aadhaar Number": "અરજદારનો આધાર નંબર દાખલ કરો",
    "Please input the Applicant’s Aadhaar number along with essential demographic details—name, date of birth, and gender. This process ensures accurate cross-checking for security. Thank you for your cooperation.":"કૃપા કરીને આવશ્યક વસ્તી વિષયક વિગતો-નામ, જન્મ તારીખ અને જાતિ સાથે અરજદારનો આધાર નંબર દાખલ કરો. આ પ્રક્રિયા સુરક્ષા માટે ચોક્કસ ક્રોસ-ચેકિંગની ખાતરી આપે છે. તમારા સહકાર માટે તમારો આભાર.",
    "For any queries or assistance, feel free to call us at +91-9699900111.": "કોઈપણ પ્રશ્નો અથવા સહાયતા માટે, અમને +91-9699900111 પર કૉલ કરો.",
    "Thank you for entrusting PrivateCourt. Your security is our priority.": "પ્રાઇવેટકોર્ટને સોંપવા બદલ આભાર. તમારી સુરક્ષા અમારી પ્રાથમિકતા છે.",
    "Enter the Aadhaar OTP Here": "અહીં આધાર OTP દાખલ કરો",
    "Verify OTP": "ઓટીપી ચકાસો",
    "Thank You Aadhaar Verification Successfully": "આધાર વેરિફિકેશન સફળતાપૂર્વક પૂર્ણ થયું",
    "Thank you for providing the OTP for Aadhaar verification. With this step completed, we are now ready to proceed with your case.":"આધાર વેરિફિકેશન માટે OTP આપવા બદલ આભાર. આ પગલું પૂર્ણ થતાં, અમે હવે તમારા કેસ સાથે આગળ વધવા માટે તૈયાર છીએ.",
    "Our team will continue to work diligently to bring about a resolution that meets your needs and expectations. Should you have any questions or concerns along the way, please don't hesitate to reach out to us.":"અમારી ટીમ તમારી જરૂરિયાતો અને અપેક્ષાઓને પૂર્ણ કરે તેવા ઠરાવ લાવવા માટે ખંતપૂર્વક કામ કરવાનું ચાલુ રાખશે. જો તમને રસ્તામાં કોઈ પ્રશ્નો અથવા ચિંતાઓ હોય, તો કૃપા કરીને અમારો સંપર્ક કરવામાં અચકાશો નહીં.",
    "Once again, thank you for entrusting PrivateCourt with your dispute resolution needs. We look forward to serving you.":"ફરી એકવાર, તમારી વિવાદ નિરાકરણની જરૂરિયાતો સાથે પ્રાઇવેટકોર્ટને સોંપવા બદલ આભાર. અમે તમને સેવા આપવા માટે આતુર છીએ.",
    "Warm regards,": "હાર્દિક સાદર,",
    "PrivateCourt Team": "ખાનગી કોર્ટ ટીમ",
    "CONCILIATION NOTICE": "સુલહ નોટિસ",
    "Warm Greetings from PRIVATECOURT. We are writing to formally inform you that PRIVATECOURT will oversee and resolve the dispute(s) between you and company .": "PRIVATECOURT તરફથી હાર્દિક અભિવાદન. PRIVATECOURT તમારા અને કંપની વચ્ચેના વિવાદોને દેખરેખમાં રાખશે અને ઉકેલશે તે આપને અધિકારિક રીતે જાણાવવા માટે અમે લખી રહ્યાં છીએ.",
    "Please find attached the confirmation document with hearing details that outline our acceptance of our commitment to handling your disputes through Alternative Dispute Resolution (ADR).": "વિવાદોને વૈકલ્પિક વિવાદ ઉકેલ (ADR) દ્વારા હેન્ડલ કરવા માટે અમારી પ્રતિબદ્ધતાને અમારી સ્વીકૃતિને ઓળખાવતી સુનાવણી વિગતો સાથેની પુષ્ટિ ડોક્યુમેન્ટ જોડેલી છે.",
    "To explore resolution options and engage in the process effectively, we provide the following avenues, from which you can select any one way:": "વિરોધોને ઉકેલવા માટે અને પ્રક્રિયામાં અસરકારક રીતે જોડાવા માટે, અમે નીચેની માર્ગો પૂરી પાડતા છીએ, જેમાંથી તમે કોઈ એક પસંદ કરી શકો છો:",
    "Access Your Personalized Dispute Resolution Page: Click {{ params.disputelink }} to access your dedicated dispute resolution page. Here, you can provide detailed information about your issue. Our support team will review your submission promptly and guide you through the next steps of the resolution process.": "તમારી વ્યક્તિગત વિવાદ ઉકેલ પેજને ઍક્સેસ કરો: તમારા સમર્પિત વિવાદ ઉકેલ પેજને ઍક્સેસ કરવા માટે {{ params.disputelink }} ક્લિક કરો. અહીં, તમે તમારી સમસ્યા વિશે વિગતવાર માહિતી આપી શકો છો. અમારી સપોર્ટ ટીમ તમારી સબમિશનનો તુરંત સમીક્ષા કરશે અને ઉકેલ પ્રક્રિયાના આવતા પગલાંની માર્ગદર્શન આપશે.",
    "OR": "કિંવા",
    "You are hereby directed to attend the {{params.meetingtype}} hearing scheduled on {{ params.conciiationdate }}, at {{ params.conciliationaddress }}, between {{params.timing}}.": "તમે {{ params.conciiationdate }} ના રોજ {{ params.conciliationaddress }} પર, {{params.timing}} વચ્ચે નિર્ધારિત {{params.meetingtype}} સુનાવણીમાં હાજર રહેવા માટે આ મારફતે સૂચિત કરવામાં આવ્યા છો.",
    "This platform enables you to directly engage with our team, comprehensively discussing and sharing your dispute details.": "આ પ્લેટફોર્મ તમને અમારા ટીમ સાથે સીધા જોડાવા, તમારી વિવાદ વિગતોને વ્યાપક રીતે ચર્ચા કરવા અને શેર કરવામાં મદદ કરે છે.",
    "If you have any additional information or documentation related to this matter, please feel free to reach out to our dedicated customer support team at +918035731376 or Email: legal@privatecourt.in": "આ મુદ્દા સાથે સંકળાયેલા કોઇ વધારાની માહિતી અથવા દસ્તાવેજો હોય તો કૃપા કરીને +918035731376 પર અમારી સમર્પિત ગ્રાહક સપોર્ટ ટીમ સાથે સંપર્ક કરવા અથવા legal@privatecourt.in પર ઇમેઇલ કરવા માટે મુક્ત થાઓ.",
    "We appreciate your trust in PRIVATECOURT for dispute resolution and are committed to delivering a fair and impartial resolution to the matter at hand.": "વિરોધ ઉકેલ માટે PRIVATECOURT પર તમારા વિશ્વાસની અમે પ્રશંસા કરીએ છીએ અને આ મુદ્દાની તાત્કાલિક અને નિષ્ણાત ઉકેલ આપવા માટે પ્રતિબદ્ધ છીએ.",
    "Thank You,": "આભાર,",
    "Team PrivateCourt": "ટીમ પ્રાઇવેટકોર્ટ",
    "Today": "આજ",
    "After 3 days": "3 દિવસ પછી",
    "After 7 days": "7 દિવસ પછી",
    "After 10 days": "10 દિવસ પછી",
    "After 15 days": "15 દિવસ પછી",
    "After 20 days": "20 દિવસ પછી"
}
export default GujaratiTranslation;