const TamilTranslation={

  //Dispute webPage
  "Verify Aadhaar":"ஆதாரை சரிபார்க்கவும்",
  "Payment Date":"கொடுப்பனவுத் திகதி",
   "Aadhaar Verification":"ஆதார் சரிபார்ப்பு",
    "To proceed further, we have initiated the Aadhaar verification process. Please provide the OTP (One-Time Password) that you have received to complete this step. Ensuring the security and authenticity of our users is paramount to us, and this verification step helps us maintain the integrity of our services.":"மேலும் தொடர, ஆதார் சரிபார்ப்பு செயல்முறையைத் தொடங்கியுள்ளோம். இந்த படிநிலையை முடிக்க நீங்கள் பெற்ற OTP (ஒரு முறை கடவுச்சொல்) ஐ வழங்கவும். எங்கள் பயனர்களின் பாதுகாப்பு மற்றும் நம்பகத்தன்மையை உறுதி செய்வது எங்களுக்கு முதன்மையானதாகும், மற்றும் இந்த சரிபார்ப்பு படி எங்கள் சேவைகளின் துல்லியத்தைப் பராமரிக்க உதவுகிறது.",
    "Thank You Aadhaar Verification Successfully":"நன்றி ஆதார் சரிபார்ப்பு வெற்றிகரமாக",
    "Thank you for providing the OTP for Aadhaar verification. With this step completed, we are now ready to proceed with your case.":"ஆதார் சரிபார்ப்புக்கான OTP ஐ வழங்கியதற்கு நன்றி. இந்த படி முடிந்ததும், இப்போது உங்கள் வழக்கைத் தொடர நாங்கள் தயாராக உள்ளோம்.",
    "Our team will continue to work diligently to bring about a resolution that meets your needs and expectations. Should you have any questions or concerns along the way, please don't hesitate to reach out to us.":"உங்கள் தேவைகள் மற்றும் எதிர்பார்ப்புகளை பூர்த்தி செய்யும் ஒரு தீர்மானத்தைக் கொண்டுவர எங்கள் குழு தொடர்ந்து விடாமுயற்சியுடன் பணியாற்றும். வழியில் உங்களுக்கு ஏதேனும் கேள்விகள் அல்லது கவலைகள் இருந்தால், தயவுசெய்து எங்களை அணுக தயங்க வேண்டாம்.",
    "Once again, thank you for entrusting PrivateCourt with your dispute resolution needs. We look forward to serving you.":"மீண்டும், உங்கள் சர்ச்சை தீர்வு தேவைகளை PrivateCourt ஐ ஒப்படைத்ததற்கு நன்றி. உங்களுக்கு சேவை செய்ய நாங்கள் எதிர்நோக்குகிறோம்.",
    // "Warm regards,":"",
    // "PrivateCourt Team":"",
  'Oops!':"அச்சச்சோ!",
  "The page you are searching, can't be found.":"நீங்கள் தேடும் பக்கத்தைக் கண்டுபிடிக்க முடியவில்லை.",
  "Language Selection:":"மொழி தேர்வு:",
  "Empowering Effective Communication":"பயனுள்ள தகவல்தொடர்புகளை மேம்படுத்துதல்",
  "Good day! I'm Murthy, your dedicated assistant for today's proceedings. Lets start by selecting the language you're most comfortable with.":"நல்ல நாள்! நான் மூர்த்தி, இன்றைய நடவடிக்கைகளுக்கு உங்களின் அர்ப்பணிப்பு உதவியாளர். உங்களுக்கு மிகவும் வசதியான மொழியைத் தேர்ந்தெடுப்பதன் மூலம் தொடங்குவோம்.",
  "Select Your Preferred Language":"உங்களுக்கு விருப்பமான மொழியைத் தேர்ந்தெடுக்கவும்",
  "Opting for your preferred language ensures clear and effective communication. Whether it's your native tongue or another language of your choice, selecting it will streamline our interaction and enhance your experience.":"உங்களுக்கு விருப்பமான மொழியைத் தேர்ந்தெடுப்பது தெளிவான மற்றும் பயனுள்ள தகவல்தொடர்புக்கு உறுதியளிக்கிறது. அது உங்கள் தாய்மொழியாக இருந்தாலும் சரி அல்லது நீங்கள் விரும்பும் வேறு மொழியாக இருந்தாலும் சரி, அதைத் தேர்ந்தெடுப்பது எங்களின் தொடர்புகளை மேம்படுத்தி உங்கள் அனுபவத்தை மேம்படுத்தும்.",
  "Next": "அடுத்தது",
  "Welcome to PrivateCourt, where Helping you is our priority.":"பிரைவேட் கோர்ட்டுக்கு வரவேற்கிறோம், உங்களுக்கு உதவுவதே எங்கள் முன்னுரிமை.",
  "Now, let me introduce you to PrivateCourt – your reliable partner in resolving lending disputes effectively and efficiently.":"இப்போது, ​​பிரைவேட் கோர்ட்டுக்கு உங்களை அறிமுகப்படுத்துகிறேன் - கடன் தகராறுகளை திறம்பட மற்றும் திறமையாக தீர்ப்பதில் உங்கள் நம்பகமான பங்குதாரர்.",
  "PrivateCourt":"பிரைவேட்கோர்ட்",
  "Your trusted partner in resolving lending disputes with care and expertise.":"கடன் வழங்குவதில் ஏற்படும் சிக்கல்களை கவனிப்பு மற்றும் நிபுணத்துவத்துடன் தீர்ப்பதில் உங்கள் நம்பகமான பங்குதாரர்.",
  "Welcome to PrivateCourt, where your case details are in good hands. With the lending company initiating arbitration or conciliation, it's essential for us to understand your situation fully. Please share all the details you can.":"பிரைவேட் கோர்ட்டுக்கு வரவேற்கிறோம், உங்கள் வழக்கு விவரங்கள் நல்ல கைகளில் உள்ளன. கடன் வழங்கும் நிறுவனம் மத்தியஸ்தம் அல்லது சமரசத்தைத் தொடங்குவதால், உங்கள் நிலைமையை நாங்கள் முழுமையாகப் புரிந்துகொள்வது அவசியம். உங்களால் முடிந்த அனைத்து விவரங்களையும் பகிர்ந்து கொள்ளவும்.",
  "The more information we have, the better we can help. Your input is crucial in finding the right solution for you.":"எங்களிடம் அதிக தகவல்கள் இருந்தால், நாங்கள் சிறப்பாக உதவ முடியும். உங்களுக்கான சரியான தீர்வைக் கண்டுபிடிப்பதில் உங்கள் உள்ளீடு முக்கியமானது.",
  "Trust us to carefully examine your case and work together to find the best way forward.":"உங்கள் வழக்கை கவனமாக ஆராய்ந்து, சிறந்த வழியைக் கண்டறிய ஒன்றாகச் செயல்பட எங்களை நம்புங்கள்.",
  "Reviewing Your Dispute Information":"உங்கள் சர்ச்சை தகவலை மதிப்பாய்வு செய்கிறது",
  "Let's take a closer look at the details surrounding your dispute. The information we're discussing today has been shared with us by Company, regarding a personal loan.":"உங்கள் தகராறு தொடர்பான விவரங்களைக் கூர்ந்து கவனிப்போம். இன்று நாம் விவாதிக்கும் தகவல்கள், தனிநபர் கடன் தொடர்பாக Company மூலம் எங்களுடன் பகிர்ந்து கொள்ளப்பட்டது.",
  "By understanding these specifics, we can better assess the situation and formulate a resolution plan that aligns with your circumstances and goals.":"இந்த பிரத்தியேகங்களைப் புரிந்துகொள்வதன் மூலம், நாங்கள் நிலைமையை நன்றாக மதிப்பிடலாம் மற்றும் உங்கள் சூழ்நிலைகள் மற்றும் இலக்குகளுடன் ஒத்துப்போகும் ஒரு தீர்மானத் திட்டத்தை உருவாக்கலாம்.",
  "Dispute Amount Information": "தகராறு தொகை தகவல்",
  "According to their records, you initially borrowed an amount of":"அவர்களின் பதிவுகளின்படி, நீங்கள் ஆரம்பத்தில் ஒரு தொகையை கடன் வாங்கியுள்ளீர்கள்",
  "Presently, your outstanding due stands at Rs.":"தற்போது, ​​உங்கள் நிலுவைத் தொகை ரூ.",
  "These details provide us with valuable insight into the history and current status of your loan.":"இந்த விவரங்கள் உங்கள் கடனின் வரலாறு மற்றும் தற்போதைய நிலை பற்றிய மதிப்புமிக்க நுண்ணறிவை எங்களுக்கு வழங்குகிறது.",
  "Spotting Financial Issues: Understanding Unpaid Obligations": "நிதிச் சிக்கல்களைக் கண்டறிதல்: செலுத்தப்படாத கடமைகளைப் புரிந்துகொள்வது",
  "Now, let's gently explore the challenges you've faced due to financial constraints. Discussing this may be sensitive, but your openness will greatly assist us in finding the best path forward. Please take a moment to select any of the following options that resonate with your experience:":"இப்போது, ​​நிதிக் கட்டுப்பாடுகள் காரணமாக நீங்கள் எதிர்கொண்ட சவால்களை மெதுவாக ஆராய்வோம். இதைப் பற்றி விவாதிப்பது உணர்திறன் வாய்ந்ததாக இருக்கலாம், ஆனால் உங்கள் வெளிப்படைத்தன்மை சிறந்த பாதையை கண்டுபிடிப்பதில் எங்களுக்கு பெரிதும் உதவும். உங்கள் அனுபவத்துடன் ஒத்திருக்கும் பின்வரும் விருப்பங்களில் ஏதேனும் ஒன்றைத் தேர்ந்தெடுக்க சிறிது நேரம் ஒதுக்குங்கள்:",
  "Financial Loss": "நிதி இழப்பு",
  "Business Loss": "தொழில் நஷ்டம்",
  "Job Loss": "வேலை இழப்பு",
  "Health Issue": "உடல்நலப் பிரச்சினை",
  "Death of the earning member": "சம்பாதிக்கும் உறுப்பினரின் மரணம்",
  "Missing payment details": "கட்டண விவரங்கள் இல்லை",
  "Dispute due to bad behaviour": "மோசமான நடத்தை காரணமாக தகராறு",
  "No clear loan information": "தெளிவான கடன் தகவல் இல்லை",
  "Death Case": "மரண வழக்கு",
  "We kindly request you to upload the death certificate at":"Legal@privatecourt.in இல் இறப்புச் சான்றிதழை பதிவேற்றம் செய்யுமாறு அன்புடன் கேட்டுக்கொள்கிறோம்",
  "Other reason/s": "மற்ற காரணங்கள்",
  "Your prompt response will greatly assist us in assessing the situation and finding the most suitable resolution. Rest assured, we are committed to working with you to resolve any outstanding issues and ensure a fair and satisfactory outcome.":"உங்கள் உடனடி பதில் நிலைமையை மதிப்பிடுவதற்கும் மிகவும் பொருத்தமான தீர்வைக் கண்டுபிடிப்பதற்கும் பெரிதும் உதவும். உறுதியாக இருங்கள், ஏதேனும் நிலுவையில் உள்ள சிக்கல்களைத் தீர்க்கவும், நியாயமான மற்றும் திருப்திகரமான முடிவை உறுதி செய்யவும் உங்களுடன் இணைந்து பணியாற்ற நாங்கள் கடமைப்பட்டுள்ளோம்.",
  "Partner Dispute": "பங்குதாரர் தகராறு",
  "Funds held with other vendors or clients":"பிற விற்பனையாளர்கள் அல்லது வாடிக்கையாளர்களிடம் வைத்திருக்கும் நிதி",
  "Monetary loss through other means": "பிற வழிகளில் பண இழப்பு",
  "Less than 2 months": "இரண்டு மாதங்களுக்கும் குறைவானது",
  "Less than 6 months": "ஆறு மாதங்களுக்கும் குறைவாக",
  "Less than 1 year": "ஒரு வருடத்திற்கும் குறைவாக",
  "More than 1 year": "ஒரு வருடத்திற்கும் மேலாக",
  "Exploring Settlement Possibilities": "தீர்வு சாத்தியங்களை ஆராய்தல்",
  "As our discussion progresses, I appreciate your openness in explaining the reason for the default. It's crucial to address your outstanding dues promptly to prevent any legal complexities. We recommend exploring different avenues, such as seeking assistance from family or investigating alternative financing options, to fulfill your obligations without delay.":"எங்கள் விவாதம் முன்னேறும்போது, ​​இயல்புநிலைக்கான காரணத்தை விளக்குவதில் உங்கள் திறந்த மனப்பான்மையை நான் பாராட்டுகிறேன். எந்தவொரு சட்ட சிக்கல்களையும் தடுக்க, உங்கள் நிலுவைத் தொகைகளை உடனடியாக நிவர்த்தி செய்வது முக்கியம். உங்கள் கடமைகளை தாமதமின்றி நிறைவேற்ற, குடும்பத்தின் உதவியை நாடுவது அல்லது மாற்று நிதியளிப்பு விருப்பங்களை ஆராய்வது போன்ற பல்வேறு வழிகளை ஆராய பரிந்துரைக்கிறோம்.",
  "Timely settlement not only mitigates legal risks but also strengthens trust and credibility in your financial interactions. Your cooperation in this matter is truly valued.":"சரியான நேரத்தில் தீர்வு என்பது சட்ட அபாயங்களைக் குறைப்பது மட்டுமல்லாமல், உங்கள் நிதி தொடர்புகளில் நம்பிக்கையையும் நம்பகத்தன்மையையும் பலப்படுத்துகிறது. இந்த விஷயத்தில் உங்கள் ஒத்துழைப்பு உண்மையிலேயே மதிக்கப்படுகிறது.",
  "Settlement Options": "தீர்வு விருப்பங்கள்",
  "In our efforts to facilitate a swift resolution, we seek your cooperation.":"விரைவான தீர்வை எளிதாக்குவதற்கான எங்கள் முயற்சிகளில், உங்கள் ஒத்துழைப்பை நாங்கள் கோருகிறோம்.",
  "Are you open to discussing the settlement of your outstanding dues?":"உங்கள் நிலுவையில் உள்ள நிலுவைத் தொகையை தீர்ப்பது பற்றி விவாதிக்க நீங்கள் தயாராக இருக்கிறீர்களா?",
  "Please Select": "தயவு செய்து தேர்வு செய்யவும்",
  "Yes": "ஆம்",
  "No": "இல்லை",
  "We appreciate your openness to resolving your outstanding dues. To better understand your financial situation and tailor our approach accordingly,": "உங்கள் சொந்த தொகைகளை தீர்மானிக்க நீங்கள் திறந்ததைச் சந்தேகப்படுத்தலாம். உங்கள் நிதி நிலையை நன்கு புரிந்து வைத்து எங்கள் வழிமுறையை சரியாக அமைக்கலாம்.",
  "could you kindly provide details on your current family income?": "உங்கள் தற்போதைய குடும்ப வருமானத்தை தயவுசெய்து அளிக்க முடியுமா?",
  "In our efforts to address your outstanding dues, we'd like to know if a": "உங்கள் கடன்களை தீர்வு செய்ய எங்கள் முயற்சிகளில், ஒரு ",
  "one-time payment settlement is an option for you.": "ஒரு முதலாவது செலுத்து தள்ளுபடி உங்களுக்கு விரும்பப்படுகிறது.",
  "I am unable to pay by any means":"என்னால் எந்த வகையிலும் பணம் செலுத்த முடியவில்லை",
  "We Appreciate Your Honesty.": "உங்கள் நேர்மையை நாங்கள் பாராட்டுகிறோம்.",
  "less than Rs. 10000/- per month": "ஒரு மாதம் முதல் Rs. 10000/- க்குள் குறைவாக",
    "less than Rs. 20,000/- per month": "ஒரு மாதம் முதல் Rs. 20,000/- க்குள் குறைவாக",
    "less than Rs. 40,000/- per month": "ஒரு மாதம் முதல் Rs. 40,000/- க்குள் குறைவாக",
    "less than Rs. 60,000/- per month": "ஒரு மாதம் முதல் Rs. 60,000/- க்குள் குறைவாக",
    "less than Rs. 1,00,000/- per month": "ஒரு மாதம் முதல் Rs. 1,00,000/- க்குள் குறைவாக",
    "more than Rs. 1,00,000/- per month": "ஒரு மாதம் முதல் Rs. 1,00,000/- க்குள் அதிகமாக",
  "Aadhaar Verify": "ஆதார் சரிபார்ப்பு",
  "We appreciate your openness to resolving your outstanding dues. To better understand your financial situation and tailor our approach accordingly, could you kindly provide details on your current family income?":"உங்கள் நிலுவைத் தொகையைத் தீர்ப்பதில் உங்கள் திறந்த மனப்பான்மையை நாங்கள் பாராட்டுகிறோம். உங்கள் நிதி நிலைமையை நன்கு புரிந்து கொள்ளவும், அதற்கேற்ப எங்களின் அணுகுமுறையை வடிவமைக்கவும், உங்கள் தற்போதைய குடும்ப வருமானம் குறித்த விவரங்களை தயவுசெய்து வழங்க முடியுமா?",
  "No Income": "வருமானம் இல்லை",
  "less than Rs. 10000/- per month": "குறைவாக ரூ. மாதம் 10000/-",
  "less than Rs. 20000/- per month": "குறைவாக ரூ. மாதம் 20000/-",
  "less than Rs. 40000/- per month": "குறைவாக ரூ. மாதம் 40000/-",
  "more than Rs. 100000/- per month": "மேலும் ரூ. மாதம் 100000/-",
  "In our efforts to address your outstanding dues, we'd like to know if a one-time payment settlement is an option for you.":"உங்களின் நிலுவைத் தொகையை நிவர்த்தி செய்வதற்கான எங்கள் முயற்சிகளில், ஒரு முறை பணம் செலுத்துவது உங்களுக்கான விருப்பமா என்பதை அறிய விரும்புகிறோம்.",
  "We appreciate your feedback. If a one-time payment is not viable, could you consider arranging payment in three equal instalments?":"உங்கள் கருத்தை நாங்கள் பாராட்டுகிறோம். ஒரு முறை பணம் செலுத்துவது சாத்தியமில்லை என்றால், மூன்று சம தவணைகளில் பணம் செலுத்த ஏற்பாடு செய்யலாமா?",
  "This approach could help ease any immediate financial strain while still addressing the outstanding dues.":"இந்த அணுகுமுறை நிலுவையில் உள்ள நிலுவைத் தொகைகளை நிவர்த்தி செய்யும் போது உடனடி நிதி நெருக்கடியை எளிதாக்க உதவும்.",
  "Aadhaar Verification": "ஆதார் சரிபார்ப்பு",
  "To proceed further, we have initiated the Aadhaar verification process. Please provide the OTP (One-Time Password) that you have received to complete this step. Ensuring the security and authenticity of our users is paramount to us, and this verification step helps us maintain the integrity of our services.":"மேலும் தொடர, ஆதார் சரிபார்ப்பு செயல்முறையைத் தொடங்கியுள்ளோம். இந்த படிநிலையை முடிக்க நீங்கள் பெற்ற ஓடிப (ஒரு முறை கடவுச்சொல்) OTP ஐ வழங்கவும். எங்கள் பயனர்களின் பாதுகாப்பு மற்றும் நம்பகத்தன்மையை உறுதிப்படுத்துவது எங்களுக்கு மிக முக்கியமானது, மேலும் இந்த சரிபார்ப்புப் படி எங்கள் சேவைகளின் ஒருமைப்பாட்டைப் பராமரிக்க உதவுகிறது.",
  "Enter the Applicant’s Aadhaar Number":"விண்ணப்பதாரரின் ஆதார் எண்ணை உள்ளிடவும்",
  "Please input the Applicant’s Aadhaar number along with essential demographic details—name, date of birth, and gender. This process ensures accurate cross-checking for security. Thank you for your cooperation.":"விண்ணப்பதாரரின் ஆதார் எண்ணை அத்தியாவசிய மக்கள்தொகை விவரங்களுடன்-பெயர், பிறந்த தேதி மற்றும் பாலினத்துடன் உள்ளிடவும். இந்த செயல்முறை பாதுகாப்பிற்கான துல்லியமான குறுக்கு சோதனையை உறுதி செய்கிறது. உங்கள் ஒத்துழைப்பிற்கு நன்றி.",
  "For any queries or assistance, feel free to call us at +91-9699900111.":"ஏதேனும் கேள்விகள் அல்லது உதவிகளுக்கு, எங்களை +91-9699900111 என்ற எண்ணில் அழைக்கவும்.",
  "Thank you for entrusting PrivateCourt. Your security is our priority.":"பிரைவேட் கோர்ட் நம்பி ஒப்படைத்ததற்கு நன்றி. உங்கள் பாதுகாப்பு எங்கள் முன்னுரிமை.",
  "Enter the Aadhaar OTP Here": "ஆதார் OTP ஐ இங்கே உள்ளிடவும்",
  "Verify OTP": "OTP ஐ சரிபார்க்கவும்",
  "Thank You Aadhaar Verification Successfully": "ஆதார் சரிபார்ப்புக்கு நன்றி",
  "Thank you for providing the OTP for Aadhaar verification. With this step completed, we are now ready to proceed with your case.":"ஆதார் சரிபார்ப்புக்கு OTP வழங்கியதற்கு நன்றி. இந்த படி முடிந்ததும், உங்கள் வழக்கைத் தொடர நாங்கள் தயாராக உள்ளோம்.",
  "Our team will continue to work diligently to bring about a resolution that meets your needs and expectations. Should you have any questions or concerns along the way, please don't hesitate to reach out to us.":"உங்கள் தேவைகள் மற்றும் எதிர்பார்ப்புகளை பூர்த்தி செய்யும் தீர்மானத்தை கொண்டு வர எங்கள் குழு தொடர்ந்து விடாமுயற்சியுடன் செயல்படும். வழியில் உங்களுக்கு ஏதேனும் கேள்விகள் அல்லது கவலைகள் இருந்தால், தயவுசெய்து எங்களைத் தொடர்புகொள்ள தயங்க வேண்டாம்.",
  "Once again, thank you for entrusting PrivateCourt with your dispute resolution needs. We look forward to serving you.":"மீண்டும் ஒருமுறை, உங்கள் சர்ச்சைத் தீர்வுத் தேவைகளை தனியார் நீதிமன்றத்தை நம்பி ஒப்படைத்ததற்கு நன்றி. உங்களுக்கு சேவை செய்ய நாங்கள் காத்திருக்கிறோம்.",
  "Warm regards,": "அன்பான வாழ்த்துக்கள்,",
  "PrivateCourt Team": "பிரைவேட் கோர்ட் குழு",
    'Email Security Check':'மின்னஞ்சல் பாதுகாப்பு சோதனை',
    'Verify Your Email for Account Access': 'கணக்கு அணுகலுக்கான உங்கள் மின்னஞ்சலைச் சரிபார்க்கவும்',
    "To ensure the security of your account, please enter your valid Email ID in the specified format- username@domain.com. If your email address doesn't exist in our system": "உங்கள் கணக்கின் பாதுகாப்பை உறுதிசெய்ய, உங்கள் சரியான மின்னஞ்சல் ஐடியை குறிப்பிட்ட வடிவத்தில் உள்ளிடவும்- username@domain.com. உங்கள் மின்னஞ்சல் முகவரி எங்கள் அமைப்பில் இல்லை என்றால், ஏ",
    'NEW USER': 'புதிய பயனர்',
    'account will be created for you': 'உங்களுக்காக கணக்கு உருவாக்கப்படும்.',
    'Once submitted, you will receive a Verification OTP (One-Time Password) sent to your provided email address.': 'சமர்ப்பிக்கப்பட்டதும், நீங்கள் வழங்கிய மின்னஞ்சல் முகவரிக்கு அனுப்பப்பட்ட சரிபார்ப்பு OTP (ஒரு முறை கடவுச்சொல்) பெறுவீர்கள்.',
    'For any queries or assistance, feel free to call us at +91-9699900111.': 'ஏதேனும் கேள்விகள் அல்லது உதவிகளுக்கு, எங்களை +91-9699900111 என்ற எண்ணில் அழைக்கவும்.',
    'Thank you for entrusting PrivateCourt. Your security is our priority.': 'தனியார் நீதிமன்றத்தை ஒப்படைத்ததற்கு நன்றி. உங்கள் பாதுகாப்பு எங்கள் முன்னுரிமை.',
    'Verify': 'சரிபார்க்கவும்',
    'Invalid Email Address': 'தவறான மின்னஞ்சல் முகவரி',
    'We regret to inform you that the email address entered is invalid. Please ensure that you have entered a correct and properly formatted email address.': 'உள்ளிடப்பட்ட மின்னஞ்சல் முகவரி தவறானது என்பதை உங்களுக்குத் தெரிவிக்க வருந்துகிறோம். நீங்கள் சரியான மற்றும் சரியாக வடிவமைக்கப்பட்ட மின்னஞ்சல் முகவரியை உள்ளிட்டுள்ளீர்கள் என்பதை உறுதிப்படுத்தவும்.',
    'If the issue persists, double-check for any typos or errors in the email format (e.g., username@example.com).': 'சிக்கல் தொடர்ந்தால், மின்னஞ்சல் வடிவத்தில் ஏதேனும் எழுத்துப்பிழைகள் அல்லது பிழைகள் உள்ளதா என இருமுறை சரிபார்க்கவும் (எ.கா., username@example.com).',
    'For further queries or assistance, feel free to call us at +91-9699900111.': 'மேலும் கேள்விகள் அல்லது உதவிகளுக்கு, எங்களை +91-9699900111 என்ற எண்ணில் அழைக்கவும்.',
    'Thank you for entrusting PrivateCourt. Your security is our priority.': 'தனியார் நீதிமன்றத்தை ஒப்படைத்ததற்கு நன்றி. உங்கள் பாதுகாப்பு எங்கள் முன்னுரிமை.',
    'Secure UDYAM Number Validation':'பாதுகாப்பான உத்யம் எண் சரிபார்ப்பு',
    'Enter Your Udyam Number':'உங்கள் Udyam எண்ணை உள்ளிடவும்',
    'Please enter your correct UDYAM Number.':'உங்களின் சரியான UDYAM எண்ணை உள்ளிடவும்.',
    'Your UDYAM Number allows us to collect crucial details associated with your business. This includes your business address, current status, service details, and organization type.':'உங்கள் UDYAM எண் உங்கள் வணிகத்துடன் தொடர்புடைய முக்கியமான விவரங்களைச் சேகரிக்க அனுமதிக்கிறது. இதில் உங்கள் வணிக முகவரி, தற்போதைய நிலை, சேவை விவரங்கள் மற்றும் நிறுவன வகை ஆகியவை அடங்கும்.',
    'Ensuring the accuracy of your UDYAM Number is vital for a seamless verification process.':'உங்கள் UDYAM எண்ணின் துல்லியத்தை உறுதி செய்வது தடையற்ற சரிபார்ப்பு செயல்முறைக்கு இன்றியமையாதது.',
    'UDYAM':'உத்யம்',
    'Error: Invalid UDYAM Number':'பிழை: உத்யம் எண் தவறானது',
    'We regret to inform you that the UDYAM number entered is invalid or incorrect. Please ensure you have entered the correct UDYAM details and double-check for any typos.':'உள்ளிட்ட UDYAM எண் தவறானது அல்லது தவறானது என்பதை உங்களுக்குத் தெரிவிக்க வருந்துகிறோம். சரியான UDYAM விவரங்களை உள்ளிட்டுள்ளதை உறுதிசெய்து, ஏதேனும் எழுத்துப் பிழைகள் உள்ளதா என இருமுறை சரிபார்க்கவும்.',
    'If you continue to face issues, kindly verify your UDYAM certificate and re-enter the details.':'நீங்கள் தொடர்ந்து சிக்கல்களைச் சந்தித்தால், தயவுசெய்து உங்களின் UDYAM சான்றிதழைச் சரிபார்த்து, விவரங்களை மீண்டும் உள்ளிடவும்.',
    'Secure PAN Validation Process':'பாதுகாப்பான PAN சரிபார்ப்பு செயல்முறை',
    'This verification process is designed to uphold the integrity of our services, ensuring a reliable and secure experience.':'இந்தச் சரிபார்ப்பு செயல்முறையானது, எங்கள் சேவைகளின் ஒருமைப்பாட்டை நிலைநிறுத்தவும், நம்பகமான மற்றும் பாதுகாப்பான அனுபவத்தை உறுதி செய்யவும் வடிவமைக்கப்பட்டுள்ளது.',
    'Enter Your PAN Number':'உங்கள் PAN எண்ணை உள்ளிடவும்',
    'Please enter your correct PAN number in the format XXXXX-0000-X.':'உங்கள் சரியான PAN எண்ணை XXXXX-0000-X வடிவத்தில் உள்ளிடவும்.',
    'PrivateCourt will meticulously collect essential information associated with your PAN such as your full name, date of birth, residential address, and contact details. For Business Owners, we extend our data collection to encompass all pertinent business details tied to your PAN such as GST details, MCA specifics, and records of GST return filings.':'உங்களின் முழுப்பெயர், பிறந்த தேதி, வசிக்கும் முகவரி மற்றும் தொடர்பு விவரங்கள் போன்ற உங்களின் பான் எண்ணுடன் தொடர்புடைய அத்தியாவசியத் தகவல்களை PrivateCourt உன்னிப்பாகச் சேகரிக்கும். வணிக உரிமையாளர்களுக்காக, உங்கள் PAN உடன் இணைக்கப்பட்டுள்ள GST விவரங்கள், MCA விவரக்குறிப்புகள் மற்றும் GST ரிட்டர்ன் தாக்கல்களின் பதிவுகள் போன்ற அனைத்து தொடர்புடைய வணிக விவரங்களையும் உள்ளடக்கியதாக எங்கள் தரவு சேகரிப்பை விரிவுபடுத்துகிறோம்.',
    'Our system assesses your CIBIL score for financial insight and cross-verifies for any ongoing court cases associated with your PAN.':'எங்கள் அமைப்பு உங்கள் CIBIL ஸ்கோரை நிதி நுண்ணறிவுக்காக மதிப்பிடுகிறது மற்றும் உங்கள் PAN உடன் தொடர்புடைய நீதிமன்ற வழக்குகளுக்கு குறுக்கு-சரிபார்க்கிறது.',
    'Error: Invalid PAN Format':'பிழை: தவறான PAN வடிவமைப்பு',
    'We regret to inform you that the PAN number entered does not match the required format (XXXXX-0000-X). Please ensure you have entered the correct PAN details and follow the specified format for accurate verification.':'உள்ளிடப்பட்ட PAN எண் தேவையான வடிவத்துடன் (XXXXX-0000-X) பொருந்தவில்லை என்பதைத் தெரிவித்துக் கொள்கிறோம். நீங்கள் சரியான PAN விவரங்களை உள்ளிட்டுள்ளீர்கள் என்பதை உறுதிசெய்து, துல்லியமான சரிபார்ப்புக்கு குறிப்பிட்ட வடிவமைப்பைப் பின்பற்றவும்.',
    'If you continue to face issues, kindly double-check your PAN card and re-enter the details. For further queries or assistance, feel free to call us at +91-9699900111.':'நீங்கள் தொடர்ந்து சிக்கல்களை எதிர்கொண்டால், தயவுசெய்து உங்கள் பான் கார்டை இருமுறை சரிபார்த்து, விவரங்களை மீண்டும் உள்ளிடவும். மேலும் கேள்விகள் அல்லது உதவிகளுக்கு, எங்களை +91-9699900111 என்ற எண்ணில் அழைக்கவும்.',
    'Welcome XXXX':'வரவேற்பு XXXX',
    'Account security starts with verification':'கணக்கு பாதுகாப்பு சரிபார்ப்புடன் தொடங்குகிறது',
    'OTP Validation for Account Access':'கணக்கு அணுகலுக்கான OTP சரிபார்ப்பு',
    'Kindly check your inbox, and enter the OTP in the designated field to complete the verification process.':'உங்கள் இன்பாக்ஸைச் சரிபார்த்து, சரிபார்ப்பு செயல்முறையை முடிக்க நியமிக்கப்பட்ட புலத்தில் OTP ஐ உள்ளிடவும்.',
    "Didn't receive OTP?":'OTP பெறவில்லையா?',
    'Request a Resend':'மீண்டும் அனுப்பக் கோரவும்',
    'Verify OTP':'OTP ஐ சரிபார்க்கவும்',
    'Error: Incorrect OTP':'பிழை: தவறான OTP',
    'We apologize for the inconvenience. The One-Time Password (OTP) entered is incorrect. Please ensure that you have accurately entered the OTP received in your email.':'சிரமத்திற்கு வருந்துகிறோம். உள்ளிடப்பட்ட ஒரு முறை கடவுச்சொல் (OTP) தவறானது. உங்கள் மின்னஞ்சலில் பெறப்பட்ட OTPயை நீங்கள் துல்லியமாக உள்ளிட்டுள்ளீர்கள் என்பதை உறுதிப்படுத்தவும்.',
    'If the issue persists, you may request a new OTP by clicking on the resend link or contacting our support team at +91-9699900111.':'சிக்கல் தொடர்ந்தால், மீண்டும் அனுப்பும் இணைப்பைக் கிளிக் செய்வதன் மூலம் அல்லது எங்கள் ஆதரவுக் குழுவை +91-9699900111 என்ற எண்ணில் தொடர்புகொள்வதன் மூலம் நீங்கள் புதிய OTP-ஐக் கோரலாம்.',
    'Double-check the email and try entering the OTP again.':'மின்னஞ்சலை இருமுறை சரிபார்த்து, மீண்டும் OTP ஐ உள்ளிட முயற்சிக்கவும்.',
    'Choose Profile':'சுயவிவரத்தைத் தேர்ந்தெடுக்கவும்',
    'Setting Your Default Profile':'உங்கள் இயல்புநிலை சுயவிவரத்தை அமைத்தல்',
    'Please choose the default profile for a seamless experience.':'தடையற்ற அனுபவத்திற்கு இயல்புநிலை சுயவிவரத்தைத் தேர்வு செய்யவும்.',
    "If you manage multiple companies, select the profile you'd like to set as the default. This ensures that every time you log in, you'll have quick access to the information relevant to your preferred company.":'நீங்கள் பல நிறுவனங்களை நிர்வகித்தால், இயல்புநிலையாக அமைக்க விரும்பும் சுயவிவரத்தைத் தேர்ந்தெடுக்கவும். ஒவ்வொரு முறையும் நீங்கள் உள்நுழையும்போது, நீங்கள் விரும்பும் நிறுவனத்துடன் தொடர்புடைய தகவல்களை விரைவாக அணுக முடியும் என்பதை இது உறுதி செய்கிறது.',
    'Making the right selection now simplifies your future logins and provides a tailored experience based on your primary business entity.':'இப்போது சரியான தேர்வு செய்வது உங்கள் எதிர்கால உள்நுழைவுகளை எளிதாக்குகிறது மற்றும் உங்கள் முதன்மை வணிக நிறுவனத்தின் அடிப்படையில் வடிவமைக்கப்பட்ட அனுபவத்தை வழங்குகிறது.',
    'Go to Dashboard':'டாஷ்போர்டுக்குச் செல்லவும்',
    'Data Compilation in Progress':'தரவு தொகுத்தல் செயல்பாட்டில் உள்ளது:',
    'Please Allow Processing Time':'செயலாக்க நேரத்தை அனுமதிக்கவும்',
    "Thank you for providing your information. Our system is currently in the process of analyzing and compiling the data you've entered, including details such as PAN, Aadhaar, GST, Email, and more, to establish your comprehensive profile.":'உங்கள் தகவலை வழங்கியதற்கு நன்றி. உங்கள் விரிவான சுயவிவரத்தை உருவாக்க, PAN, ஆதார், GST, மின்னஞ்சல் மற்றும் பல போன்ற விவரங்கள் உட்பட, நீங்கள் உள்ளிட்ட தரவை பகுப்பாய்வு செய்து தொகுக்கும் பணியில் எங்கள் அமைப்பு தற்போது ஈடுபட்டுள்ளது.',
    "This operation may take a few moments. During this time, your screen may show minimal activity. Please be assured that this is a routine part of our data compilation process.":'இந்தச் செயல்பாடு சில நொடிகள் ஆகலாம். இந்த நேரத்தில், உங்கள் திரை குறைந்தபட்ச செயல்பாட்டைக் காட்டலாம். இது எங்களின் தரவுத் தொகுத்தல் செயல்முறையின் வழக்கமான பகுதியாகும் என்பதை உறுதிப்படுத்திக் கொள்ளவும்.',
    'Your profile is being meticulously crafted to uphold accuracy and security. We sincerely appreciate your patience and understanding as we work to enhance your experience.':'உங்கள் சுயவிவரம் துல்லியம் மற்றும் பாதுகாப்பை நிலைநிறுத்துவதற்கு உன்னிப்பாக வடிவமைக்கப்பட்டுள்ளது. உங்கள் அனுபவத்தை மேம்படுத்த நாங்கள் உழைக்கும்போது உங்கள் பொறுமை மற்றும் புரிதலை நாங்கள் மனதாரப் பாராட்டுகிறோம்.',
    'Start Your Bank Account Verification':'உங்கள் வங்கி கணக்கு சரிபார்ப்பை தொடங்கவும்',
    'Bank Verification':'வங்கி சரிபார்ப்பு',
    'Ensuring the accuracy of your bank account information is crucial for a seamless verification process. Kindly enter your valid Account Number, and input the correct IFSC code associated with your account.':'உங்கள் வங்கிக் கணக்குத் தகவலின் துல்லியத்தை உறுதி செய்வது தடையற்ற சரிபார்ப்புச் செயல்முறைக்கு முக்கியமானது. தயவுசெய்து உங்கள் கணக்கு எண்ணை உள்ளிட்டு, உங்கள் கணக்குடன் தொடர்புடைய சரியான IFSC குறியீட்டை உள்ளிடவும்.',
    'This verification step is designed to enhance the security of our services and provide you with a reliable experience.':'இந்த சரிபார்ப்பு படி எங்கள் சேவைகளின் பாதுகாப்பை மேம்படுத்தவும் நம்பகமான அனுபவத்தை உங்களுக்கு வழங்கவும் வடிவமைக்கப்பட்டுள்ளது.',
    'Account Number':'',
    'IFSC code':'',
    'Error: Invalid Bank Account or IFSC Code':'பிழை: தவறான வங்கிக் கணக்கு அல்லது IFSC குறியீடு',
    'We regret to inform you that the Bank Account or IFSC Code entered is invalid or incorrect. Please ensure you have accurately entered both details and double-check for any typos.':'உள்ளிடப்பட்ட வங்கிக் கணக்கு அல்லது IFSC குறியீடு தவறானது அல்லது தவறானது என்பதை உங்களுக்குத் தெரிவிக்க வருந்துகிறோம். இரண்டு விவரங்களையும் துல்லியமாக உள்ளிட்டுள்ளதை உறுதிசெய்து, ஏதேனும் எழுத்துப் பிழைகள் உள்ளதா என இருமுறை சரிபார்க்கவும்.',
    'If you continue to face issues, kindly verify your bank statement or contact your bank to confirm the accuracy of the provided information.':'நீங்கள் தொடர்ந்து சிக்கல்களைச் சந்தித்தால், உங்கள் வங்கி அறிக்கையைச் சரிபார்க்கவும் அல்லது வழங்கப்பட்ட தகவலின் துல்லியத்தை உறுதிப்படுத்த உங்கள் வங்கியைத் தொடர்பு கொள்ளவும்.',
    'Aadhaar Details Verification':'ஆதார் விவரங்கள் சரிபார்ப்பு',
    'Enter Your Aadhaar Number':'உங்கள் ஆதார் எண்ணை உள்ளிடவும்',
    'Please input your Aadhaar number. This process ensures accurate cross-checking for your security. Thank you for your cooperation.':'உங்கள் ஆதார் எண்ணை உள்ளிடவும். இந்த செயல்முறை உங்கள் பாதுகாப்பிற்கான துல்லியமான குறுக்கு சோதனையை உறுதி செய்கிறது. உங்கள் ஒத்துழைப்பிற்கு நன்றி.',
    'Enter Your Aadhaar OTP Here':'உங்கள் ஆதார் OTP ஐ இங்கே உள்ளிடவு',
    'Error: Incorrect Aadhaar OTP':'பிழை: தவறான ஆதார் OTP',
    'We regret to inform you that the One-Time Password (OTP) entered for Aadhaar verification is incorrect. Please ensure that you have accurately entered the OTP received on your registered mobile number.':'ஆதார் சரிபார்ப்புக்காக உள்ளிடப்பட்ட ஒரு முறை கடவுச்சொல் (OTP) தவறானது என்பதை தெரிவித்துக்கொள்கிறோம். உங்கள் பதிவு செய்யப்பட்ட மொபைல் எண்ணில் பெறப்பட்ட OTPயை நீங்கள் துல்லியமாக உள்ளிட்டுள்ளீர்கள் என்பதை உறுதிப்படுத்தவும்.',
    'If the issue persists, you may request a new OTP by clicking on the resend link or contacting our support team at +91-9699900111.':'சிக்கல் தொடர்ந்தால், மீண்டும் அனுப்பும் இணைப்பைக் கிளிக் செய்வதன் மூலம் அல்லது எங்கள் ஆதரவுக் குழுவை +91-9699900111 என்ற எண்ணில் தொடர்புகொள்வதன் மூலம் நீங்கள் புதிய OTP-ஐக் கோரலாம்.',
    'Double-check the email and try entering the OTP again.':'மின்னஞ்சலை இருமுறை சரிபார்த்து, மீண்டும் OTP ஐ உள்ளிட முயற்சிக்கவும்.',
    'Error: Invalid Aadhaar Number':'பிழை: தவறான ஆதார் எண்',
    'We apologize for the inconvenience. The Aadhaar number entered is invalid. Please double-check and ensure that you have entered the correct 12-digit Aadhaar number.':'சிரமத்திற்கு வருந்துகிறோம். உள்ளிட்ட ஆதார் எண் தவறானது. சரியான 12 இலக்க ஆதார் எண்ணை உள்ளிட்டுள்ளீர்களா என்பதை இருமுறை சரிபார்த்து உறுதிப்படுத்தவும்.',
    'If you continue to face issues, verify your Aadhaar card or contact our support team at +91-9699900111 for further assistance. We appreciate your cooperation.':'நீங்கள் தொடர்ந்து சிக்கல்களைச் சந்தித்தால், உங்கள் ஆதார் அட்டையைச் சரிபார்க்கவும் அல்லது கூடுதல் உதவிக்கு எங்கள் ஆதரவுக் குழுவை +91-9699900111 என்ற எண்ணில் தொடர்பு கொள்ளவும். உங்கள் ஒத்துழைப்பை நாங்கள் பாராட்டுகிறோம்.',
    
    //newly added

    "Please ensure you have entered the correct GST details and follow the specified format for accurate verification.": "நீங்கள் சரியான ஜிஎஸ்டி விவரங்களை உள்ளிட்டுள்ளதை உறுதிசெய்து, துல்லியமான சரிபார்ப்புக்கு குறிப்பிட்ட வடிவமைப்பைப் பின்பற்றவும்.",
    "If you continue to face issues, kindly double-check your PAN card and re-enter the details.": "நீங்கள் தொடர்ந்து சிக்கல்களை எதிர்கொண்டால், தயவுசெய்து உங்கள் பான் கார்டை இருமுறை சரிபார்த்து, விவரங்களை மீண்டும் உள்ளிடவும்.",
    "The GST provided already exists in our records. Please try using a different GST for verification.": "வழங்கப்பட்ட ஜிஎஸ்டி ஏற்கனவே எங்கள் பதிவுகளில் உள்ளது. சரிபார்ப்பிற்கு வேறு GSTயைப் பயன்படுத்தவும்.",
    "It appears that the PAN details provided belong to a company. We require your personal PAN details for verification purposes.": "வழங்கப்பட்ட பான் விவரங்கள் ஒரு நிறுவனத்திற்கு சொந்தமானது என்று தெரிகிறது. சரிபார்ப்பு நோக்கங்களுக்காக உங்களின் தனிப்பட்ட PAN விவரங்கள் தேவை..",
    "If you continue to face issues, kindly double-check your PAN card and re- enter the details.For further queries or assistance, feel free to call us at +91-9699900111.": "நீங்கள் தொடர்ந்து சிக்கல்களை எதிர்கொண்டால், தயவுசெய்து உங்கள் பான் கார்டை இருமுறை சரிபார்த்து, விவரங்களை மீண்டும் உள்ளிடவும். மேலும் கேள்விகள் அல்லது உதவிக்கு, எங்களை +91-9699900111 என்ற எண்ணில் அழைக்கவும்.",
    "The PAN provided already exists in our records. Please try using a different PAN for verification.": "வழங்கப்பட்ட PAN ஏற்கனவே எங்கள் பதிவுகளில் உள்ளது. சரிபார்ப்பிற்கு வேறு PAN ஐப் பயன்படுத்த முயற்சிக்கவும்.",
    "New User Registration": "புதிய பயனர் பதிவு",
    "To begin your journey with us, we kindly request all new users to register by following the simple steps outlined below:": "எங்களுடன் உங்கள் பயணத்தைத் தொடங்க, கீழே உள்ள எளிய வழிமுறைகளைப் பின்பற்றி அனைத்து புதிய பயனர்களையும் பதிவு செய்யுமாறு கேட்டுக்கொள்கிறோம்:",
    "Registration": "பதிவு",
    "To register and gain access to our comprehensive verification services, please proceed by making a one-time payment of": "எங்கள் விரிவான சரிபார்ப்புச் சேவைகளைப் பதிவுசெய்து அணுகலைப் பெற, தயவு செய்து ஒரு முறை கட்டணம் செலுத்தி தொடரவும்",
    "Rs. 500": "ரூ. 500",
    "GST": "ஜிஎஸ்டி",
    "done": "முடிந்தது",
    "Please note that the registration fee is subject to 18% GST (Goods and Services Tax) as per government regulations.": "பதிவுக் கட்டணம் அரசாங்க விதிமுறைகளின்படி 18% GST (சரக்கு மற்றும் சேவை வரி)க்கு உட்பட்டது என்பதை நினைவில் கொள்ளவும்.",
    "Total Amount Due:": "செலுத்த வேண்டிய மொத்த தொகை:",
    "Rs. 590": "ரூ. 590",
    "Total Registration Fee + 18% GST": "மொத்தப் பதிவுக் கட்டணம் + 18% ஜிஎஸ்டி",
    "Secure Payment Gateway": "பாதுகாப்பான கட்டண நுழைவாயில்",
    "Rest assured, your payment and personal information are handled with the utmost security through our encrypted payment gateway.": "எங்களின் என்க்ரிப்ட் செய்யப்பட்ட பேமெண்ட் கேட்வே மூலம் உங்களது பேமெண்ட் மற்றும் தனிப்பட்ட தகவல்கள் மிகுந்த பாதுகாப்புடன் கையாளப்படுகின்றன.",
    "Registration Complete - Thank You!": "பதிவு முடிந்தது - நன்றி!",
    "Congratulations! You've completed the registration process. Your dedication to providing the necessary information has brought us one step closer to serving you effectively.": "வாழ்த்துகள்! பதிவு செயல்முறையை முடித்துவிட்டீர்கள். தேவையான தகவல்களை வழங்குவதற்கான உங்கள் அர்ப்பணிப்பு, உங்களுக்கு திறம்பட சேவை செய்வதற்கு எங்களை ஒரு படி மேலே கொண்டு வந்துள்ளது.",
    "With your registration finalized, our team is fully equipped to handle your requirements efficiently. We assure you that your case will be handled with the utmost care and attention to detail.": "உங்கள் பதிவு முடிவடைந்த நிலையில், உங்கள் தேவைகளை திறம்பட கையாள எங்கள் குழு முழுமையாக தயாராக உள்ளது. உங்கள் வழக்கு மிகவும் கவனமாகவும் விரிவாகவும் கையாளப்படும் என்று நாங்கள் உறுதியளிக்கிறோம்.",
    "Should you ever require assistance or have any queries, please feel free to contact us at any time. We are here to address your concerns and ensure a smooth journey throughout the process.": "உங்களுக்கு எப்போதாவது உதவி தேவைப்பட்டால் அல்லது ஏதேனும் கேள்விகள் இருந்தால், எந்த நேரத்திலும் எங்களைத் தொடர்புகொள்ள தயங்க வேண்டாம். உங்கள் கவலைகளை நிவர்த்தி செய்யவும், செயல்முறை முழுவதும் சுமூகமான பயணத்தை உறுதி செய்யவும் நாங்கள் இங்கு வந்துள்ளோம்.",
    "We eagerly anticipate the opportunity to assist you further.": "உங்களுக்கு மேலும் உதவுவதற்கான வாய்ப்பை நாங்கள் ஆவலுடன் எதிர்பார்க்கிறோம்.",
    "Warm regards,": "அன்பான வாழ்த்துக்கள்,",
    "PrivateCourt Team": "பிரைவேட் கோர்ட் குழு",
    "Secure UDYAM Number Validation": "பாதுகாப்பான UDYAM எண் சரிபார்ப்பு",
    "Enter UDYAM for": "UDYAM ஐ உள்ளிடவும்",
    "having GST number": "ஜிஎஸ்டி எண் உள்ளது",
    "Enter Your Udyam Number": "உங்கள் Udyam எண்ணை உள்ளிடவும்",
    "Error: Invalid UAM Number": "பிழை: தவறான UAM எண்",
    "We regret to inform you that the UAM Number entered isinvalid or incorrect. Please ensure you have accuratelyentered detail and double-check for any typos.": "உள்ளிட்ட UAM எண் தவறானது அல்லது தவறானது என்பதை உங்களுக்குத் தெரிவிக்க வருந்துகிறோம். நீங்கள் விவரங்களைத் துல்லியமாக உள்ளிட்டுள்ளதை உறுதிசெய்து, ஏதேனும் எழுத்துப் பிழைகள் உள்ளதா என இருமுறை சரிபார்க்கவும்.",
    "Server Overload: Please Retry Later": "சர்வர் ஓவர்லோட்: தயவுசெய்து பிறகு முயற்சிக்கவும்",
    "We apologize for the inconvenience and appreciate your patience. Currently, our server is experiencing a surge in requests, creating a temporary delay. Rest assured, we are diligently collecting your data to ensure accuracy and security.": "சிரமத்திற்கு வருந்துகிறோம், உங்கள் பொறுமையைப் பாராட்டுகிறோம். தற்போது, எங்கள் சேவையகம் கோரிக்கைகளின் அதிகரிப்பை எதிர்கொள்கிறது, தற்காலிக தாமதத்தை உருவாக்குகிறது. உறுதியாக இருங்கள், துல்லியம் மற்றும் பாதுகாப்பை உறுதி செய்வதற்காக உங்கள் தரவை நாங்கள் ஆர்வத்துடன் சேகரித்து வருகிறோம்.",
    "Please bear with us during this busy period, and we kindly request you to wait for a brief moment before attempting again. Your understanding is crucial in maintaining the quality of service we strive to provide.": "இந்த பரபரப்பான காலகட்டத்தில் தயவுசெய்து எங்களுடன் பொறுமையாக இருங்கள், மீண்டும் முயற்சிக்கும் முன் சிறிது நேரம் காத்திருக்குமாறு கேட்டுக்கொள்கிறோம். நாங்கள் வழங்க முயற்சிக்கும் சேவையின் தரத்தை பராமரிப்பதில் உங்கள் புரிதல் முக்கியமானது.",
    "For urgent matters or assistance, feel free to call us at +91-9699900111.": "அவசர விஷயங்கள் அல்லது உதவிக்கு, எங்களை +91-9699900111 என்ற எண்ணில் அழைக்கவும்.",
    "Thank you for your patience and entrusting PrivateCourt. Your security is our priority.": "உங்கள் பொறுமைக்கும், தனியார் நீதிமன்றத்தை நம்பி ஒப்படைத்ததற்கும் நன்றி. உங்கள் பாதுகாப்பு எங்கள் முன்னுரிமை.",
    "If you continue to face issues, verify the": "நீங்கள் தொடர்ந்து சிக்கல்களை எதிர்கொண்டால், சரிபார்க்கவும்",
    "Applicant’s": "விண்ணப்பதாரரின்",
    "Error: Invalid OTP": "பிழை: தவறான OTP",
    "We apologize for the inconvenience. The OTP entered is incorrect. Please double-check the OTP and try again.": "சிரமத்திற்கு வருந்துகிறோம். உள்ளிட்ட OTP தவறானது. OTP ஐ இருமுறை சரிபார்த்து மீண்டும் முயற்சிக்கவும்.",
    "Aadhaar card or contact our support team at +91-9699900111 for further assistance. We appreciate your cooperation.": "மேலும் உதவிக்கு ஆதார் அட்டை அல்லது எங்கள் ஆதரவு குழுவை +91-9699900111 என்ற எண்ணில் தொடர்பு கொள்ளவும். உங்கள் ஒத்துழைப்பை நாங்கள் பாராட்டுகிறோம்.",
    "Error: User already exists": "பிழை: பயனர் ஏற்கனவே இருக்கிறார்",
    "We apologize for the inconvenience. The Aadhaar number provided already exists in our records.Please provide a different Aadhaar number for verification.": "சிரமத்திற்கு வருந்துகிறோம். வழங்கப்பட்ட ஆதார் எண் ஏற்கனவே எங்கள் பதிவுகளில் உள்ளது. சரிபார்ப்பதற்காக வேறு ஆதார் எண்ணை வழங்கவும்.",
    "We apologize for the inconvenience. The name provided does not match the PAN records. Please ensure that the name entered matches the information on your PAN card and try again.": "சிரமத்திற்கு வருந்துகிறோம். வழங்கப்பட்ட பெயர் PAN பதிவுகளுடன் பொருந்தவில்லை. உள்ளிட்ட பெயர் உங்கள் பான் கார்டில் உள்ள தகவலுடன் பொருந்துகிறதா என்பதை உறுதிசெய்து, மீண்டும் முயற்சிக்கவும்.",
    "Enter Bank Details for": "வங்கி விவரங்களை உள்ளிடவும்",
    "Error: Already Exists": "பிழை: ஏற்கனவே உள்ளது",
    "We regret to inform you that the ID you've provided already exists in our system. Please try another ID.": "நீங்கள் வழங்கிய ஐடி ஏற்கனவே எங்கள் சிஸ்டத்தில் உள்ளது என்பதை தெரிவித்துக்கொள்கிறோம். வேறொரு ஐடியை முயற்சிக்கவும்.",
    "If you continue to face issues, kindly verify your bank statement or contact your bank to confirm the accuracy of the provided information.": "நீங்கள் தொடர்ந்து சிக்கல்களைச் சந்தித்தால், உங்கள் வங்கி அறிக்கையைச் சரிபார்க்கவும் அல்லது வழங்கப்பட்ட தகவலின் துல்லியத்தை உறுதிப்படுத்த உங்கள் வங்கியைத் தொடர்பு கொள்ளவும்.",
    "Error: Name Not Matched": "பிழை: பெயர் பொருந்தவில்லை",
    "Good Afternoon": "மதிய வணக்கம்",
    "Account security starts with verification": "கணக்கு பாதுகாப்பு சரிபார்ப்புடன் தொடங்குகிறது",
    "OTP Validation for Account Access": "கணக்கு அணுகலுக்கான OTP சரிபார்ப்பு",
    "Kindly check your inbox, and enter the OTP in the designated field to complete the verification process.": "உங்கள் இன்பாக்ஸைச் சரிபார்த்து, சரிபார்ப்பு செயல்முறையை முடிக்க நியமிக்கப்பட்ட புலத்தில் OTP ஐ உள்ளிடவும்.",
    "Request a Resend": "மீண்டும் அனுப்பக் கோரவும்",
    "Error: Incorrect OTP": "பிழை: தவறான OTP",
    "We apologize for the inconvenience. The One-Time Password (OTP) entered is incorrect. Please ensure that you have accurately entered the OTP received in your email.": "சிரமத்திற்கு வருந்துகிறோம். உள்ளிடப்பட்ட ஒரு முறை கடவுச்சொல் (OTP) தவறானது. உங்கள் மின்னஞ்சலில் பெறப்பட்ட OTPயை நீங்கள் துல்லியமாக உள்ளிட்டுள்ளீர்கள் என்பதை உறுதிப்படுத்தவும்.",
    "If the issue persists, you may request a new OTP by clicking on the resend link or contacting our support team at +91-9699900111.": "சிக்கல் தொடர்ந்தால், மீண்டும் அனுப்பும் இணைப்பைக் கிளிக் செய்வதன் மூலம் அல்லது எங்கள் ஆதரவுக் குழுவை +91-9699900111 என்ற எண்ணில் தொடர்புகொள்வதன் மூலம் நீங்கள் புதிய OTP-ஐக் கோரலாம்.",
    "Double-check the email and try entering the OTP again.": "மின்னஞ்சலை இருமுறை சரிபார்த்து, மீண்டும் OTP ஐ உள்ளிட முயற்சிக்கவும்.",
    "At PrivateCourt, we prioritize the security and integrity of our services. Our GST verification process is meticulously designed to ensure a reliable and secure experience for all our users.": "PrivateCourt இல், எங்கள் சேவைகளின் பாதுகாப்பு மற்றும் ஒருமைப்பாட்டுக்கு முன்னுரிமை அளிக்கிறோம். எங்கள் ஜிஎஸ்டி சரிபார்ப்பு செயல்முறை, எங்கள் பயனர்கள் அனைவருக்கும் நம்பகமான மற்றும் பாதுகாப்பான அனுபவத்தை உறுதிசெய்யும் வகையில் மிகவும் கவனமாக வடிவமைக்கப்பட்டுள்ளது.",
    "Enter Your GSTIN": "உங்கள் GSTIN ஐ உள்ளிடவும்",
    "Please enter your correct GST Identification Number (GSTIN) in the format 00-XXXXX-0000-X-0-X-0.": "உங்கள் சரியான GST அடையாள எண்ணை (GSTIN) 00-XXXXX-0000-X-0-X-0 வடிவத்தில் உள்ளிடவும்.",
    "Upon entering your GSTIN, PrivateCourt will diligently collect essential information associated with it. This includes your full name, date of birth, residential address, and contact details. For business owners, we extend our data collection to encompass all pertinent business details tied to your GSTIN, such a specifics and records of GST return filings.": "உங்கள் ஜிஎஸ்டிஐஎன் உள்ளிடும்போது, அதனுடன் தொடர்புடைய அத்தியாவசியத் தகவல்களை தனியார் நீதிமன்றம் ஆர்வத்துடன் சேகரிக்கும். இதில் உங்கள் முழுப்பெயர், பிறந்த தேதி, வசிக்கும் முகவரி மற்றும் தொடர்பு விவரங்கள் ஆகியவை அடங்கும். வணிக உரிமையாளர்களுக்காக, உங்கள் GSTIN உடன் இணைக்கப்பட்ட அனைத்து வணிக விவரங்களையும் உள்ளடக்கியதாக எங்கள் தரவு சேகரிப்பை விரிவுபடுத்துகிறோம்.",
    "Our system assesses your CIBIL score for financial insight and cross-verifies for any ongoing court cases associated with your GSTIN.": "எங்கள் அமைப்பு உங்கள் CIBIL ஸ்கோரை நிதி நுண்ணறிவுக்காக மதிப்பிடுகிறது மற்றும் உங்கள் GSTIN உடன் தொடர்புடைய நீதிமன்ற வழக்குகளுக்கு குறுக்கு-சரிபார்க்கிறது.",
    "Error: Invalid GST Format": "பிழை: தவறான GST வடிவம்",
    "Please enter your personal PAN": "உங்கள் தனிப்பட்ட PAN எண்ணை உள்ளிடவும்",
    "Already Exists": "ஏற்கனவே இருக்கிறது",
    "Invalid": "செல்லாதது",
    "Secure PAN Validation Process": "பாதுகாப்பான பான் சரிபார்ப்பு செயல்முறை",
    "This verification process is designed to uphold the integrity of our services, ensuring a reliable and secure experience.": "இந்தச் சரிபார்ப்புச் செயல்முறையானது, எங்கள் சேவைகளின் ஒருமைப்பாட்டை நிலைநிறுத்தி, நம்பகமான மற்றும் பாதுகாப்பான அனுபவத்தை உறுதிசெய்யும் வகையில் வடிவமைக்கப்பட்டுள்ளது.",
    "Enter Your PAN Number": "உங்கள் PAN எண்ணை உள்ளிடவும்",
    "Please enter your correct PAN number in the format XXXXX-0000-X.": "உங்கள் சரியான PAN எண்ணை XXXXX-0000-X வடிவத்தில் உள்ளிடவும்.",
    "PrivateCourt will meticulously collect essential information associated with your PAN such as your full name, date of birth, residential address, and contact details. For Business Owners, we extend our data collection to encompass all pertinent business details tied to your PAN such as GST details specifics, and records of GST return filings.": "உங்களின் முழுப்பெயர், பிறந்த தேதி, வசிக்கும் முகவரி மற்றும் தொடர்பு விவரங்கள் போன்ற உங்களின் பான் எண்ணுடன் தொடர்புடைய அத்தியாவசியத் தகவல்களை PrivateCourt உன்னிப்பாகச் சேகரிக்கும். வணிக உரிமையாளர்களுக்கு, ஜிஎஸ்டி விவரங்கள் விவரங்கள் மற்றும் ஜிஎஸ்டி ரிட்டர்ன் தாக்கல்களின் பதிவுகள் போன்ற உங்கள் PAN உடன் இணைக்கப்பட்ட அனைத்து வணிக விவரங்களையும் உள்ளடக்கியதாக எங்கள் தரவு சேகரிப்பை விரிவுபடுத்துகிறோம்.",
    "Our system assesses your CIBIL score for financial insight and cross-verifies for any ongoing court cases associated with your PAN.": "எங்கள் அமைப்பு உங்கள் CIBIL ஸ்கோரை நிதி நுண்ணறிவுக்காக மதிப்பிடுகிறது மற்றும் உங்கள் PAN உடன் தொடர்புடைய நீதிமன்ற வழக்குகளுக்கு குறுக்கு-சரிபார்க்கிறது.",
    "It appears that the PAN details provided belong to a company. We require your personal PAN details for verification purposes.": "வழங்கப்பட்ட பான் விவரங்கள் ஒரு நிறுவனத்திற்கு சொந்தமானது என்று தெரிகிறது. சரிபார்ப்பு நோக்கங்களுக்காக உங்களின் தனிப்பட்ட PAN விவரங்கள் தேவை.",

    "Data Compilation in Progress: Please Allow Processing Time": "தரவு தொகுத்தல் செயல்பாட்டில் உள்ளது: செயலாக்க நேரத்தை அனுமதிக்கவும்",
    "Thank you for providing your information. Our system is currently in the process of analyzing and compiling the data you've entered, including details such as PAN, Aadhaar, GST, Email, and more, to establish your comprehensive profile.": "உங்கள் தகவலை வழங்கியதற்கு நன்றி. உங்கள் விரிவான சுயவிவரத்தை உருவாக்க, PAN, ஆதார், GST, மின்னஞ்சல் மற்றும் பல போன்ற விவரங்கள் உட்பட, நீங்கள் உள்ளிட்ட தரவை பகுப்பாய்வு செய்து தொகுக்கும் பணியில் எங்கள் அமைப்பு தற்போது ஈடுபட்டுள்ளது.",
    "This operation may take a few moments. During this time, your screen may show minimal activity. Please be assured that this is a routine part of our data compilation process.": "இந்தச் செயல்பாடு சில நொடிகள் ஆகலாம். இந்த நேரத்தில், உங்கள் திரை குறைந்தபட்ச செயல்பாட்டைக் காட்டலாம். இது எங்கள் தரவுத் தொகுத்தல் செயல்முறையின் வழக்கமான பகுதியாகும் என்பதை உறுதிப்படுத்திக் கொள்ளவும்.",
    "Your profile is being meticulously crafted to uphold accuracy and security. We sincerely appreciate your patience and understanding as we work to enhance your experience.": "உங்கள் சுயவிவரம் துல்லியம் மற்றும் பாதுகாப்பை நிலைநிறுத்துவதற்கு உன்னிப்பாக வடிவமைக்கப்பட்டுள்ளது. உங்கள் அனுபவத்தை மேம்படுத்த நாங்கள் உழைக்கும்போது உங்கள் பொறுமை மற்றும் புரிதலை நாங்கள் மனதாரப் பாராட்டுகிறோம்.",
    "For urgent matters or assistance, feel free to call us at +91-9699900111.": "அவசர விஷயங்கள் அல்லது உதவிகளுக்கு, எங்களை +91-9699900111 என்ற எண்ணில் அழைக்கவும்.",
    "Thank you for your patience and entrusting PrivateCourt. Your security is our priority.": "உங்கள் பொறுமைக்கும், தனியார் நீதிமன்றத்தை நம்பி ஒப்படைத்ததற்கும் நன்றி. உங்கள் பாதுகாப்பு எங்கள் முன்னுரிமை.",
    "Please input your Aadhaar number. This process ensures accurate cross-checking for your security. Thank you for your cooperation.": "உங்கள் ஆதார் எண்ணை உள்ளிடவும். இந்த செயல்முறை உங்கள் பாதுகாப்பிற்கான துல்லியமான குறுக்கு சோதனையை உறுதி செய்கிறது. உங்கள் ஒத்துழைப்பிற்கு நன்றி.",
    "We apologize for the inconvenience. The Aadhaar number entered is invalid. Please double-check and ensure that you have entered the": "சிரமத்திற்கு வருந்துகிறோம். உள்ளிட்ட ஆதார் எண் தவறானது. இருமுறை சரிபார்த்து, நீங்கள் உள்ளிட்டுள்ளீர்கள் என்பதை உறுதிப்படுத்தவும்",
    "Applicant’s correct 12-digit Aadhaar number.": "விண்ணப்பதாரரின் சரியான 12 இலக்க ஆதார் எண்.",
    "Error: Invalid OTP": "பிழை: தவறான OTP",
    "We apologize for the inconvenience. The OTP entered is incorrect. Please double-check the OTP and try again.": "சிரமத்திற்கு வருந்துகிறோம். உள்ளிட்ட OTP தவறானது. OTP ஐ இருமுறை சரிபார்த்து மீண்டும் முயற்சிக்கவும்.",
    "Welcome": "வரவேற்பு",
    "Ensuring the accuracy of your bank account information is crucial for a seamless verification process.Kindly enter your valid Account Number, and input the correct IFSC code associated with your account.": "தடையற்ற சரிபார்ப்புச் செயல்முறைக்கு உங்கள் வங்கிக் கணக்குத் தகவலின் துல்லியத்தை உறுதிசெய்வது மிகவும் முக்கியமானது. உங்கள் செல்லுபடியாகும் கணக்கு எண்ணை உள்ளிட்டு, உங்கள் கணக்குடன் தொடர்புடைய சரியான IFSC குறியீட்டை உள்ளிடவும்.",
    "This verification step is designed to enhance the security of our services and provide you with a reliable experience.": "இந்த சரிபார்ப்பு படி எங்கள் சேவைகளின் பாதுகாப்பை மேம்படுத்தவும் நம்பகமான அனுபவத்தை உங்களுக்கு வழங்கவும் வடிவமைக்கப்பட்டுள்ளது.",
    "We apologize for the inconvenience. The name provided does not match the GST records. Please ensure that the name entered matches the information on your GST card and try again.": "சிரமத்திற்கு வருந்துகிறோம். வழங்கப்பட்ட பெயர் GST பதிவுகளுடன் பொருந்தவில்லை. உள்ளிட்ட பெயர் உங்கள் ஜிஎஸ்டி கார்டில் உள்ள தகவலுடன் பொருந்துகிறதா என்பதை உறுதிசெய்து, மீண்டும் முயற்சிக்கவும்.",
    "Business Verification Process": "வணிக சரிபார்ப்பு செயல்முறை",
    "Ensuring Secure Business Validation- This verification process is meticulously designed to uphold the integrity of our services, providing a reliable and secure experience.": "பாதுகாப்பான வணிகச் சரிபார்ப்பை உறுதி செய்தல்- இந்தச் சரிபார்ப்புச் செயல்முறையானது, நம்பகமான மற்றும் பாதுகாப்பான அனுபவத்தை வழங்கும், எங்கள் சேவைகளின் ஒருமைப்பாட்டை நிலைநிறுத்துவதற்காக உன்னிப்பாக வடிவமைக்கப்பட்டுள்ளது.",
    "Please provide details for each of your businesses for accurate validation.": "துல்லியமான சரிபார்ப்புக்கு உங்கள் ஒவ்வொரு வணிகத்திற்கும் விவரங்களை வழங்கவும்.",
    "PrivateCourt will collect essential information associated with your PAN such as your full name, date of birth, residential address, and contact details. For Business Owners, we extend our data collection to encompass all pertinent business details tied to your PAN such as GST details, MCA specifics, and records of GST return filings.": "உங்கள் முழுப்பெயர், பிறந்த தேதி, குடியிருப்பு முகவரி மற்றும் தொடர்பு விவரங்கள் போன்ற உங்கள் PAN உடன் தொடர்புடைய அத்தியாவசியத் தகவல்களை PrivateCourt சேகரிக்கும். வணிக உரிமையாளர்களுக்கு, GST விவரங்கள், MCA விவரக்குறிப்புகள் மற்றும் GST ரிட்டர்ன் தாக்கல்களின் பதிவுகள் போன்ற உங்கள் PAN உடன் இணைக்கப்பட்டுள்ள அனைத்து தொடர்புடைய வணிக விவரங்களையும் உள்ளடக்கும் வகையில் எங்கள் தரவு சேகரிப்பை விரிவுபடுத்துகிறோம்.",
    "Our system evaluates your CIBIL score for financial insight and cross-verifies for any ongoing court cases associated with your PAN.": "நிதி நுண்ணறிவுக்காக உங்கள் CIBIL ஸ்கோரை எங்கள் அமைப்பு மதிப்பிடுகிறது மற்றும் உங்கள் PAN உடன் தொடர்புடைய நீதிமன்ற வழக்குகளுக்கு குறுக்கு சரிபார்ப்பு செய்கிறது.",
    "To ensure the security of your account, please enter your valid Email ID in the specified format- username@domain.com. If your email address doesn't exist in our system, a": "உங்கள் கணக்கின் பாதுகாப்பை உறுதிசெய்ய, உங்கள் சரியான மின்னஞ்சல் ஐடியை குறிப்பிட்ட வடிவத்தில் உள்ளிடவும்- username@domain.com. உங்கள் மின்னஞ்சல் முகவரி எங்கள் அமைப்பில் இல்லை என்றால், ஏ",
    "account will be created for you.": "உங்களுக்காக கணக்கு உருவாக்கப்படும்.",
    "GST Verification Process": "ஜிஎஸ்டி சரிபார்ப்பு செயல்முறை",
    "We regret to inform you that the GST number entered does not match the required format (00-XXXXX-0000-X-0-X-0).Please ensure you have entered the correct GST details and follow the specified format for accurate verification.": "உள்ளிடப்பட்ட ஜிஎஸ்டி எண் தேவையான வடிவத்துடன் (00-XXXXX-0000-X-0-X-0) பொருந்தவில்லை என்பதைத் தெரிவித்துக் கொள்கிறோம். சரியான ஜிஎஸ்டி விவரங்களை உள்ளிட்டுள்ளதை உறுதிசெய்து, துல்லியமான சரிபார்ப்புக்கு குறிப்பிட்ட வடிவமைப்பைப் பின்பற்றவும்.",
    "Verify GST": "ஜிஎஸ்டியைச் சரிபார்க்கவும்",
    "Congratulations! You've completed the registration process. Your dedication to providing the necessary information has brought us one step closer to serving you effectively.": "வாழ்த்துகள்! பதிவு செயல்முறையை முடித்துவிட்டீர்கள். தேவையான தகவல்களை வழங்குவதற்கான உங்கள் அர்ப்பணிப்பு, உங்களுக்கு திறம்பட சேவை செய்வதற்கு எங்களை ஒரு படி மேலே கொண்டு வந்துள்ளது.",
    "We apologize for the inconvenience. The name provided does not match the GST records. Please ensure that the name entered matches the information on your GST card and try again.": "சிரமத்திற்கு வருந்துகிறோம். வழங்கப்பட்ட பெயர் GST பதிவுகளுடன் பொருந்தவில்லை. உள்ளிட்ட பெயர் உங்கள் ஜிஎஸ்டி கார்டில் உள்ள தகவலுடன் பொருந்துகிறதா என்பதை உறுதிசெய்து, மீண்டும் முயற்சிக்கவும்.",
    "Skip": "தவிர்க்கவும்",
    "Server Overload: Please Retry Later": "சர்வர் ஓவர்லோட்: தயவுசெய்து பிறகு முயற்சிக்கவும்",
    "Back To Home": "வீட்டிற்குத் திரும்பு",
    "CONCILIATION NOTICE": "சூழல் அறிவிப்பு",
    "Warm Greetings from PRIVATECOURT. We are writing to formally inform you that PRIVATECOURT will oversee and resolve the dispute(s) between you and company .": "PRIVATECOURT இல் இருந்து மண் வணக்கங்கள். PRIVATECOURT உங்கள் மற்றும் நிறுவனத்திற்கிடையிலான மோதல்களைப் கண்காணித்து, தீர்க்கப்படும் என்பதை நாங்கள் உங்களைப் அதிகாரப்பூர்வமாகத் தகவல் அளிக்கிறோம்.",
    "Please find attached the confirmation document with hearing details that outline our acceptance of our commitment to handling your disputes through Alternative Dispute Resolution (ADR).": "நாங்கள் உங்கள் மோதல்களை மாற்று மோதலுக்கான தீர்வின் (ADR) மூலம் கையாளும் நம்பிக்கையை ஒப்புக்கொள்வதை விளக்கும் கேள்வி விவரங்களுடன் உறுதிப்பத்திரத்தை இணைத்துள்ளோம்.",
    "To explore resolution options and engage in the process effectively, we provide the following avenues, from which you can select any one way:": "தீர்வு விருப்பங்களை ஆராய்ந்து, செயல்முறை நன்கு ஈடுபடுவதற்காக, நாங்கள் கீழ்க்காணும் வழிகளை வழங்குகிறோம், இவற்றில் எதுவும் நீங்கள் தேர்ந்தெடுக்கலாம்:",
    "Access Your Personalized Dispute Resolution Page: Click {{ params.disputelink }} to access your dedicated dispute resolution page. Here, you can provide detailed information about your issue. Our support team will review your submission promptly and guide you through the next steps of the resolution process.": "உங்கள் தனிப்பட்ட மோதல் தீர்வு பக்கம் அணுகவும்: உங்கள் மவுணை குறிக்கோள் தீர்வு பக்கம் அணுக {{ params.disputelink }} என்பதைக் கிளிக் செய்யவும். இங்கு, நீங்கள் உங்கள் பிரச்சினையைக் குறித்த விரிவான தகவல்களை வழங்கலாம். எங்கள் ஆதரவு குழு உங்கள் சமர்ப்பணத்தை உடனடியாகப் பரிசீலித்து, தீர்வுப் செயல்முறையின் அடுத்த படிகள் மூலம் உங்களை வழிகாட்டும்.",
    "OR": "அல்லது",
    "You are hereby directed to attend the {{params.meetingtype}} hearing scheduled on {{ params.conciiationdate }}, at {{ params.conciliationaddress }}, between {{params.timing}}.": "நீங்கள் {{ params.conciiationdate }} அன்று {{ params.conciliationaddress }} இல், {{params.timing}} இடையில் திட்டமிடப்பட்ட {{params.meetingtype}} கேள்வியைச் சேர்க்குமாறு இங்கு உங்களை வழிமொழிகிறோம்.",
    "This platform enables you to directly engage with our team, comprehensively discussing and sharing your dispute details.": "இந்த தளம் உங்களை நேரடியாக எங்கள் குழுவுடன் இணைந்து, உங்கள் மோதல் விவரங்களை விரிவாகப் பேச மற்றும் பகிர்ந்து கொள்ள உதவுகிறது.",
    "If you have any additional information or documentation related to this matter, please feel free to reach out to our dedicated customer support team at +918035731376 or Email: legal@privatecourt.in": "இந்த விஷயத்திற்கு சம்பந்தப்பட்ட கூடுதல் தகவல்களோ அல்லது ஆவணங்களோ இருந்தால், தயவுசெய்து +918035731376 என்ற எண்ணுக்கு அல்லது legal@privatecourt.in என்ற மின்னஞ்சலுக்கு எங்கள் நன்கு அய்யா ஆதரவு குழுவுடன் தொடர்புகொள்ள தயங்க வேண்டாம்.",
    "We appreciate your trust in PRIVATECOURT for dispute resolution and are committed to delivering a fair and impartial resolution to the matter at hand.": "மோதல் தீர்விற்காக PRIVATECOURT மீது உங்கள் நம்பிக்கையை நாங்கள் மதிக்கிறோம் மற்றும் தற்போதைய விவகாரத்திற்கு நேர்மையான மற்றும் அங்கீகாரமான தீர்வுகளை வழங்குவதில் நாங்கள் உறுதியாக உள்ளோம்.",
    "Thank You,": "நன்றி,",
    "Team PrivateCourt": "பிரைவேட்‌கார்ட் குழு",
    "Today": "இன்று",
    "After 3 days": "3 நாட்கள் கழித்து",
    "After 7 days": "7 நாட்கள் கழித்து",
    "After 10 days": "10 நாட்கள் கழித்து",
    "After 15 days": "15 நாட்கள் கழித்து",
    "After 20 days": "20 நாட்கள் கழித்து"
    }

export default TamilTranslation;