const EnglishTranslation={
    'Email Security Check':'Email Security Check',
    'Verify Your Email for Account Access': 'Verify Your Email for Account Access',
    "To ensure the security of your account, please enter your valid Email ID in the specified format- username@domain.com. If your email address doesn't exist in our system": "To ensure the security of your account, please enter your valid Email ID in the specified format- username@domain.com. If your email address doesn't exist in our system",
    'NEW USER': 'NEW USER',
    'account will be created for you': 'account will be created for you',
    'Once submitted, you will receive a Verification OTP (One-Time Password) sent to your provided email address.': 'Once submitted, you will receive a Verification OTP (One-Time Password) sent to your provided email address.',
    'For any queries or assistance, feel free to call us at +91-9699900111.': 'For any queries or assistance, feel free to call us at +91-9699900111.',
    'Thank you for entrusting PrivateCourt. Your security is our priority.': 'Thank you for entrusting PrivateCourt. Your security is our priority.',
    'Verify': 'Verify',
    'Invalid Email Address': 'Invalid Email Address',
    'We regret to inform you that the email address entered is invalid. Please ensure that you have entered a correct and properly formatted email address.': 'We regret to inform you that the email address entered is invalid. Please ensure that you have entered a correct and properly formatted email address.',
    'If the issue persists, double-check for any typos or errors in the email format (e.g., username@example.com).': 'If the issue persists, double-check for any typos or errors in the email format (e.g., username@example.com).',
    'For further queries or assistance, feel free to call us at +91-9699900111.': 'For further queries or assistance, feel free to call us at +91-9699900111.',
    'Thank you for entrusting PrivateCourt. Your security is our priority.': 'Thank you for entrusting PrivateCourt. Your security is our priority.',
    'Secure UDYAM Number Validation':'Secure UDYAM Number Validation',
    'Enter Your Udyam Number':'Enter Your Udyam Number',
    'Please enter your correct UDYAM Number.':'Please enter your correct UDYAM Number.',
    'Your UDYAM Number allows us to collect crucial details associated with your business. This includes your business address, current status, service details, and organization type.':'Your UDYAM Number allows us to collect crucial details associated with your business. This includes your business address, current status, service details, and organization type.',
    'Ensuring the accuracy of your UDYAM Number is vital for a seamless verification process.':'Ensuring the accuracy of your UDYAM Number is vital for a seamless verification process.',
    'UDYAM':'UDYAM',
    'Error: Invalid UDYAM Number':'Error: Invalid UDYAM Number',
    'We regret to inform you that the UDYAM number entered is invalid or incorrect. Please ensure you have entered the correct UDYAM details and double-check for any typos.':'We regret to inform you that the UDYAM number entered is invalid or incorrect. Please ensure you have entered the correct UDYAM details and double-check for any typos.',
    'If you continue to face issues, kindly verify your UDYAM certificate and re-enter the details.':'If you continue to face issues, kindly verify your UDYAM certificate and re-enter the details.',
    'Secure PAN Validation Process':'Secure PAN Validation Process',
    'This verification process is designed to uphold the integrity of our services, ensuring a reliable and secure experience.':'This verification process is designed to uphold the integrity of our services, ensuring a reliable and secure experience.',
    'Enter Your PAN Number':'Enter Your PAN Number',
    'Please enter your correct PAN number in the format XXXXX-0000-X.':'Please enter your correct PAN number in the format XXXXX-0000-X.',
    'PrivateCourt will meticulously collect essential information associated with your PAN such as your full name, date of birth, residential address, and contact details. For Business Owners, we extend our data collection to encompass all pertinent business details tied to your PAN such as GST details, MCA specifics, and records of GST return filings.':'PrivateCourt will meticulously collect essential information associated with your PAN such as your full name, date of birth, residential address, and contact details. For Business Owners, we extend our data collection to encompass all pertinent business details tied to your PAN such as GST details, MCA specifics, and records of GST return filings.',
    'Our system assesses your CIBIL score for financial insight and cross-verifies for any ongoing court cases associated with your PAN.':'Our system assesses your CIBIL score for financial insight and cross-verifies for any ongoing court cases associated with your PAN.',
    'Error: Invalid PAN Format':'Error: Invalid PAN Format',
    'We regret to inform you that the PAN number entered does not match the required format (XXXXX-0000-X). Please ensure you have entered the correct PAN details and follow the specified format for accurate verification.':'We regret to inform you that the PAN number entered does not match the required format (XXXXX-0000-X). Please ensure you have entered the correct PAN details and follow the specified format for accurate verification.',
    'If you continue to face issues, kindly double-check your PAN card and re-enter the details. For further queries or assistance, feel free to call us at +91-9699900111.':'If you continue to face issues, kindly double-check your PAN card and re-enter the details. For further queries or assistance, feel free to call us at +91-9699900111.',
    'Welcome XXXX':'Welcome XXXX',
    'Account security starts with verification':'Account security starts with verification',
    'OTP Validation for Account Access':'OTP Validation for Account Access',
    'Kindly check your inbox, and enter the OTP in the designated field to complete the verification process.':'Kindly check your inbox, and enter the OTP in the designated field to complete the verification process.',
    "Didn't receive OTP?":"Didn't receive OTP?",
    'Request a Resend':'Request a Resend',
    'Verify OTP':'Verify OTP',
    'Error: Incorrect OTP':'Error: Incorrect OTP',
    'We apologize for the inconvenience. The One-Time Password (OTP) entered is incorrect. Please ensure that you have accurately entered the OTP received in your email.':'We apologize for the inconvenience. The One-Time Password (OTP) entered is incorrect. Please ensure that you have accurately entered the OTP received in your email.',
    'If the issue persists, you may request a new OTP by clicking on the resend link or contacting our support team at +91-9699900111.':'If the issue persists, you may request a new OTP by clicking on the resend link or contacting our support team at +91-9699900111.',
    'Double-check the email and try entering the OTP again.':'Double-check the email and try entering the OTP again.',
    'Choose Profile':'Choose Profile',
    'Setting Your Default Profile':'Setting Your Default Profile',
    'Please choose the default profile for a seamless experience.':'Please choose the default profile for a seamless experience.',
    "If you manage multiple companies, select the profile you'd like to set as the default. This ensures that every time you log in, you'll have quick access to the information relevant to your preferred company.":"If you manage multiple companies, select the profile you'd like to set as the default. This ensures that every time you log in, you'll have quick access to the information relevant to your preferred company.",
    'Making the right selection now simplifies your future logins and provides a tailored experience based on your primary business entity.':'Making the right selection now simplifies your future logins and provides a tailored experience based on your primary business entity.',
    'Go to Dashboard':'Go to Dashboard',
    'Data Compilation in Progress':'Data Compilation in Progress',
    'Please Allow Processing Time':'Please Allow Processing Time',
    "Thank you for providing your information. Our system is currently in the process of analyzing and compiling the data you've entered, including details such as PAN, Aadhaar, GST, Email, and more, to establish your comprehensive profile.":"Thank you for providing your information. Our system is currently in the process of analyzing and compiling the data you've entered, including details such as PAN, Aadhaar, GST, Email, and more, to establish your comprehensive profile.",
    "This operation may take a few moments. During this time, your screen may show minimal activity. Please be assured that this is a routine part of our data compilation process.":'This operation may take a few moments. During this time, your screen may show minimal activity. Please be assured that this is a routine part of our data compilation process.',
    'Your profile is being meticulously crafted to uphold accuracy and security. We sincerely appreciate your patience and understanding as we work to enhance your experience.':'Your profile is being meticulously crafted to uphold accuracy and security. We sincerely appreciate your patience and understanding as we work to enhance your experience.',
    'Start Your Bank Account Verification':'Start Your Bank Account Verification',
    'Bank Verification':'Bank Verification',
    'Ensuring the accuracy of your bank account information is crucial for a seamless verification process. Kindly enter your valid Account Number, and input the correct IFSC code associated with your account.':'Ensuring the accuracy of your bank account information is crucial for a seamless verification process. Kindly enter your valid Account Number, and input the correct IFSC code associated with your account.',
    'This verification step is designed to enhance the security of our services and provide you with a reliable experience.':'This verification step is designed to enhance the security of our services and provide you with a reliable experience.',
    'Account Number':'Account Numbe',
    'IFSC code':'IFSC code',
    'Error: Invalid Bank Account or IFSC Code':'Error: Invalid Bank Account or IFSC Code',
    'We regret to inform you that the Bank Account or IFSC Code entered is invalid or incorrect. Please ensure you have accurately entered both details and double-check for any typos.':'We regret to inform you that the Bank Account or IFSC Code entered is invalid or incorrect. Please ensure you have accurately entered both details and double-check for any typos.',
    'If you continue to face issues, kindly verify your bank statement or contact your bank to confirm the accuracy of the provided information.':'If you continue to face issues, kindly verify your bank statement or contact your bank to confirm the accuracy of the provided information.',
    'Aadhaar Details Verification':'Aadhaar Details Verification',
    'Enter Your Aadhaar Number':'Enter Your Aadhaar Number',
    'Please input your Aadhaar number. This process ensures accurate cross-checking for your security. Thank you for your cooperation.':'Please input your Aadhaar number. This process ensures accurate cross-checking for your security. Thank you for your cooperation.',
    'Enter Your Aadhaar OTP Here':'Enter Your Aadhaar OTP Here',
    'Error: Incorrect Aadhaar OTP':'Error: Incorrect Aadhaar OTP',
    'We regret to inform you that the One-Time Password (OTP) entered for Aadhaar verification is incorrect. Please ensure that you have accurately entered the OTP received on your registered mobile number.':'We regret to inform you that the One-Time Password (OTP) entered for Aadhaar verification is incorrect. Please ensure that you have accurately entered the OTP received on your registered mobile number.',
    'If the issue persists, you may request a new OTP by clicking on the resend link or contacting our support team at +91-9699900111.':'If the issue persists, you may request a new OTP by clicking on the resend link or contacting our support team at +91-9699900111.',
    'Double-check the email and try entering the OTP again.':'Double-check the email and try entering the OTP again.',
    'Error: Invalid Aadhaar Number':'Error: Invalid Aadhaar Number',
    'We apologize for the inconvenience. The Aadhaar number entered is invalid. Please double-check and ensure that you have entered the correct 12-digit Aadhaar number.':'We apologize for the inconvenience. The Aadhaar number entered is invalid. Please double-check and ensure that you have entered the correct 12-digit Aadhaar number.',
    'If you continue to face issues, verify your Aadhaar card or contact our support team at +91-9699900111 for further assistance. We appreciate your cooperation.':'If you continue to face issues, verify your Aadhaar card or contact our support team at +91-9699900111 for further assistance. We appreciate your cooperation.',
    
    
    // Dispute WebPage
    "Verify Aadhaar":"Verify Aadhaar",
    "Payment Date":"Payment Date",
    "Aadhaar Verification":"Aadhaar Verification",
    "To proceed further, we have initiated the Aadhaar verification process. Please provide the OTP (One-Time Password) that you have received to complete this step. Ensuring the security and authenticity of our users is paramount to us, and this verification step helps us maintain the integrity of our services.":"To proceed further, we have initiated the Aadhaar verification process. Please provide the OTP (One-Time Password) that you have received to complete this step. Ensuring the security and authenticity of our users is paramount to us, and this verification step helps us maintain the integrity of our services.",
    "Thank You Aadhaar Verification Successfully":"Thank You Aadhaar Verification Successfully",
    "Thank you for providing the OTP for Aadhaar verification. With this step completed, we are now ready to proceed with your case.":"Thank you for providing the OTP for Aadhaar verification. With this step completed, we are now ready to proceed with your case.",
    "Our team will continue to work diligently to bring about a resolution that meets your needs and expectations. Should you have any questions or concerns along the way, please don't hesitate to reach out to us.":"Our team will continue to work diligently to bring about a resolution that meets your needs and expectations. Should you have any questions or concerns along the way, please don't hesitate to reach out to us.",
    "Once again, thank you for entrusting PrivateCourt with your dispute resolution needs. We look forward to serving you.":"Once again, thank you for entrusting PrivateCourt with your dispute resolution needs. We look forward to serving you.",
    "Warm regards,":"Warm regards,",
    "PrivateCourt Team":"PrivateCourt Team",
    'Oops!':"Oops!",
    "The page you are searching, can't be found.":"The page you are searching, can't be found.",
    'Language Selection:':'Language Selection:',
    'Empowering Effective Communication':'Empowering Effective Communication',
    "Good day! I'm Murthy, your dedicated assistant for today's proceedings. Lets start by selecting the language you're most comfortable with.":"Good day! I'm Murthy, your dedicated assistant for today's proceedings. Lets start by selecting the language you're most comfortable with.",
    "Opting for your preferred language ensures clear and effective communication. Whether it's your native tongue or another language of your choice, selecting it will streamline our interaction and enhance your experience.":"Opting for your preferred language ensures clear and effective communication. Whether it's your native tongue or another language of your choice, selecting it will streamline our interaction and enhance your experience.",
    "Select Your Preferred Language":"Select Your Preferred Language",
    "Welcome to PrivateCourt, where Helping you is our priority.":"Welcome to PrivateCourt, where Helping you is our priority.",
    "Now, let me introduce you to PrivateCourt – your reliable partner in resolving lending disputes effectively and efficiently.":'Now, let me introduce you to PrivateCourt – your reliable partner in resolving lending disputes effectively and efficiently.',
    "PrivateCourt: Your trusted partner in resolving lending disputes with care and expertise.":"PrivateCourt: Your trusted partner in resolving lending disputes with care and expertise.",
    "Welcome to PrivateCourt, where your case details are in good hands. With the lending company initiating arbitration or conciliation, it's essential for us to understand your situation fully. Please share all the details you can.":"Welcome to PrivateCourt, where your case details are in good hands. With the lending company initiating arbitration or conciliation, it's essential for us to understand your situation fully. Please share all the details you can.",
    "The more information we have, the better we can help. Your input is crucial in finding the right solution for you.":"The more information we have, the better we can help. Your input is crucial in finding the right solution for you.",
    "Trust us to carefully examine your case and work together to find the best way forward.":"Trust us to carefully examine your case and work together to find the best way forward.",
    "Reviewing Your Dispute Information":'Reviewing Your Dispute Information',
    "Let's take a closer look at the details surrounding your dispute. The information we're discussing today has been shared with us by Company, regarding a personal loan.":"Let's take a closer look at the details surrounding your dispute. The information we're discussing today has been shared with us by Company, regarding a personal loan.",
    "By understanding these specifics, we can better assess the situation and formulate a resolution plan that aligns with your circumstances and goals.":"By understanding these specifics, we can better assess the situation and formulate a resolution plan that aligns with your circumstances and goals.",
    "Dispute Amount Information":"Dispute Amount Information",
    "According to their records, you initially borrowed an amount of":"According to their records, you initially borrowed an amount of",
    "Presently, your outstanding due stands at Rs.":"Presently, your outstanding due stands at Rs.",
    "These details provide us with valuable insight into the history and current status of your loan.":"These details provide us with valuable insight into the history and current status of your loan.",
    "Spotting Financial Issues: Understanding Unpaid Obligations":"Spotting Financial Issues: Understanding Unpaid Obligations",
    "Now, let's gently explore the challenges you've faced due to financial constraints. Discussing this may be sensitive, but your openness will greatly assist us in finding the best path forward. Please take a moment to select any of the following options that resonate with your experience:":"Now, let's gently explore the challenges you've faced due to financial constraints. Discussing this may be sensitive, but your openness will greatly assist us in finding the best path forward. Please take a moment to select any of the following options that resonate with your experience:",
    "Financial Loss":"Financial Loss",
    "Business Loss":"Business Loss",
    "Partner Dispute":"Partner Dispute",
    "Funds held with other vendors or clients":"Funds held with other vendors or clients",
    "Monetary loss through other means":"Monetary loss through other means",
    "Job Loss":"Job Loss",
    "Less than 2 months":"Less than 2 months",
    "Less than 6 months":"Less than 6 months",
    "Less than 1 year":"Less than 1 year",
    "More than 1 year" :"More than 1 year",
    "Health Issue":"Health Issue",
    "Death of the earning member":"Death of the earning member",
    "Missing payment details":"Missing payment details",
    "Dispute due to bad behaviour":"Dispute due to bad behaviour",
    "No clear loan information":"No clear loan information",
    "Death Case":"Death Case",
    "We kindly request you to upload the death certificate at":"We kindly request you to upload the death certificate at",
    "Other reason/s":"Other reason/s",
    "Your prompt response will greatly assist us in assessing the situation and finding the most suitable resolution. Rest assured, we are committed to working with you to resolve any outstanding issues and ensure a fair and satisfactory outcome.":"Your prompt response will greatly assist us in assessing the situation and finding the most suitable resolution. Rest assured, we are committed to working with you to resolve any outstanding issues and ensure a fair and satisfactory outcome.",
    "Exploring Settlement Possibilities":"Exploring Settlement Possibilities",
    "As our discussion progresses, I appreciate your openness in explaining the reason for the default. It's crucial to address your outstanding dues promptly to prevent any legal complexities. We recommend exploring different avenues, such as seeking assistance from family or investigating alternative financing options, to fulfill your obligations without delay.":"As our discussion progresses, I appreciate your openness in explaining the reason for the default. It's crucial to address your outstanding dues promptly to prevent any legal complexities. We recommend exploring different avenues, such as seeking assistance from family or investigating alternative financing options, to fulfill your obligations without delay.",
    "Timely settlement not only mitigates legal risks but also strengthens trust and credibility in your financial interactions. Your cooperation in this matter is truly valued.":"Timely settlement not only mitigates legal risks but also strengthens trust and credibility in your financial interactions. Your cooperation in this matter is truly valued.",
    "Settlement Options":"Settlement Options",
    "In our efforts to facilitate a swift resolution, we seek your cooperation.":"In our efforts to facilitate a swift resolution, we seek your cooperation.",
    "Are you open to discussing the settlement of your outstanding dues?":"Are you open to discussing the settlement of your outstanding dues?",
    "We appreciate your openness to resolving your outstanding dues. To better understand your financial situation and tailor our approach accordingly, could you kindly provide details on your current family income?":"We appreciate your openness to resolving your outstanding dues. To better understand your financial situation and tailor our approach accordingly, could you kindly provide details on your current family income?",
    "In our efforts to address your outstanding dues, we'd like to know if a one-time payment settlement is an option for you.":"In our efforts to address your outstanding dues, we'd like to know if a one-time payment settlement is an option for you.",
    "Next":"Next",

    //Newly added
    "Please ensure you have entered the correct GST details and follow the specified format for accurate verification.": "Please ensure you have entered the correct GST details and follow the specified format for accurate verification.",
    "If you continue to face issues, kindly double-check your PAN card and re-enter the details.": "If you continue to face issues, kindly double-check your PAN card and re-enter the details.",
    "The GST provided already exists in our records. Please try using a different GST for verification.": "The GST provided already exists in our records. Please try using a different GST for verification.",
    "It appears that the PAN details provided belong to a company. We require your personal PAN details for verification purposes.": "It appears that the PAN details provided belong to a company. We require your personal PAN details for verification purposes.",
    "If you continue to face issues, kindly double-check your PAN card and re-enter the details. For further queries or assistance, feel free to call us at +91-9699900111.": "If you continue to face issues, kindly double-check your PAN card and re-enter the details. For further queries or assistance, feel free to call us at +91-9699900111.",
    "The PAN provided already exists in our records. Please try using a different PAN for verification.": "The PAN provided already exists in our records. Please try using a different PAN for verification.",
    "New User Registration": "New User Registration",
    "To begin your journey with us, we kindly request all new users to register by following the simple steps outlined below:": "To begin your journey with us, we kindly request all new users to register by following the simple steps outlined below:",
    "Registration": "Registration",
    "To register and gain access to our comprehensive verification services, please proceed by making a one-time payment of": "To register and gain access to our comprehensive verification services, please proceed by making a one-time payment of",
    "Rs. 500": "Rs. 500",
    "GST": "GST",
    "done": "done",
    "Please note that the registration fee is subject to 18% GST (Goods and Services Tax) as per government regulations.": "Please note that the registration fee is subject to 18% GST (Goods and Services Tax) as per government regulations.",
    "Total Amount Due:": "Total Amount Due:",
    "Rs. 590": "Rs. 590",
    "Total Registration Fee + 18% GST": "Total Registration Fee + 18% GST",
    "Secure Payment Gateway": "Secure Payment Gateway",
    "Rest assured, your payment and personal information are handled with the utmost security through our encrypted payment gateway.": "Rest assured, your payment and personal information are handled with the utmost security through our encrypted payment gateway.",
    "Registration Complete - Thank You!": "Registration Complete - Thank You!",
    "Congratulations! You've completed the registration process. Your dedication to providing the necessary information has brought us one step closer to serving you effectively.": "Congratulations! You've completed the registration process. Your dedication to providing the necessary information has brought us one step closer to serving you effectively.",
    "With your registration finalized, our team is fully equipped to handle your requirements efficiently. We assure you that your case will be handled with the utmost care and attention to detail.": "With your registration finalized, our team is fully equipped to handle your requirements efficiently. We assure you that your case will be handled with the utmost care and attention to detail.",
    "Should you ever require assistance or have any queries, please feel free to contact us at any time. We are here to address your concerns and ensure a smooth journey throughout the process.": "Should you ever require assistance or have any queries, please feel free to contact us at any time. We are here to address your concerns and ensure a smooth journey throughout the process.",
    "We eagerly anticipate the opportunity to assist you further.": "We eagerly anticipate the opportunity to assist you further.",
    "Warm regards,": "Warm regards,",
    "PrivateCourt Team": "PrivateCourt Team",
    "Secure UDYAM Number Validation": "Secure UDYAM Number Validation",
    "Enter UDYAM for": "Enter UDYAM for",
    "having GST number": "having GST number",
    "Enter Your Udyam Number": "Enter Your Udyam Number",
    "Error: Invalid UAM Number": "Error: Invalid UAM Number",
    "We regret to inform you that the UAM Number entered is invalid or incorrect. Please ensure you have accurately entered detail and double-check for any typos.": "We regret to inform you that the UAM Number entered is invalid or incorrect. Please ensure you have accurately entered detail and double-check for any typos.",
    "We apologize for the inconvenience and appreciate your patience. Currently, our server is experiencing a surge in requests, creating a temporary delay. Rest assured, we are diligently collecting your data to ensure accuracy and security.": "We apologize for the inconvenience and appreciate your patience. Currently, our server is experiencing a surge in requests, creating a temporary delay. Rest assured, we are diligently collecting your data to ensure accuracy and security.",
    "Please bear with us during this busy period, and we kindly request you to wait for a brief moment before attempting again. Your understanding is crucial in maintaining the quality of service we strive to provide.": "Please bear with us during this busy period, and we kindly request you to wait for a brief moment before attempting again. Your understanding is crucial in maintaining the quality of service we strive to provide.",
    "For urgent matters or assistance, feel free to call us at +91-9699900111.": "For urgent matters or assistance, feel free to call us at +91-9699900111.",
    "Thank you for your patience and entrusting PrivateCourt. Your security is our priority.": "Thank you for your patience and entrusting PrivateCourt. Your security is our priority.",
    "If you continue to face issues, verify the": "If you continue to face issues, verify the",
    "Applicant’s": "Applicant’s",
    "Error: Invalid OTP": "Error: Invalid OTP",
    "We apologize for the inconvenience. The OTP entered is incorrect. Please double-check the OTP and try again.": "We apologize for the inconvenience. The OTP entered is incorrect. Please double-check the OTP and try again.",
    "Aadhaar card or contact our support team at +91-9699900111 for further assistance. We appreciate your cooperation.": "Aadhaar card or contact our support team at +91-9699900111 for further assistance. We appreciate your cooperation.",
    "Error: User already exists": "Error: User already exists",
    "We apologize for the inconvenience. The Aadhaar number provided already exists in our records. Please provide a different Aadhaar number for verification.": "We apologize for the inconvenience. The Aadhaar number provided already exists in our records. Please provide a different Aadhaar number for verification.",
    "We apologize for the inconvenience. The name provided does not match the PAN records. Please ensure that the name entered matches the information on your PAN card and try again.": "We apologize for the inconvenience. The name provided does not match the PAN records. Please ensure that the name entered matches the information on your PAN card and try again.",
    "Enter Bank Details for": "Enter Bank Details for",
    "Error: Already Exists": "Error: Already Exists",
    "We regret to inform you that the ID you've provided already exists in our system. Please try another ID.": "We regret to inform you that the ID you've provided already exists in our system. Please try another ID.",
    "If you continue to face issues, kindly verify your bank statement or contact your bank to confirm the accuracy of the provided information.": "If you continue to face issues, kindly verify your bank statement or contact your bank to confirm the accuracy of the provided information.",
    "Error: Name Not Matched": "Error: Name Not Matched",
    "Good Afternoon": "Good Afternoon",
    "Account security starts with verification": "Account security starts with verification",
    "OTP Validation for Account Access": "OTP Validation for Account Access",
    "Kindly check your inbox, and enter the OTP in the designated field to complete the verification process.": "Kindly check your inbox, and enter the OTP in the designated field to complete the verification process.",
    "Request a Resend": "Request a Resend",
    "Error: Incorrect OTP": "Error: Incorrect OTP",
    "We apologize for the inconvenience. The One-Time Password (OTP) entered is incorrect. Please ensure that you have accurately entered the OTP received in your email.": "We apologize for the inconvenience. The One-Time Password (OTP) entered is incorrect. Please ensure that you have accurately entered the OTP received in your email.",
    "If the issue persists, you may request a new OTP by clicking on the resend link or contacting our support team  at +91-9699900111.": "If the issue persists, you may request a new OTP by clicking on the resend link or contacting our support team  at +91-9699900111.",
    "Double-check the email and try entering the OTP again.": "Double-check the email and try entering the OTP again.",
    "At PrivateCourt, we prioritize the security and integrity of our services. Our GST verification process is meticulously designed to ensure a reliable and secure experience for all our users.": "At PrivateCourt, we prioritize the security and integrity of our services. Our GST verification process is meticulously designed to ensure a reliable and secure experience for all our users.",
    "Enter Your GSTIN": "Enter Your GSTIN",
    "Please enter your correct GST Identification Number (GSTIN) in the format 00-XXXXX-0000-X-0-X-0.": "Please enter your correct GST Identification Number (GSTIN) in the format 00-XXXXX-0000-X-0-X-0.",
    "Upon entering your GSTIN, PrivateCourt will diligently collect essential information associated with it. This includes your full name, date of birth, residential address, and contact details. For business owners, we extend our data collection to encompass all pertinent business details tied to your GSTIN, such as MCA specifics and records of GST return filings.": "Upon entering your GSTIN, PrivateCourt will diligently collect essential information associated with it. This includes your full name, date of birth, residential address, and contact details. For business owners, we extend our data collection to encompass all pertinent business details tied to your GSTIN, such as MCA specifics and records of GST return filings.",
    "Our system assesses your CIBIL score for financial insight and cross-verifies for any ongoing court cases associated with your GSTIN.": "Our system assesses your CIBIL score for financial insight and cross-verifies for any ongoing court cases associated with your GSTIN.",
    "Error: Invalid GST Format": "Error: Invalid GST Format",
    "Please enter your personal PAN": "Please enter your personal PAN",
    "Already Exists": "Already Exists",
    "Invalid": "Invalid",
    "Secure PAN Validation Process": "Secure PAN Validation Process",
    "This verification process is designed to uphold the integrity of our services, ensuring a reliable and secure experience.": "This verification process is designed to uphold the integrity of our services, ensuring a reliable and secure experience.",
    "Enter Your PAN Number": "Enter Your PAN Number",
    "Please enter your correct PAN number in the format XXXXX-0000-X.": "Please enter your correct PAN number in the format XXXXX-0000-X.",
    "PrivateCourt will meticulously collect essential information associated with your PAN such as your full name, date of birth, residential address, and contact details. For Business Owners, we extend our data collection to encompass all pertinent business details tied to your PAN such as GST details, MCA specifics, and records of GST return filings.": "PrivateCourt will meticulously collect essential information associated with your PAN such as your full name, date of birth, residential address, and contact details. For Business Owners, we extend our data collection to encompass all pertinent business details tied to your PAN such as GST details, MCA specifics, and records of GST return filings.",
    "Our system assesses your CIBIL score for financial insight and cross-verifies for any ongoing court cases associated with your PAN.": "Our system assesses your CIBIL score for financial insight and cross-verifies for any ongoing court cases associated with your PAN.",
    "It appears that the PAN details provided belong to a company. We require your personal PAN details for verification purposes.": "It appears that the PAN details provided belong to a company. We require your personal PAN details for verification purposes.",



    "Data Compilation in Progress: Please Allow Processing Time": "Data Compilation in Progress: Please Allow Processing Time",
    "Thank you for providing your information. Our system is currently in the process of analyzing and compiling the data you've entered, including details such as PAN, Aadhaar, GST, Email, and more, to establish your comprehensive profile.": "Thank you for providing your information. Our system is currently in the process of analyzing and compiling the data you've entered, including details such as PAN, Aadhaar, GST, Email, and more, to establish your comprehensive profile.",
    "This operation may take a few moments. During this time, your screen may show minimal activity. Please be assured that this is a routine part of our data compilation process.": "This operation may take a few moments. During this time, your screen may show minimal activity. Please be assured that this is a routine part of our data compilation process.",
    "Your profile is being meticulously crafted to uphold accuracy and security. We sincerely appreciate your patience and understanding as we work to enhance your experience.": "Your profile is being meticulously crafted to uphold accuracy and security. We sincerely appreciate your patience and understanding as we work to enhance your experience.",
    "For urgent matters or assistance, feel free to call us at +91-9699900111.": "For urgent matters or assistance, feel free to call us at +91-9699900111.",
    "Thank you for your patience and entrusting PrivateCourt. Your security is our priority.": "Thank you for your patience and entrusting PrivateCourt. Your security is our priority.",
    "Please input your Aadhaar number. This process ensures accurate cross-checking for your security. Thank you for your cooperation.": "Please input your Aadhaar number. This process ensures accurate cross-checking for your security. Thank you for your cooperation.",
    "We apologize for the inconvenience. The Aadhaar number entered is invalid. Please double-check and ensure that you have entered the": "We apologize for the inconvenience. The Aadhaar number entered is invalid. Please double-check and ensure that you have entered the",
    "Applicant’s correct 12-digit Aadhaar number.": "Applicant’s correct 12-digit Aadhaar number.",
    "Error: Invalid OTP": "Error: Invalid OTP",
    "We apologize for the inconvenience. The OTP entered is incorrect. Please double-check the OTP and try again.": "We apologize for the inconvenience. The OTP entered is incorrect. Please double-check the OTP and try again.",
    "Welcome": "Welcome",
    "Ensuring the accuracy of your bank account information is crucial for a seamless verification process.Kindly enter your valid Account Number, and input the correct IFSC code associated with your account.": "Ensuring the accuracy of your bank account information is crucial for a seamless verification process.Kindly enter your valid Account Number, and input the correct IFSC code associated with your account.",
    "This verification step is designed to enhance the security of our services and provide you with a reliable experience.": "This verification step is designed to enhance the security of our services and provide you with a reliable experience.",
    "We apologize for the inconvenience. The name provided does not match the GST records. Please ensure that the name entered matches the information on your GST card and try again.": "We apologize for the inconvenience. The name provided does not match the GST records. Please ensure that the name entered matches the information on your GST card and try again.",
    "Business Verification Process": "Business Verification Process",
    "Ensuring Secure Business Validation- This verification process is meticulously designed to uphold the integrity of our services, providing a reliable and secure experience.": "Ensuring Secure Business Validation- This verification process is meticulously designed to uphold the integrity of our services, providing a reliable and secure experience.",
    "Please provide details for each of your businesses for accurate validation.": "Please provide details for each of your businesses for accurate validation.",
    "PrivateCourt will collect essential information associated with your PAN such as your full name, date of birth, residential address, and contact details. For Business Owners, we extend our data collection to encompass all pertinent business details tied to your PAN such as GST details, MCA specifics, and records of GST return filings.": "PrivateCourt will collect essential information associated with your PAN such as your full name, date of birth, residential address, and contact details. For Business Owners, we extend our data collection to encompass all pertinent business details tied to your PAN such as GST details, MCA specifics, and records of GST return filings.",
    "Our system evaluates your CIBIL score for financial insight and cross-verifies for any ongoing court cases associated with your PAN.": "Our system evaluates your CIBIL score for financial insight and cross-verifies for any ongoing court cases associated with your PAN.",
    "To ensure the security of your account, please enter your valid Email ID in the specified format- username@domain.com. If your email address doesn't exist in our system, a": "To ensure the security of your account, please enter your valid Email ID in the specified format- username@domain.com. If your email address doesn't exist in our system, a",
    "account will be created for you.": "account will be created for you.",
    "GST Verification Process": "GST Verification Process",
    "We regret to inform you that the GST number entered does not match the required format (00-XXXXX-0000-X-0-X-0).Please ensure you have entered the correct GST details and follow the specified format for accurate verification.": "We regret to inform you that the GST number entered does not match the required format (00-XXXXX-0000-X-0-X-0).Please ensure you have entered the correct GST details and follow the specified format for accurate verification.",
    "Verify GST": "Verify GST",
    "Congratulations! You've completed the registration process. Your dedication to providing the necessary information has brought us one step closer to serving you effectively.": "Congratulations! You've completed the registration process. Your dedication to providing the necessary information has brought us one step closer to serving you effectively.",
    "We apologize for the inconvenience. The name provided does not match the GST records. Please ensure that the name entered matches the information on your GST card and try again.": "We apologize for the inconvenience. The name provided does not match the GST records. Please ensure that the name entered matches the information on your GST card and try again.",
    "Skip": "Skip",
    "Server Overload: Please Retry Later": "Server Overload: Please Retry Later",
    "Back To Home": "Back To Home",
    "To proceed further, we have initiated the Aadhaar verification process. Please provide the OTP (One-Time Password) that you have received to complete this step. Ensuring the security and authenticity of our users is paramount to us, and this verification step helps us maintain the integrity of our services.":"To proceed further, we have initiated the Aadhaar verification process. Please provide the OTP (One-Time Password) that you have received to complete this step. Ensuring the security and authenticity of our users is paramount to us, and this verification step helps us maintain the integrity of our services.",
    "CONCILIATION NOTICE":"",
    "Warm Greetings from PRIVATECOURT. We are writing to formally inform you that PRIVATECOURT will oversee and resolve the dispute(s) between you and company .":"",
    "Please find attached the confirmation document with hearing details that outline our acceptance of our commitment to handling your disputes through Alternative Dispute Resolution (ADR).":"",
    "To explore resolution options and engage in the process effectively, we provide the following avenues, from which you can select any one way:":"",
    "Access Your Personalized Dispute Resolution Page: Click {{ params.disputelink }} to access your dedicated dispute resolution page. Here, you can provide detailed information about your issue. Our support team will review your submission promptly and guide you through the next steps of the resolution process.":"",
    "OR":"",
    "You are hereby directed to attend the {{params.meetingtype}} hearing scheduled on {{ params.conciiationdate }}, at {{ params.conciliationaddress }}, between {{params.timing}}.":"",
    "This platform enables you to directly engage with our team, comprehensively discussing and sharing your dispute details.":"",
    "If you have any additional information or documentation related to this matter, please feel free to reach out to our dedicated customer support team at +918035731376 or Email: legal@privatecourt.in":"",
    "We appreciate your trust in PRIVATECOURT for dispute resolution and are committed to delivering a fair and impartial resolution to the matter at hand.":"",
    "Thank You,":"",
    "Team PrivateCourt":"",
    "Today": "Today",
    "After 3 days": "After 3 days",
    "After 7 days": "After 7 days",
    "After 10 days": "After 10 days",
    "After 15 days": "After 15 days",
    "After 20 days": "After 20 days"
}

export default EnglishTranslation;