import { useState } from "react";
import VerifyButton from "../../loginPage/components/verifyButton";
import { useDispatch, useSelector } from "react-redux";
import { changeStage } from "../../features/disputeStage/disputeSlice";
import useLanguage from "../../local/saveLanguage";
import SingleSelectCheckbox from "../../components/SingleCheckbox";
import { Reasons, SubReasons, SubReasons1, SubReasons2 } from "../../Objects/Reasons";
import BusinessLossCheckbox from "../../components/BussinessCheckBox";
import http from '../../services/httpService'
import Loading from '../../components/loading';
import axios from "axios";
const SpotReason = () => {
  const path = useSelector(state => state.disputeStage.typeOfCommunication);
  console.log(path)
  // console.log(SubReasons2)
  const data = useSelector(state => state.disputeStage.data);
  const loanId=useSelector(state=>state.disputeStage.data?.loanId);
  const [loading,setLoading] = useState(false);
  const lang=useSelector(state=>state.lang.lang);
  const Location = useSelector(state => state.disputeStage.location);
  // console.log(data.claimantCompany)
  const [check, setCheck] = useState({
    "Financial Loss": false,
    "Missing payment details": false,
    "Dispute due to bad behaviour": false,
    "No clear loan information": false,
    "Death Case": false,
    "Other reason/s": false
  });
  const [financialCheck, setFinancialCheck] = useState({
      "Business Loss":false,
      "Job Loss":false,
      "Health Issue":false,
      "Death of the earning member":false
  });
// console.log(check['Financial Loss'])
  const [businessCheck,setBusinessCheck] = useState({
      "Partner Dispute":false,
      "Funds held with other vendors or clients":false,
      "Monetary loss through other means":false
  });

  const [JobCheck,setJobCheck]=useState({
    "Less than 2 months":false,
    "Less than 6 months":false,
    "Less than 1 year":false,
    "More than 1 year":false
  });
  const [FinalObject,setFinalObject]=useState({});

  const dispatch = useDispatch();
  const translate = useLanguage.getLang();
  const handlerChange = (reason) => {
    const newCheck = {};
    //Below code is for once Parent checkbox unchecked then unchecked all children as well.
    if (!check["Financial Loss"]) {
      setFinancialCheck(Object.fromEntries(Object.keys(financialCheck).map(key => [key, false])));
    }
    for (const key in check) {
      if (key === reason) {
        newCheck[key] = !check[key];
        setFinalObject({mainReason:key});
      } else {
        newCheck[key] = false;
      }
    }
    setCheck(newCheck);
    const anyTrue = Object.values(newCheck).some(value => value);
    if(!anyTrue) setFinalObject({});
  };

  const handlerChange1 = (reason) => {
    const newCheck = {};
    //Below code is for once Parent checkbox unchecked then unchecked all children as well.
    if (!financialCheck["Business Loss"]) {
      setBusinessCheck(Object.fromEntries(Object.keys(businessCheck).map(key => [key, false])));
    }
    if (!financialCheck["Job Loss"]) {
      setJobCheck(Object.fromEntries(Object.keys(JobCheck).map(key => [key, false])));
    }
    for (const key in financialCheck) {
      if (key === reason) {
        newCheck[key] = !financialCheck[key];
        setFinalObject({...FinalObject,FinancialReason:key});
      }
       else {
        newCheck[key] = false;
      }
    }
    setFinancialCheck(newCheck);
  };
  const handlerChange2 = (reason) => {
    const newCheck = {};
    for (const key in businessCheck) {
      if (key === reason) {
        newCheck[key] = !businessCheck[key];
        delete FinalObject.JobLossFrom;
        setFinalObject({...FinalObject,BussinessReason:key});
      } else {
        newCheck[key] = false;
      }
    }
    setBusinessCheck(newCheck);
  };
  const handlerChange3= (reason) => {
    const newCheck = {};
    for (const key in JobCheck) {
      if (key === reason) {
        newCheck[key] = !JobCheck[key];
        delete FinalObject.BussinessReason;
        setFinalObject({...FinalObject,JobLossFrom:key});
      } else {
        newCheck[key] = false;
      }
    }
    setJobCheck(newCheck);
  }
  const handleSubmit  = async () => {
        setLoading(true);
        FinalObject['Language'] = lang.toLowerCase() || "english";
        // await http.post2("/AddFinancialIssue",{loanId:loanId,FinancialIssue:FinalObject,claimentCompany:data.claimantCompany,stage:"settlement"})
        await http.post2("/AddFinancialIssue",{loanId:loanId,FinancialIssue:FinalObject,claimentCompany:data.claimantCompany,stage:"settlement",Location:Location,Language:lang.toLowerCase(),email:data.email,sheetTimestamp:Number(data.timeStamp),typeOfCommunication:path ? path : ''})
        .then((res)=>{
          if(res.data === "Updated Successfully"){
            console.log("done")
            setLoading(false);
            handlesubmit();
          }
        })
        .catch((err)=>{
          console.log(err);
        })
  }
// console.log(FinalObject)
  const handlesubmit = () => {
    dispatch(changeStage("settlement"));
  };
  return (
    <>
  {loading && <Loading />}
    <div class="login-mid">
      <div class="container">
        <div class="row">
          <div class="col-xl-7 col-lg-5 login-mid-left">
            <h2 data-translate="sfi-1">
              {translate[
                "Spotting Financial Issues: Understanding Unpaid Obligations"
              ] ??
                "Spotting Financial Issues: Understanding Unpaid Obligations"}
            </h2>
          </div>
          <div class="col-xl-5 col-lg-7">
            <div class="card pb-normal bank-verification">
              <div class="vf-content">
                <p data-translate="sfi-2">
                  {translate[
                    "Now, let's gently explore the challenges you've faced due to financial constraints. Discussing this may be sensitive, but your openness will greatly assist us in finding the best path forward. Please take a moment to select any of the following options that resonate with your experience:"
                  ] ??
                    "Now, let's gently explore the challenges you've faced due to financial constraints. Discussing this may be sensitive, but your openness will greatly assist us in finding the best path forward. Please take a moment to select any of the following options that resonate with your experience:"}
                </p>
                <div class="m-3"></div>
                <div class="financial-loss">
                  {Reasons.map((item, index) => (
                    <div key={index}>
                      <SingleSelectCheckbox
                        label={
                          translate[item.reason]
                            ? translate[item.reason]
                            : item.reason
                        }
                        checked={check[item.reason] || false}
                        onChange={() => handlerChange(item.reason)}
                      />
                      {item.reason === "Financial Loss" &&
                        check[item.reason] && (
                          <div
                            // className="financial-loss-box"
                            style={{ marginLeft: "1rem" }}
                          >
                            {
                              SubReasons.map((item,index)=>(
                              <div key={index}>
                                <BusinessLossCheckbox 
                                 label={ translate[item.reason]? translate[item.reason] : item.reason }
                                checked={financialCheck[item.reason] || false}
                                onChange={() => handlerChange1(item.reason)}/>
                                {
                                  item.reason === "Business Loss" && financialCheck[item.reason] && (
                                    <div
                                    //  className="financial-loss-box"
                                     style={{ marginLeft: "1rem" }}>
                                   {SubReasons1.map((item,index)=> (
                                    <div key={index}>
                                    <BusinessLossCheckbox 
                                    label={ translate[item.reason]? translate[item.reason] : item.reason}
                                    checked={businessCheck[item.reason] || false}
                                    onChange={() => handlerChange2(item.reason)}/>
                                   </div>
                                    ))
                                   }
                                </div>
                                )
                                }

                                {
                                  item.reason === "Job Loss" && financialCheck[item.reason] && (
                                    <div 
                                    // className="financial-loss-box"
                                    style={{ marginLeft: "1rem" }}>
                                   {SubReasons2.map((item,index)=> (
                                    <div key={index}>
                                    <BusinessLossCheckbox 
                                    label={ translate[item.reason]? translate[item.reason] : item.reason}
                                    checked={JobCheck[item.reason] || false}
                                    onChange={() => handlerChange3(item.reason)}/>
                                   </div>
                                    ))
                                   }
                                </div>
                                )
                                }


                              </div> 
                              ))
                            }
                          </div>
                        )}
                      {item?.reason === "Death Case" && (
                        <div className="py-2 ps-3">
                          <p
                            className="mb-0"
                            style={{
                              fontSize: "14px",
                              lineHeight: "16px",
                              marginTop: "5px"
                            }}
                          >
                            {translate[
                              "We kindly request you to upload the death certificate at"
                            ] ??
                              "We kindly request you to upload the death certificate at"}{" "}
                            Legal@privatecourt.in
                          </p>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
                <div class="m-3"></div>
                <p data-translate="sfi-prompt">
                  {translate[
                    "Your prompt response will greatly assist us in assessing the situation and finding the most suitable resolution. Rest assured, we are committed to working with you to resolve any outstanding issues and ensure a fair and satisfactory outcome."
                  ] ??
                    "Your prompt response will greatly assist us in assessing the situation and finding the most suitable resolution. Rest assured, we are committed to working with you to resolve any outstanding issues and ensure a fair and satisfactory outcome."}
                </p>
              </div>
              { (Object.values(FinalObject) === 0) ? (
                  <div>Please Choose the First</div>
               ) :""
              }
              <div class="ms-auto">
                <VerifyButton btnName="Next" 
                onSubmit={handleSubmit}
                 disabled={Object.values(FinalObject).length === 0}/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};
export default SpotReason;
