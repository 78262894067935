

export const Reasons= [
    {
        "reason":"Financial Loss"
    },
    {
        "reason":"Missing payment details"
    },
    {
        "reason":"Dispute due to bad behaviour"
    },
    {
        "reason":"No clear loan information"
    },
    {
        "reason":"Death Case"
    },
    {
        "reason":"Other reason/s"
    }
];

export const SubReasons = [
    {
        "reason":"Business Loss"
    },
    {
        "reason":"Job Loss"
    },
    {
        "reason":"Health Issue"
    },
    {
        "reason":"Death of the earning member"
    }
]

export  const SubReasons1 = [
    {
        "reason":"Partner Dispute"
    },
    {
        "reason":"Funds held with other vendors or clients"
    },
    {
        "reason":"Monetary loss through other means"
    }
]
export  const SubReasons2 = [
    {
        "reason":"Less than 2 months"
    },
    {
        "reason":"Less than 6 months"
    },
    {
        "reason":"Less than 1 year"
    },
    {
        "reason":"More than 1 year"
    }
]