import React,{useRef, useState} from 'react';
import VerifyButton from '../../loginPage/components/verifyButton';
import useLanguage from "../../local/saveLanguage";
import { useDispatch, useSelector } from 'react-redux';
import http from "../../services/httpService";
import axios from 'axios';
import useHandleModal from '../../components/useModal1';
import { changeStage } from '../../features/disputeStage/disputeSlice';
// import use from "..";
import Loading from '../../components/loading';
import ContentCard from './contentCard';

const AdharVerification = () => {
    const [loading,setLoading] = useState(false);
    const { handleModal2, handleModal3 } = useHandleModal();
    const loanId =useSelector(state => state.disputeStage.data?.loanId);
    const translate = useLanguage.getLang();
    const [aadhaarEr, setAadhaarEr] = useState(false);
    // const [user, setUser] = useState(auth.getUser().UserLogin);
    const [showOTP,setShowOTP]=useState(false);
    const dispatch = useDispatch();
    const inputRefs = [
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null)
      ];  
      const inputRefs1 = [
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null)
      ]; 

      const handleKeyDown = (index, e) => {
        setAadhaarEr(false);
        if (e.key === "Backspace" && index > 0 && e.target.value === "") {
          const prevIndex = index-1;
          inputRefs[prevIndex].current.focus();
        } else if (e.key >= "0" && e.key <= "9") {
          const nextIndex = index + 1;
          if (nextIndex < inputRefs.length) {
            inputRefs[index].current.value = e.key;
            inputRefs[nextIndex].current.focus();
          }
        } 
        else if (
          index === 0 &&
          e.key !== "Backspace" &&
          (e.key < "0" || e.key > "9")
        ) {
          // Prevent typing in the first input field if it's not a number or Backspace
          inputRefs[0] = e.key;
          e.preventDefault();
        }
      };
      const handleKeyDown1 = (index, e) => {
        setAadhaarEr(false);
        if (e.key === "Backspace" && index > 0 && e.target.value === "") {
          const prevIndex = index-1;
          inputRefs1[prevIndex].current.focus();
        } else if (e.key >= "0" && e.key <= "9") {
          const nextIndex = index + 1;
          if (nextIndex < inputRefs1.length) {
            inputRefs1[index].current.value = e.key;
            inputRefs1[nextIndex].current.focus();
          }
        } 
        else if (
          index === 0 &&
          e.key !== "Backspace" &&
          (e.key < "0" || e.key > "9")
        ) {
          // Prevent typing in the first input field if it's not a number or Backspace
          inputRefs1[0] = e.key;
          e.preventDefault();
        }
      };

      const handleSubmitAadhar = async () => {
        setLoading(true);
        let aadhaarno = inputRefs.map((ref) => ref.current.value).join("");
        console.log(aadhaarno)
        if(aadhaarno.length ===12){
          // await axios.post("http://localhost:3004/api/sendAdharOTP",{aadharno:aadhaarno})
          await http.post2("/sendAdharOTP",{aadharno:aadhaarno})
          // await http.post2("/sendAdharOTP",{aadharno:aadhaarno})
          .then((res)=>{
              if(res.status >=300 && res.status <= 550){
                dispatch(changeStage("overload"));
              }
              if(res.data == "OTP sent successfully"){  
                  setLoading(false); 
                  handleModal3("OTP has sent in your Aadhar Link Number")     
                  setShowOTP(true);
              }
              else if(res.data == "Invalid Aadhaar Card"){
                setLoading(false); 
                handleModal2("Please Enter Correct Aadhar Number");
              }
              else{
                setLoading(false); 
                handleModal2("Please Try Again later");
              }
          })
          .catch((err)=>{
              console.log(err);
          })
        }
        else{
          setLoading(false); 
          handleModal2('Please Enter 12 Digit Aadhar Number');
        }
   
      } 
      const handleSubmitVerify = async () => {
        setLoading(true); 
        let aadhaarno = inputRefs.map((ref) => ref.current.value).join("");
        let otp = inputRefs1.map((ref) => ref.current.value).join("");
        await http.post2("/verifyAdharOTP",{aadharno:aadhaarno,otp:otp})
        // await axios.post("http://localhost:3004/api/verifyAdharOTP",{aadharno:aadhaarno,otp:otp})
        // await http.post2("/verifyAdharOTP",{aadharno:aadhaarno,otp:otp})
        .then( async (res)=>{
           if(res.status >=300 && res.status <=500){
            dispatch(changeStage("overload"));
           }
            if(res.data == "Verification Successfully"){
                setShowOTP(false);
                let AdharInput = {aadhaarno:aadhaarno,stage:"verified"};
                // http.post2("/AddFinancialIssue",{DID:did,AdharInput})
                 await http.post2("/AddFinancialIssue",{loanId:loanId,AdharInput})
                .then((res)=>{
                  if(res.data == "Updated Successfully"){
                    setLoading(false); 
                    handleModal3("Succesfully Verified");
                    setTimeout(()=>{
                      dispatch(changeStage("verified"));
                    },2000);
                  }
                  else{
                    handleModal2("Something went Wrong While verification. Please try again later");
                  }
                })
                .catch((err)=>{
                  console.log(err);
                })
              
            }
            else{
                setLoading(false);
                handleModal2('InCorrect OTP');
            }
        })
        .catch((err)=>{
            console.log(err);
        })
      }
    return (
      <>
      {loading && <Loading/>}
        <div className="login-mid">
            <div className="container">
                <div className="row">
                    <div className="col-xl-6 col-lg-6 login-mid-left">
                        <h2 data-translate="tran-1">{translate?.["Aadhaar Verification"] ?? "Aadhaar Verification"}</h2>
                        <p data-translate="lml-para">{translate?.["To proceed further, we have initiated the Aadhaar verification process. Please provide the OTP (One-Time Password) that you have received to complete this step. Ensuring the security and authenticity of our users is paramount to us, and this verification step helps us maintain the integrity of our services."] ?? "To proceed further, we have initiated the Aadhaar verification process. Please provide the OTP (One-Time Password) that you have received to complete this step. Ensuring the security and authenticity of our users is paramount to us, and this verification step helps us maintain the integrity of our services."}</p>
                    </div>
                    <div class="col-xl-6 col-lg-6">
                        <div className="card pb-normal ad-box">
                        {/* <ContentCard><> */}
                            <div className="vf-content">
                                <h1 data-translate="aaav-c-heading">{translate?.["Enter the Applicant’s Aadhaar Number"] ?? "Enter the Applicant’s Aadhaar Number"}</h1>
                                <p data-translate="lml-para">{translate?.["Please input the Applicant’s Aadhaar number along with essential demographic details—name, date of birth, and gender. This process ensures accurate cross-checking for security. Thank you for your cooperation."] ?? "Please input the Applicant’s Aadhaar number along with essential demographic details—name, date of birth, and gender. This process ensures accurate cross-checking for security. Thank you for your cooperation."}</p>
                                <p data-translate="aaav-c-st-2">{translate?.["For any queries or assistance, feel free to call us at +91-9699900111."] ?? "For any queries or assistance, feel free to call us at +91-9699900111."}</p>
                                <p><strong data-translate="tran-5">{translate?.["Thank you for entrusting PrivateCourt. Your security is our priority."] ?? "Thank you for entrusting PrivateCourt. Your security is our priority."}</strong></p>
                            </div>
                            <div className="otp-boxs otp-pan d-flex flex-wrap justify-content-center udyam-number-box aadhar-number-box">
                                {[...Array(12)].map((_, index) => (
                                    <React.Fragment key={index}>
                                        <input
                                            className="text-center form-control rounded"
                                            type="text"
                                            onKeyUp={(e)=>handleKeyDown(index, e)}
                                            maxLength="1"
                                            ref={inputRefs[index]}
                                        />
                                        {(index === 3 || index === 7) && <span className="aadhar-divider">-</span>}
                                    </React.Fragment>
                                ))}
                                {/* <h6 className="field-mid-text">1234</h6> */}
                            </div>
                            {showOTP ? (
                            <div>
                            <h5 className="mb-1 mt-3" data-translate="tran-6">Enter Your Aadhaar OTP Here</h5>
                            <div className="otp-boxs d-flex flex-row justify-content-center aadhar-number-box">
                                {[...Array(6)].map((_, index) => (
                                    <input
                                        key={index}
                                        className="text-center form-control rounded"
                                        type="text"
                                        onKeyUp={(e)=>handleKeyDown1(index, e)}
                                        // onKeyPress={restrictInput}
                                        ref={inputRefs1[index]}
                                        maxLength="1"
                                    />
                                ))}
                            </div>
                            </div>
                             ) : ""}
                            <div className="ms-auto">
                                <VerifyButton btnName="Verify Aadhaar"
                                 onSubmit={showOTP ? handleSubmitVerify : handleSubmitAadhar} 
                                 />
                            </div>

                          {/* </></ContentCard>   */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    );
};

export default AdharVerification;
